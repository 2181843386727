import React, { forwardRef } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'moment/locale/ru';

const DatePicker = (props, ref) => (
  <AntDatePicker ref={ref} format={'DD.MM.YYYY'} locale={locale} {...props} />
);

export default forwardRef(DatePicker);
