import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Popconfirm, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { delFilters } from 'slices/Filters';

import { useGetFiltersData } from 'hooks/useGetFiltersData';
import openNotificationWithIcon from 'components/controls/base/Notification';
import { outputDateFormat } from 'utils/constants';
import { tableHeaderData } from './tableConstants';
import 'antd/dist/antd.css';
import './style.scss';

const getStoresData = (allFiltersData, store) => {
  const building = _get(allFiltersData, `buildingIds`, []).find(
    b => b.id === store.buildingId,
  );
  const city = _get(allFiltersData, `cityIds`, []).find(
    b => b.id === building.cityId,
  );
  const brand = _get(allFiltersData, `brandIds`, []).find(
    b => b.id === store.brandId,
  );

  const buildingName = _get(building, 'name', '');
  const cityName = _get(city, 'name', '');
  const brandName = _get(brand, 'name', '');

  return { buildingName, cityName, brandName };
};

const FiltersForm = () => {
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const allMyFilters = useSelector(state => state.filters.list);
  // кастомный хук
  const { ...allFiltersData } = useGetFiltersData();

  useEffect(() => {
    setTableData(allMyFilters.map(filterData => filterData));
  }, [allMyFilters]);

  // Возвращает название заголовка
  const tableHeader = (headerName = {}) => {
    return <span>{tableHeaderData[headerName].title}</span>;
  };

  // для перевода id/code в читабельный вид
  const findInEntity = (entity = [], value, idField, type) => {
    if (type === 'date') {
      return moment(entity).format(outputDateFormat);
    }
    if (value && allFiltersData[value]) {
      const translatedFields = allFiltersData[value]
        .filter(fDate => entity && entity.includes(fDate[idField]))
        .map(field => {
          if (value === 'storeIds') {
            const { cityName, brandName, buildingName } = getStoresData(
              allFiltersData,
              field,
            );
            return `${cityName} - ТЦ ${buildingName} ${brandName} ${field.code}`;
          }
          return field.name;
        });
      return translatedFields.join(', ');
    }
  };

  // Подготовка структуры заголовков таблицы
  let prepareColumns = Object.keys(tableHeaderData).map(el => {
    if (tableHeaderData[el].renderFn) {
      return {
        title: tableHeader(el),
        dataIndex: el,
        key: el,
        width: tableHeaderData[el].size,
        render: value => (
          <div>
            {tableHeaderData[el].date && value
              ? findInEntity(value, el, tableHeaderData[el].idField, 'date')
              : findInEntity(value, el, tableHeaderData[el].idField)}
          </div>
        ),
      };
    }
    return {
      title: tableHeader(el),
      dataIndex: el,
      key: el,
      width: tableHeaderData[el].size,
      fixed: el === 'name' && 'left',
    };
  });
  // кнопка удаления
  const addingDelBtn = {
    title: 'Удалить',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (text, record) => {
      return (
        <Popconfirm
          title="Вы уверены что хотте удалить?"
          onConfirm={() => handleDelete(record.id)}
        >
          <Icon type="delete" style={{ fontSize: '25px' }} />
        </Popconfirm>
      );
    },
  };

  prepareColumns = [...prepareColumns, addingDelBtn];

  // Удаление фильтров
  const handleDelete = key => {
    dispatch(delFilters(key))
      .then(_ => {
        openNotificationWithIcon('success', 'Фильтр успешно удален');
        setTableData(tableData.filter(item => item.id !== key));
      })
      .catch(e => {
        openNotificationWithIcon('error', 'Упс, что-то пошло не так :(', e);
      });
  };

  return (
    <div style={{ paddingTop: 64 }}>
      <Link to="/requests" style={{ margin: 20, display: 'block' }}>
        <Button type="primary">
          <Icon type="left" />
          Назад
        </Button>
      </Link>
      <Table
        columns={prepareColumns.map(item => ({ ...item, ellipsis: true }))}
        loading={!tableData.length > 0}
        rowClassName="custom-table-row"
        dataSource={tableData}
        scroll={{ x: 1500 }}
        rowKey="id"
        bordered
      />
    </div>
  );
};

export default FiltersForm;
