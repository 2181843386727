import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCities } from 'slices/Cities';
import { getBuildings } from 'slices/Buildings';
import { getBrands } from 'slices/Brands';

const useGetStoresData = () => {
  const dispatch = useDispatch();

  const buildings = useSelector(state => state.buildings.list);
  const stores = useSelector(state => state.stores.withInactiveList);

  useEffect(() => {
    if (stores && stores.length) {
      const buildingsIds = stores.map(s => s.buildingId);
      dispatch(getBuildings({ ids: buildingsIds }));

      const brandsIds = buildings.map(b => b.brandId);
      dispatch(getBrands({ ids: brandsIds }));
    }
  }, [JSON.stringify(stores)]); // eslint-disable-line

  useEffect(() => {
    if (buildings && buildings.length) {
      const citiesIds = buildings.map(b => b.cityId);
      dispatch(getCities({ ids: citiesIds }));
    }
  }, [JSON.stringify(buildings)]); // eslint-disable-line
};

export default useGetStoresData;
