import React, { useState, useEffect, useRef, useMemo } from 'react';
import localforage from 'localforage';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

import Table from './components/Table';
import AvailableColumns from './components/AvailableColumns';
import FilterSidebar from 'components/common/FilterSidebar';
import defaultColumnsData from './defaultColumnsData';

function RequestsForm() {
  const filterInput = useRef(null);

  const [columnsData, setColumnsData] = useState(defaultColumnsData);
  const [filterData, setFilterData] = useState(null);
  const [page, setPage] = useState(null);
  const [perPage, setPerPage] = useState(null);
  const [filterStr, setFilterStr] = useState('');

  const totalRec = useSelector(state => state.requests.totalRec);

  useEffect(() => {
    localforage.setItem('activeColumns', JSON.stringify(columnsData));
  }, [columnsData]);

  useEffect(() => {
    function loadFromLF() {
      localforage.getItem('activeColumns').then(savedColumns => {
        if (savedColumns) setColumnsData(JSON.parse(savedColumns));
      });
    }

    loadFromLF();
  }, []);

  // по ctrl + f - ставим фокус на инпут фильтрации
  useEffect(() => {
    const handler = e => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
        e.preventDefault();
        filterInput.current.focus();
      }
      return;
    };
    window.addEventListener('keydown', handler);

    return () => window.removeEventListener('keydown', handler);
  }, []);

  const getPaginationSteps = dataLength => {
    // считывает при загрузке и изменения данные из урла и иниц-ет применение фильтра
    if (!dataLength) return;
    return [25, 50, 100, 200];
  };

  const pageSteps = useMemo(() => getPaginationSteps(totalRec), [totalRec]);

  return (
    <div style={{ background: '#fff', marginTop: 64 }}>
      <Layout>
        <FilterSidebar
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          filterData={filterData}
          columnsData={columnsData}
          setFilterData={setFilterData}
        />
        <div>
          <AvailableColumns
            ref={filterInput}
            columns={columnsData}
            edit={setColumnsData}
            filterStr={filterStr}
            onChangeFilter={setFilterStr}
          />
          <Table
            page={page}
            perPage={perPage}
            setPage={setPage}
            filterStr={filterStr}
            pageSteps={pageSteps}
            setPerPage={setPerPage}
            columnsData={columnsData}
            setColumnsData={setColumnsData}
          />
        </div>
      </Layout>
    </div>
  );
}

export default RequestsForm;
