import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import Button from 'components/controls/base/Button';
import { changePassword } from 'slices/Sessions';
import {
  formItemLayout,
  tailFormItemLayout,
  minCharacters,
} from 'components/forms/CabinetForm/constants';
import openNotificationWithIcon from 'components/controls/base/Notification';

const ChangePass = props => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const { getFieldDecorator, resetFields } = props.form;
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, { current, next }) => {
      if (!err) {
        dispatch(
          changePassword({
            current,
            next,
          }),
        ).then(resp => {
          if (resp === 400) {
            openNotificationWithIcon('error', 'Старый пароль указан не верно');
            return;
          }
          openNotificationWithIcon('success', 'Пароль успешно изменен');
          resetFields();
        });
      }
    });
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('next')) {
      callback('Пароли не совпадают');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;
    const next = form.getFieldValue('next');
    const current = form.getFieldValue('current');
    if (current === next) {
      callback('Новый пароль не может совпадать со старым.');
    }

    if (value && confirmDirty) {
      callback();
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Старый пароль" hasFeedback>
        {getFieldDecorator('current', {
          rules: [
            {
              required: true,
              message: 'Пожалуйста введите  пароль!',
            },
            {
              min: minCharacters,
              message: `Пароль должно быть не менее ${minCharacters} символов.`,
            },
            {
              validator: validateToNextPassword,
            },
          ],
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label="Новый пароль" hasFeedback>
        {getFieldDecorator('next', {
          rules: [
            {
              required: true,
              message: 'Пожалуйста введите новый пароль',
            },
            {
              min: minCharacters,
              message: `Пароль должно быть не менее ${minCharacters} символов.`,
            },
            {
              validator: validateToNextPassword,
            },
          ],
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label="Подтвердите пароль" hasFeedback>
        {getFieldDecorator('confirm', {
          rules: [
            {
              required: true,
              message: 'Пожалуйста подтвердите ваш новый пароль',
            },
            {
              min: minCharacters,
              message: `Пароль должно быть не менее ${minCharacters} символов.`,
            },
            {
              validator: compareToFirstPassword,
            },
          ],
        })(<Input.Password onBlur={handleConfirmBlur} />)}
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" title="Изменить" />
      </Form.Item>
    </Form>
  );
};

export default Form.create({ name: 'register' })(ChangePass);
