import React from 'react';
import NotificationsForm from 'components/forms/NotificationsForm';

const CabinetPage = () => (
  <div style={{ height: '100%' }}>
    <NotificationsForm />
  </div>
);

export default CabinetPage;
