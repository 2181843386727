import { combineReducers } from 'redux';
import localforage from 'localforage';

import branches from './slices/Branches';
import brands from './slices/Brands';
import buildings from './slices/Buildings';
import cities from './slices/Cities';
import companies from './slices/Companies';
import policies from './slices/Policies';
import priorities from './slices/Priorities';
import requests from './slices/Requests';
import sessions from './slices/Sessions';
import stores from './slices/Stores';
import filters from './slices/Filters';
import users from './slices/Users';
import files from './slices/Files';
import notifications from './slices/Notifications';
import selectsFilters from './slices/SelectsFilters';
import estimates from './slices/Estimates';

const appReducer = combineReducers({
  users,
  files,
  brands,
  stores,
  cities,
  filters,
  branches,
  policies,
  requests,
  sessions,
  companies,
  buildings,
  estimates,
  priorities,
  notifications,
  selectsFilters,
});

const rootReducer = (state, action) => {
  if (action.type === 'sessions/clearUser') {
    localforage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
