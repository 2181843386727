import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getFilters } from 'slices/Filters';
import { getCities } from 'slices/Cities';
import { getBuildings } from 'slices/Buildings';
import { getBrands } from 'slices/Brands';
import { getBranches } from 'slices/Branches';
import { getPriorities } from 'slices/Priorities';
import { getStores } from 'slices/Stores';
import { getRequestStates } from 'slices/Requests';

export const useGetFiltersData = () => {
  const dispatch = useDispatch();
  const cityIds = useSelector(state => state.cities.list);
  const buildingIds = useSelector(state => state.buildings.list);
  const brandIds = useSelector(state => state.brands.list);
  const branches = useSelector(state => state.branches.list);
  const priorities = useSelector(state => state.priorities.list);
  const storeIds = useSelector(state => state.stores.withInactiveList);
  const states = useSelector(state => state.requests.states);

  // Запрашиваем все данные
  useEffect(() => {
    dispatch(getFilters());
    dispatch(getCities());
    dispatch(getBuildings());
    dispatch(getBrands());
    dispatch(getBranches());
    dispatch(getPriorities());
    dispatch(getRequestStates());
    if (!storeIds || !storeIds.length) dispatch(getStores());
  }, []);

  return {
    cityIds,
    buildingIds,
    brandIds,
    branches,
    priorities,
    storeIds,
    states,
  };
};
