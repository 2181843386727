import React, { forwardRef } from 'react';
import { Input } from 'antd';
import './styles.scss';

const TextArea = ({ compact, value, textCenter = false, ...props }, ref) => {
  if (compact) {
    return (
      <div
        className={`disableTextArea highlighter ${textCenter ? 'center' : ''}`}
      >
        {value}
      </div>
    );
  }
  return <Input.TextArea ref={ref} value={value} {...props} />;
};

export default forwardRef(TextArea);
