import React from 'react';
import FiltersForm from '../../forms/FilterForm';

const FiltersPage = () => {
  return (
    <div>
      <FiltersForm />
    </div>
  );
};

export default FiltersPage;
