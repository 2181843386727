import { APIService } from '../api/rest';

export const Files = {
  async getCounter(requestIds) {
    try {
      return await APIService.GET('/files/counters', { requestIds });
    } catch (e) {
      return e.response.data;
    }
  },
  async getFile(requestId) {
    try {
      return await APIService.GET(`/files/${requestId}`, null, true);
    } catch (e) {
      return e.response.data;
    }
  },

  async getFiles(requestId) {
    try {
      return await APIService.GET(`/files`, { requestId });
    } catch (e) {
      throw e;
    }
  },

  async postFiles({ id: requestId, type, value }, formData) {
    // TODO: поля type, value отсылаются из components\forms\DetailsForm\components\RequestForm
    // Временное решение. Нужно понять как отправлять квери парамс вместе с formData у axous
    const req = type
      ? `/files?requestId=${requestId}&${type}=${value}`
      : `/files?requestId=${requestId}`;
    try {
      return await APIService.POST(req, formData);
    } catch (e) {
      throw e;
    }
  },
};
