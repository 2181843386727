// import { message } from 'antd';
import { createSlice } from 'redux-starter-kit';
import { Branches } from 'connectors/query/Branches';

const BranchesSlice = createSlice({
  name: 'branches',

  initialState: {
    list: [],
  },

  reducers: {
    setBranches: (state, { payload }) => {
      state.list = payload || [];
    },
  },
});

export default BranchesSlice.reducer;

export const { setBranches } = BranchesSlice.actions;

export const getBranches = () => async dispatch => {
  const response = await Branches.getList();
  await dispatch(setBranches(response));
};
