export const tableColumnLargeSize = '300px';
export const tableColumnSmallSize = '130px';
// Структура заголовков таблицы на странице фильтров
export const tableHeaderData = {
  name: { title: 'Имя фильтра', size: tableColumnLargeSize },
  externalCodeLike: { title: 'Внешний № Заявки', size: tableColumnSmallSize },
  codeLike: { title: 'Заявка', size: tableColumnSmallSize },
  states: {
    title: 'Статус',
    size: tableColumnLargeSize,
    idField: 'code',
    renderFn: true,
  },
  branches: {
    title: 'Отрасль',
    size: tableColumnLargeSize,
    idField: 'code',
    renderFn: true,
  },
  priorities: {
    title: 'Приоритет',
    size: tableColumnLargeSize,
    idField: 'code',
    renderFn: true,
  },
  brandIds: {
    title: 'Бренд',
    size: tableColumnSmallSize,
    idField: 'id',
    renderFn: true,
  },
  cityIds: {
    title: 'Город',
    size: tableColumnSmallSize,
    idField: 'id',
    renderFn: true,
  },
  buildingIds: {
    title: 'ТЦ',
    size: tableColumnSmallSize,
    idField: 'id',
    renderFn: true,
  },
  storeIds: {
    title: 'Объекты',
    size: tableColumnLargeSize,
    idField: 'id',
    renderFn: true,
  },
  completeAtFrom: {
    title: 'Срок с',
    date: true,
    renderFn: true,
    size: tableColumnSmallSize,
  },
  completeAtTo: {
    title: 'Срок до',
    date: true,
    renderFn: true,
    size: tableColumnSmallSize,
  },
  createdAtFrom: {
    title: 'Создана c',
    date: true,
    renderFn: true,
    size: tableColumnSmallSize,
  },
  createdAtTo: {
    title: 'Создана до',
    date: true,
    renderFn: true,
    size: tableColumnSmallSize,
  },
  createdAt: {
    title: 'Создана',
    date: true,
    renderFn: true,
    size: tableColumnSmallSize,
  },
};
