import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
// import _get from 'lodash/get';
import { createSlice } from 'redux-starter-kit';
import { Cities } from 'connectors/query/Cities';

const CitiesSlice = createSlice({
  name: 'cities',

  initialState: {
    loading: false,
    list: [],
  },

  reducers: {
    setCities: (state, { payload }) => {
      state.list = _uniqWith([...state.list, ...payload], _isEqual) || [];
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default CitiesSlice.reducer;

export const { setCities, setLoading } = CitiesSlice.actions;

export const getCities = params => async (dispatch, getState) => {
  dispatch(setLoading(true));
  // console.log('getState().cities.list', getState().cities.list);
  // const newParams = prepareParamsWithNoGettedIds(
  //   params,
  //   getState().cities.list,
  // );
  // console.log('params', params, newParams);

  const response = await Cities.getList(params);
  await dispatch(setCities(response));
  dispatch(setLoading(false));
};
