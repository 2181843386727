import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';

import {
  Form,
  Input,
  Icon,
  Upload,
  Typography,
  Divider,
  notification,
} from 'antd';

import { getPolicies } from 'slices/Policies';
// import { saveRequest, uploadFiles } from 'slices/Requests';
import { saveRequest } from 'slices/Requests';
import { uploadFiles } from 'slices/Files';

import { postComment } from 'slices/Comments';

import {
  BranchesSelect,
  CompaniesSelect,
  PrioritiesSelect,
  StoresSelect,
} from 'components/controls/selects';
import DatePicker from 'components/controls/base/DatePicker';
import TextArea from 'components/controls/base/TextArea';
import Button from 'components/controls/base/Button';
import openNotificationWithIcon from 'components/controls/base/Notification';
import { cutFields } from 'utils/helpers';
import { handleAddFiles } from './helpers';

import { formItemLayout, tailFormItemLayout } from './constants';
import { defaultDateFormat } from 'utils/constants';

const { Dragger } = Upload;
const { Title } = Typography;

const uploadFileDebounced = _debounce(handleAddFiles, 500);

const AddForm = ({ form }) => {
  const { getFieldDecorator, resetFields } = form;
  const history = useHistory();
  const dispatch = useDispatch();
  const [filesToUpload, setFilesToUpload] = useState([]);

  useEffect(() => {
    dispatch(getPolicies());
  }, []);

  const prepareFiles = id => {
    const formData = new FormData();
    if (filesToUpload.length > 0) {
      filesToUpload.forEach(file => formData.append(`files`, file));

      dispatch(uploadFiles({ id }, formData, false)).catch(() => {
        openNotificationWithIcon('error', 'Файлы не добавлены');
        return;
      });
      setFilesToUpload([]);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const preparedValues = {
          ...values,
          ...(values.completeAt && {
            completeAt: values.completeAt.format(defaultDateFormat),
          }),
        };

        dispatch(
          saveRequest(
            cutFields(['comment', 'files'], {
              ...preparedValues,
              // contractorCompanyId - хардкодим в 11 для версии 19
              contractorCompanyId: 11,
            }),
          ),
        )
          .then(action => {
            if (preparedValues.files) {
              prepareFiles(action.id);
            }
            if (preparedValues.comment) {
              dispatch(
                postComment(
                  { requestId: action.id, text: preparedValues.comment },
                  false,
                ),
              ).catch(_ => {
                openNotificationWithIcon('error', 'Комментарий не добавлен');
                return;
              });
            }
            resetFields();
            notification.open({
              message: 'Заявка создана',
              description: 'Перейти к созданной заявке?',
              btn: (
                <Button
                  title="Перейти"
                  onClick={() => history.push(`requests/${action.id}`)}
                />
              ),
              duration: 2,
            });
          })
          .catch(err => {
            openNotificationWithIcon('error', _get(err, 'data.message', ''));
          });
      }
    });
  };

  return (
    <div className="content_container">
      <Title level={2} style={{ textAlign: 'center' }}>
        Добавление заявки
      </Title>
      <Form
        {...formItemLayout}
        onSubmit={onSubmit}
        style={{ maxWidth: '800px', margin: 'auto' }}
      >
        <Form.Item label={<span>Внешний номер заявки</span>}>
          {getFieldDecorator('externalCode')(<Input maxLength={20} />)}
        </Form.Item>

        <Form.Item label={<span>Описание</span>}>
          {getFieldDecorator('details', {
            rules: [{ required: true, message: 'Ввведите описание!' }],
          })(<TextArea rows={4} />)}
        </Form.Item>

        <Form.Item label={<span>Объект</span>}>
          {getFieldDecorator('storeId', {
            rules: [
              {
                required: true,
                message: 'Укажите объект!',
              },
            ],
          })(<StoresSelect style={{ width: '100%' }} />)}
        </Form.Item>

        <Form.Item label={<span>Отрасль</span>}>
          {getFieldDecorator('branch', {
            initialValue: 'exploit',
            rules: [
              {
                required: true,
                message: 'Укажите отрасль!',
              },
            ],
          })(<BranchesSelect />)}
        </Form.Item>

        <Form.Item label={<span>Приоритет</span>}>
          {getFieldDecorator('priority', {
            initialValue: 'normal',
            rules: [
              {
                required: true,
                message: 'Укажите приоритет!',
              },
            ],
          })(<PrioritiesSelect />)}
        </Form.Item>

        {/* скрываем в 19 версии и хардкодим id = 11
        <Form.Item label={<span>Исполнитель</span>}>
          {getFieldDecorator('contractorCompanyId', {
            initialValue: 11,
            rules: [
              {
                required: true,
                message: 'Укажите исполнителя!',
              },
            ],
          })(<CompaniesSelect params={{ onlyContractors: true }} />)}
        </Form.Item> */}

        <Form.Item label={<span>Срок выполнения</span>}>
          {getFieldDecorator('completeAt')(
            <DatePicker
              style={{ width: '200px' }}
              disabledDate={current =>
                current && current < moment().startOf('day')
              }
            />,
          )}
        </Form.Item>

        <Divider />

        <Form.Item label={<span>Комментарий</span>}>
          {getFieldDecorator('comment')(<TextArea rows={4} />)}
        </Form.Item>

        <Form.Item label={<span>Файлы</span>}>
          {getFieldDecorator('files')(
            <Dragger
              fileList={filesToUpload}
              name={'files'}
              multiple
              beforeUpload={(file, files) => {
                uploadFileDebounced({ files, filesToUpload, setFilesToUpload });
                return false;
              }}
              onRemove={file => {
                setFilesToUpload(
                  filesToUpload.filter(
                    f => f.lastModified !== file.lastModified,
                  ),
                );
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Нажмите или перетащите файлы для загрузки
              </p>
            </Dragger>,
          )}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" title="Отправить" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create({ name: 'addPage' })(AddForm);
