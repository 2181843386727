import { APIService } from 'connectors/api/rest';

export const Policies = {
  async getData() {
    try {
      return await APIService.GET('policies');
    } catch (e) {
      return null;
    }
  },
};
