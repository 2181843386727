import React, { useState } from 'react';
import { Layout, Icon } from 'antd';

import CustomizedFilterForm from './components/CustomizedFilterForm';
import 'antd/dist/antd.css';
import './styles.scss';

const FilterSidebar = ({
  form = {},
  setFilterData = _ => _,
  setPerPage = _ => _,
  setPage = _ => _,
  page,
  perPage,
  filterData,
  clearCardParams,
  infoCardParams,
  columnsData,
}) => {
  const [collapsed, onCollapse] = useState(false);
  const { Sider } = Layout;

  // скрывает/показывает сайдбар
  const handleOnCollapse = () => onCollapse(!collapsed);

  const onChange = (changedFields, allFields, resetFields) => {
    // Очистка полей бренд, объекты при выборе сити, бренда
    // TODO: доделать.
    // Обнуление срабатывает на onchange,
    // при выборе сох - ого фильтра не должно срабатывать очищение
    // if (
    //   changedFields.cityIds &&
    //   (allFields.storeIds.value || allFields.buildingIds.value)
    // ) {
    //   return resetFields(['buildingIds', 'storeIds']);
    // }
    // if (changedFields.buildingIds && allFields.storeIds.value) {
    //   return resetFields('storeIds');
    // }
  };

  return (
    <Sider
      collapsed={collapsed}
      width={250}
      style={{ minHeight: '100vh' }}
      className="filter_sidebar"
    >
      <div
        className={`collapse-btn ${collapsed ? 'close' : ''}`}
        onClick={handleOnCollapse}
      >
        <Icon type="left" theme="outlined" />
        {!collapsed && <h4 style={{ marginLeft: '10px' }}>ПАНЕЛЬ ФИЛЬТРОВ</h4>}
      </div>
      <CustomizedFilterForm
        onChange={onChange}
        setFilterData={setFilterData}
        setPerPage={setPerPage}
        setPage={setPage}
        page={page}
        perPage={perPage}
        filterData={filterData}
        clearCardParams={clearCardParams}
        infoCardParams={infoCardParams}
        columnsData={columnsData}
      />
    </Sider>
  );
};

export default FilterSidebar;
