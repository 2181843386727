import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import localforage from 'localforage';
import { Layout } from 'antd';
import { setUser } from 'slices/Sessions';

import LoginPage from 'components/pages/login-page';
import Header from 'components/common/Header';
import './style.scss';
const { Content } = Layout;

function AuthLayout({ children = {} }) {
  const user = useSelector(state => state.sessions.user);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    async function restore() {
      const user = await localforage.getItem('user');
      const token = await localforage.getItem('token');
      dispatch(setUser({ user: JSON.parse(user), token }));
    }
    restore();
  }, []);

  return (
    <Layout>
      <Layout style={{ minHeight: '100vh' }}>
        {user !== null && <Header />}
        <Content className={`${!user && 'main_container'}`}>
          {user === null ? <LoginPage /> : children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default AuthLayout;
