import React from 'react';
import {
  Input,
  Checkbox,
  Popconfirm,
  InputNumber as InputCurrency,
} from 'antd';
import _uniqueId from 'lodash/uniqueId';
import _clone from 'lodash/clone';

import { PriceSelect } from 'components/controls/selects/PriceSelect';

// Редактирует для каждый таблицы данные
export const editTableCell = ({ estimates, curDataRow }) => {
  const newData = estimates.map(tableFields => {
    if (tableFields.tableTitle === curDataRow.tableTitle) {
      const newTableData = tableFields.data.map(tableRow => {
        if (tableRow.id === curDataRow.row.id) return curDataRow.row;
        return tableRow;
      });
      return { data: newTableData, tableTitle: curDataRow.tableTitle };
    }
    return tableFields;
  });
  return newData;
};

export const createNewRowFields = fields => {
  const newData = _clone(fields);
  newData.id = _uniqueId('customId_');
  return newData;
};

export const getTableHeaders = ({
  dispatch,
  tableTitle,
  tableHeaders = [],
  handleDel = () => {},
}) => {
  const clonedData = _clone(tableHeaders);
  const delBtn = {
    title: 'Действие',
    dataIndex: 'action',
    render: (text, record) => (
      <Popconfirm
        title="Вы уверены, что хотите удалить ?"
        onConfirm={() => {
          dispatch(handleDel({ id: record.id, tableTitle }));
        }}
      >
        <a>Удалить строку</a>
      </Popconfirm>
    ),
  };
  clonedData.push(delBtn);

  return clonedData;
};

// Получает корректное имя для селекта ед.измерения
export const getCurrentUnit = (units, cur) => {
  try {
    const currentUnit = units.find(u => u.id === cur.unitId);
    return (currentUnit && currentUnit.name) || '';
  } catch (error) {
    console.error('getCurrentUnit fn have error', error);
  }
};

// проверяет наличие всех обяз-ых полей
export const checkRequiredFields = (data, requiredFields) => {
  return requiredFields.every(field => Boolean(data[field]));
};

// форматирует 0000 в 0 000
export const setCurrencyFormat = value => {
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getFullPrice = (price = 1, count = 1) => {
  try {
    const res = ((price * 100 * count) / 100).toFixed(2);
    return setCurrencyFormat(res);
  } catch (error) {
    console.log('setCurrencyFormat fn', error);
  }
};

export const checkCustomId = id => {
  return typeof id === 'string' && id.includes('customId_');
};

export const getFormEl = ({
  form,
  units,
  field,
  record,
  inputEl,
  onClick,
  toggleEdit,
  typesFormEl,
  handleOnSave,
}) => {
  if (typesFormEl[field].elem === 'select') {
    return (
      <PriceSelect
        units={units}
        onBlur={toggleEdit}
        onClick={onClick}
        onChange={e => {
          handleOnSave({
            eventValue: e,
            form,
            field,
          });
        }}
        currentId={record.id}
      />
    );
  }

  if (typesFormEl[field].elem === 'input') {
    return (
      <Input
        ref={inputEl}
        onClick={onClick}
        onBlur={e => {
          const eventValue = e.currentTarget.value;
          handleOnSave({
            eventValue,
            form,
            field,
          });
          toggleEdit();
        }}
      />
    );
  }

  if (typesFormEl[field].elem === 'inputNumber') {
    const defaultProps = {
      ref: inputEl,
      onClick,
      onBlur: e => {
        const eventValue = e.currentTarget.value.replace(/\$\s?|(\s)/g, '');
        handleOnSave({
          eventValue,
          form,
          field,
        });
        toggleEdit();
      },
    };

    if (typesFormEl[field].type === 'inputCurrency') {
      return (
        <InputCurrency
          style={{ width: '100%' }}
          {...defaultProps}
          formatter={value => {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          }}
          parser={value => value.replace(/\$\s?|(\s)/g, '')}
          precision={2}
        />
      );
    }
    return <InputCurrency {...defaultProps} min={1} />;
  }

  if (typesFormEl[field].elem === 'checkbox') {
    return (
      <Checkbox
        onBlur={toggleEdit}
        onClick={onClick}
        onChange={e => {
          handleOnSave({
            eventValue: e.target.checked,
            form,
            field,
            type: typesFormEl[field].elem,
          });
        }}
        defaultChecked={!!record[field]}
      />
    );
  }

  return (
    <Input
      ref={inputEl}
      onBlur={toggleEdit}
      onClick={onClick}
      onChange={() => {}}
    />
  );
};

// Получает значение для полей в нередактируемом виде
export const getTitle = ({
  units,
  record,
  children,
  dataIndex,
  typesFormEl,
}) => {
  // Если select выдать значение по id
  if (dataIndex === 'unitId') return getCurrentUnit(units, record);

  // если цены отформатировать по маске 0 000.00
  if (typesFormEl[dataIndex].type === 'inputCurrency') {
    return setCurrencyFormat(record[dataIndex]);
  }

  return children;
};

export const getRules = ({ dataIndex, requiredFields, typesFormEl }) => {
  const field = typesFormEl[dataIndex];
  const rules = {
    message: 'Заполните поле',
    required: requiredFields.includes(dataIndex),
  };

  if (field.elem === 'inputNumber' && field.type === 'number') {
    rules.pattern = new RegExp(/^[0-9]*?[0-9]$/g);
  }

  if (field.elem === 'inputNumber' && field.type === 'inputCurrency') {
    rules.pattern = new RegExp('^[0-9]*[.]?[0-9]+$');
  }

  return rules;
};
