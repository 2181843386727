import React from 'react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Spin, Carousel } from 'antd';

import { LocalizedModal } from 'components/controls/base/LocalizedModal';

import '../../styles.scss';

const PictureModal = ({
  visible,
  handleModal,
  downloadFile,
  indexSlide,
  onChangeSlide,
  imageFiles,
  files,
  dispatch,
  updateBlobFiles,
  getFile,
  setIndexSlide,
  carousel,
  linkToDownload,
  url,
  fileName,
}) => {
  return (
    <>
      {/* Модалка для превью фоток */}
      <LocalizedModal
        title=""
        centered
        visible={visible}
        maskClosable={true}
        cancelText="Отмена"
        okText="Скачать"
        onOk={() =>
          downloadFile({
            id: indexSlide,
            slider: true,
            otherFiles: false,
          })
        }
        onCancel={() => handleModal(false)}
        width={'100%'}
        style={{ zIndex: 999 }}
      >
        <Carousel
          afterChange={current =>
            onChangeSlide({
              id: current,
              slider: true,
              otherFiles: false,
              imageFiles,
              files,
              dispatch,
              updateBlobFiles,
              getFile,
              setIndexSlide,
            })
          }
          ref={carousel}
        >
          {imageFiles &&
            imageFiles.map(image => (
              <div
                style={{
                  width: '100%',
                  height: '64vh',
                }}
                key={image.id}
              >
                <Spin spinning={!image.isLoaded} size="large" delay={200}>
                  <div
                    className="carousel_elem"
                    style={{
                      background: `url(${image.src || ''}) no-repeat 50% 50%`,
                    }}
                  ></div>
                </Spin>
              </div>
            ))}
        </Carousel>
      </LocalizedModal>
      <a ref={linkToDownload} href={url} download={fileName} />
    </>
  );
};

export default PictureModal;
