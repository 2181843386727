import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBuildingsOptions } from 'slices/SelectsFilters';

import Select from 'components/controls/base/Select';

export const BuildingsSelect = forwardRef(
  (
    { label = '', params = {}, withCity = false, citiesChoosen = [], ...props },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [gettedData, setGettedData] = useState(false);
    const { buildingsLoading, buildingsOptions } = useSelector(
      state => state.selectsFilters,
    );

    useEffect(() => {
      if (props.value && props.value.length)
        dispatch(getBuildingsOptions({ ids: props.value, used: true, withCity }));
    }, [JSON.stringify(props.value)]);

    let preparedBuildings = buildingsOptions;
    if (citiesChoosen.length > 0) {
      preparedBuildings = preparedBuildings.filter(el =>
        citiesChoosen.includes(el.cityId),
      );
    }

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        <Select
          {...props}
          options={preparedBuildings}
          onDropdownVisibleChange={() => {
            if (!gettedData) {
              dispatch(getBuildingsOptions({ ...params, used: true, withCity }));
              setGettedData(true);
            }
          }}
          loading={buildingsLoading}
        />
      </div>
    );
  },
);
