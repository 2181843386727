import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import SmetaTable from '../../SmetaTable';

import { LocalizedModal } from 'components/controls/base/LocalizedModal';
import { sendExcellEstimates } from 'slices/Estimates';
import {
  tableColTehnicial,
  tableColContractor,
  tableNewRowTehnicial,
  tableNewRowContractor,
} from '../../SmetaTable/constant';
import { setCurrencyFormat } from '../../SmetaTable/helpers';

import { Col, Row, Icon, Upload, Divider } from 'antd';
import FileList from '../../../FileList';

import '../../styles.scss';

const { Dragger } = Upload;

const SmetaModal = ({
  role,
  visible,
  filesToUpload,
  estimates,
  requestId,
  handleModal,
  downloadFile,
  setFilesToUpload,
  setCount,
  showModal,
}) => {
  const dispatch = useDispatch();
  const files = useSelector(state => state.files.list);

  const [smetaTotal, setSmetaTotal] = useState({ work: 0, mat: 0 });

  const isContractor = role === 'contractor';

  const tableHeaders = isContractor ? tableColContractor : tableColTehnicial;

  const tableNewRowStructure = isContractor
    ? tableNewRowContractor
    : tableNewRowTehnicial;

  return (
    <LocalizedModal
      visible={visible}
      maskClosable={true}
      cancelText="Закрыть"
      wrapClassName="smetaModal"
      title="Редактирование сметы"
      okText="Сформировать Excel файл"
      onOk={() => {
        dispatch(sendExcellEstimates(requestId));
        handleModal(false);
      }}
      onCancel={() => handleModal(false)}
    >
      {estimates.map(tableData => {
        return (
          <SmetaTable
            tableFieldsData={{
              tableHeaders,
              tableNewRowStructure,
            }}
            list={tableData}
            requestId={requestId}
            key={tableData.tableTitle}
            isContractor={isContractor}
            setSmetaTotal={setSmetaTotal}
          />
        );
      })}
      {isContractor && (
        <>
          <Divider />
          <Row type="flex" justify="end">
            <h3>
              Стоимость сметы:{' '}
              {setCurrencyFormat(smetaTotal.work + smetaTotal.mat)}
            </h3>
          </Row>
        </>
      )}

      <Row>
        {/* all files */}
        <Col span={24}>
          <Col span={16} className="file_wrap">
            {files &&
              files.map((file, i) => {
                const { receipt } = file;
                if (receipt) {
                  return (
                    <FileList
                      index={i}
                      file={file}
                      key={file.id}
                      showModal={showModal}
                      downloadFile={downloadFile}
                    />
                  );
                }
              })}
          </Col>
          <Col span={8} className="area_file">
            <Dragger
              fileList={filesToUpload.typeFile}
              fileList={[]}
              name="file"
              multiple
              onChange={({ fileList }) => {
                setCount(count => count + 1);
                setFilesToUpload({
                  ...filesToUpload,
                  receipt: fileList,
                  typeFile: { type: 'receipt', value: true },
                });
                return false;
              }}
              beforeUpload={() => false}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Нажмите или перенесите{' '}
                <span style={{ color: '#1890ff' }}> файл </span> в эту область
              </p>
            </Dragger>
          </Col>
        </Col>
      </Row>
    </LocalizedModal>
  );
};

export default SmetaModal;
