import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { createSlice } from 'redux-starter-kit';
import { Buildings } from 'connectors/query/Buildings';

const BuildingsSlice = createSlice({
  name: 'buildings',

  initialState: {
    list: [],
  },

  reducers: {
    setBuildings: (state, { payload }) => {
      state.list = _uniqWith([...state.list, ...payload], _isEqual) || [];
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default BuildingsSlice.reducer;

export const { setBuildings, setLoading } = BuildingsSlice.actions;

export const getBuildings = params => async dispatch => {
  dispatch(setLoading(true));
  const response = await Buildings.getList(params);
  await dispatch(setBuildings(response));
  dispatch(setLoading(false));
  return response;
};
