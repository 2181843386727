import React from 'react';
import { Icon, Row, Col, Typography, Tooltip } from 'antd';
import './style.scss';

const { Paragraph } = Typography;

const FileList = ({ file = {}, downloadFile, showModal, index = 0 }) => {
  const { id, name, mimetype, fullName } = file;
  return (
    <Row
      className="filelist_wrap"
      justify="space-between"
      align="middle"
      type="flex"
      gutter={[5, 5]}
    >
      <Col sm={20} xs={18}>
        <Row type="flex" align="middle" className="filelist_info">
          <Icon type="file-done" className="file_done" />
          <Paragraph className="file_descr" ellipsis>
            {name}
          </Paragraph>
          <Tooltip title={fullName} className="filelist_info_icon">
            <Icon type="info-circle" theme="twoTone" />
          </Tooltip>
        </Row>
      </Col>
      <Col sm={4} xs={6}>
        <Row type="flex" justify="end" align="middle">
          <span className="file_elem_icon">
            {mimetype.includes('image') && (
              <Icon
                type="eye"
                onClick={() => showModal(file, index)}
                className="file_btn"
              />
            )}
          </span>
          <Icon
            type="download"
            className="file_btn"
            onClick={() => {
              downloadFile({ id, name });
            }}
          />
          {/* <Icon className="file_del file_btn" type="delete" /> */}
        </Row>
      </Col>
    </Row>
  );
};

export default FileList;
