import React, { useState, forwardRef, useEffect } from 'react';
import { Form, Typography, Icon, Row, Col, Divider } from 'antd';
import TextArea from 'components/controls/base/TextArea';

import './style.scss';

const { Title, Paragraph } = Typography;

const RequestInfoPart = (
  {
    form,
    notices,
    completeJobs,
    leftInStore,
    uncompleteJobs,
    // rejectedDetails,
    availableFields,
    suppliedByClient,
    suppliedByService,
    contractorInfoSmallSize,
  },
  ref,
) => {
  const { getFieldDecorator } = form;

  const [smallSize, setSmallSize] = useState(false);
  const resize = () => {
    setSmallSize(!smallSize);
  };

  useEffect(() => {
    setSmallSize(contractorInfoSmallSize);
  }, [contractorInfoSmallSize]);

  const hasAnyData =
    smallSize && (suppliedByClient || suppliedByService || leftInStore);
  const isCompactCostBlock = !smallSize || hasAnyData;

  return (
    <div
      ref={ref}
      className={`info-part blue ${smallSize && 'compact'}`}
      style={{ position: 'relative' }}
    >
      <Row type="flex" justify="space-between">
        <Title level={4}>Информация о исполнении</Title>
        <Icon
          onClick={resize}
          type={`${smallSize ? 'arrows-alt' : 'shrink'}`}
        />
      </Row>
      <div className="request_container">
        {smallSize && !completeJobs ? null : (
          <>
            <Divider> Описание выполненных действий</Divider>
            <Form.Item wrapperCol={{ sm: 24 }}>
              {getFieldDecorator('completeJobs', {
                initialValue: completeJobs,
              })(
                <TextArea
                  style={{ width: '100%' }}
                  rows={8}
                  compact={smallSize}
                  disabled={!availableFields.includes('completeJobs')}
                />,
              )}
            </Form.Item>
          </>
        )}
        {isCompactCostBlock && (
          <>
            <Divider>Расходы материалов</Divider>
            <Row>
              <Col
                span={8}
                className={`executionGroup ${smallSize ? 'compact' : ''}`}
              >
                <Paragraph className="info-paragraph">
                  Поставлено заказчиком
                </Paragraph>
                {smallSize && !suppliedByClient ? null : (
                  <Form.Item wrapperCol={{ sm: 23 }}>
                    {getFieldDecorator('suppliedByClient', {
                      initialValue: suppliedByClient,
                    })(
                      <TextArea
                        rows={8}
                        compact={smallSize}
                        disabled={!availableFields.includes('suppliedByClient')}
                        textCenter
                      />,
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col
                span={8}
                className={`executionGroup ${smallSize ? 'compact' : ''}`}
              >
                <Paragraph className="info-paragraph">
                  Поставлено исполнителем
                </Paragraph>
                {smallSize && !suppliedByService ? null : (
                  <Form.Item wrapperCol={{ sm: 23 }}>
                    {getFieldDecorator('suppliedByService', {
                      initialValue: suppliedByService,
                    })(
                      <TextArea
                        rows={8}
                        compact={smallSize}
                        disabled={
                          !availableFields.includes('suppliedByService')
                        }
                        textCenter
                      />,
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col
                span={8}
                className={`executionGroup ${smallSize ? 'compact' : ''}`}
              >
                <Paragraph className="info-paragraph">
                  Оставлено на объекте
                </Paragraph>
                {smallSize && !leftInStore ? null : (
                  <Form.Item wrapperCol={{ sm: 23 }}>
                    {getFieldDecorator('leftInStore', {
                      initialValue: leftInStore,
                    })(
                      <TextArea
                        rows={8}
                        compact={smallSize}
                        disabled={!availableFields.includes('leftInStore')}
                        textCenter
                      />,
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
          </>
        )}

        {smallSize && !notices ? null : (
          <>
            <Divider>Замечания и рекомендации</Divider>

            <Form.Item wrapperCol={{ sm: 24 }}>
              {getFieldDecorator('notices', {
                initialValue: notices,
              })(
                <TextArea
                  rows={4}
                  compact={smallSize}
                  disabled={!availableFields.includes('notices')}
                  textCenter
                />,
              )}
            </Form.Item>
          </>
        )}

        {smallSize && !uncompleteJobs ? null : (
          <>
            <Paragraph style={{ textAlign: 'center' }}>
              Действия, невыполненные по техническим и/или организационным
              причинам - которые будут выполнены при следующем
              уведомлении/закупочные материалы
            </Paragraph>
            <Form.Item wrapperCol={{ sm: 24 }}>
              {getFieldDecorator('uncompleteJobs', {
                initialValue: uncompleteJobs,
              })(
                <TextArea
                  rows={4}
                  compact={smallSize}
                  disabled={!availableFields.includes('uncompleteJobs')}
                  textCenter
                />,
              )}
            </Form.Item>
          </>
        )}
      </div>
      {/* {smallSize && !rejectedDetails ? null : (
        <Form.Item label={<span>Причина отклонения</span>}>
          {getFieldDecorator('rejectedDetails', {
            initialValue: rejectedDetails,
          })(
            <TextArea
              rows={8}
              compact={smallSize}
              disabled={!availableFields.includes('rejectedDetails')}
            />,
          )}
        </Form.Item>
      )} */}
    </div>
  );
};

export default forwardRef(RequestInfoPart);
