import React, { forwardRef } from 'react';
import { Icon, Input as AntInput } from 'antd';

const Input = forwardRef(
  ({ title, placeholder, compact, value, iconType, ...props }, ref) => {
    if (compact) return <div className="highlighter">{value}</div>;
    return (
      <>
        <p style={{ color: 'white', margin: 0 }}>{title}</p>
        <AntInput
          prefix={
            iconType && (
              <Icon type={iconType} style={{ color: 'rgba(0,0,0,.25)' }} />
            )
          }
          placeholder={placeholder}
          value={value}
          ref={ref}
          {...props}
        />
      </>
    );
  },
);

export default Input;
