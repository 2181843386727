import React from 'react';
import { configureStore } from 'redux-starter-kit';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import reducer from './reducer';
import { Provider } from 'react-redux';
import AuthLayout from 'components/common/AuthLayout';
import { QueryParamProvider } from 'use-query-params';

import InfoPage from 'components/pages/info-page';
import CabinetPage from 'components/pages/cabinet-page';
import AddPage from 'components/pages/add-page';
import FiltersPage from 'components/pages/filters-page';
import RequestsPage from 'components/pages/requests-page';
import DetailsPage from 'components/pages/details-page';
import NotificationsPage from 'components/pages/notifications-page';
// псевдо страница для списка созданных компонентов форм
// import StoriesPage from 'components/pages/stories-page';

export const store = configureStore({ reducer });

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AuthLayout>
            <Switch>
              <Route exact path={'/'} render={() => <InfoPage />} />
              <Route exact path={'/cabinet'} render={() => <CabinetPage />} />
              <Route exact path={'/add'} render={() => <AddPage />} />
              <Route exact path={'/filters'} render={() => <FiltersPage />} />
              <Route exact path={'/requests'} component={RequestsPage} />
              {/* <Route exact path={'/requests'} render={() => <RequestsPage />} /> */}
              {/* <Route exact path={'/stories'} render={() => <StoriesPage />} /> */}
              <Route
                exact
                path={'/notifications'}
                render={() => <NotificationsPage />}
              />
              <Route
                exact
                path={'/requests/:id'}
                render={() => <DetailsPage />}
              />
            </Switch>
          </AuthLayout>
        </QueryParamProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
