import axios from 'axios';
import 'whatwg-fetch';
import localforage from 'localforage';
import { store } from 'App';
import { stopSession } from 'slices/Sessions';
import qs from 'qs';

const instance = axios.create({
  baseURL: '/api',
});
const instanceXLSX = axios.create({
  baseURL: '/api',
  responseType: 'arraybuffer',
});

const axiosApi = async xlsx => {
  const token = await localforage.getItem('token');
  const newInstance = xlsx ? instanceXLSX : instance;
  if (token) {
    newInstance.defaults.headers.common.Authorization = `Bearer ${token.replace(
      /"/g,
      '',
    )}`;
  } else {
    delete newInstance.defaults.headers.common.Authorization;
  }

  return newInstance;
};

export const APIService = {
  async checkStatus401(status) {
    if (status === 401) {
      console.error('wotaFACK! 401');
      store.dispatch(stopSession());
    }
  },

  async checkStatus403(status) {
    if (status === 403) {
      console.error('wotaFACK! 403');
      window.location.href = '/';

      // TODO: убрать когда бэк будет отдавать 401, вместо 403
      store.dispatch(stopSession());
    }
  },

  checkStatus500(status) {
    if (status === 500) {
      console.error('wotaFACK! 500');
    }
  },

  async GET(route, params, excel = false, withHeaders = false) {
    try {
      const API = await axiosApi(excel);
      const response = await API.get(route, {
        params,
        //  TODO: метод делает из массива строку
        paramsSerializer: function (params) {
          const test = qs.stringify(params, { arrayFormat: 'comma' });
          return test;
        },
      });
      const { data, headers } = response;

      if (withHeaders) return response;
      return excel
        ? {
            fileData: data,
            nameFromHeader: headers['content-disposition'],
            mimeFromHeader: headers['content-type'],
          }
        : data;
    } catch ({ response: { status } }) {
      this.checkStatus401(status);
      this.checkStatus403(status);
      this.checkStatus500(status);
    }
  },

  async POST(route, params) {
    try {
      const API = await axiosApi();
      const { data } = await API.post(route, params);
      return data;
    } catch ({ response: { status, data } }) {
      this.checkStatus401(status);
      this.checkStatus403(status);
      this.checkStatus500(status);
      throw { data, status };
    }
  },

  async DELETE(route, params) {
    try {
      const API = await axiosApi();
      console.log('PARAMS', params);
      const { data } = await API.delete(route, params);
      return data;
    } catch ({ response: { status } }) {
      this.checkStatus401(status);
      this.checkStatus403(status);
      this.checkStatus500(status);
    }
  },
  async PATCH(route, params) {
    try {
      const API = await axiosApi();
      const { data } = await API.patch(route, params);
      return data;
    } catch (e) {
      const {
        response: { status },
      } = e;
      this.checkStatus401(status);
      this.checkStatus403(status);
      // this.checkStatus500(status);
      // return status;
      throw e;
    }
  },
  async PUT(route, params) {
    try {
      const API = await axiosApi();
      const { data } = await API.put(route, params);
      return data;
    } catch (e) {
      const {
        response: { status },
      } = e;
      this.checkStatus401(status);
      this.checkStatus403(status);
      // this.checkStatus500(status);
      // return status;
      throw e;
    }
  },
};
