import 'antd/dist/antd.css';
import { notification } from 'antd';
import _get from 'lodash/get';

const openNotificationWithIcon = (
  type = 'info',
  message = '',
  description = '',
  ...props
) => {
  if (typeof message === 'string') {
    notification[type]({
      message: message,
      description: description,
      ...props,
    });
  }

  if (message && message.length) {
    const property = _get(message, '[0].property', '');
    if (property === 'email') {
      return notification[type]({
        message: 'Неверный email',
        description: description,
        ...props,
      });
    }
  }
};

export default openNotificationWithIcon;
