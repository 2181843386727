import moment from 'moment';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import { defaultDateFormat } from 'utils/constants';

const matchField = (data, dateFields, format) => {
  const newDate = {};
  Object.keys(data).forEach(filter => {
    dateFields.forEach(dField => {
      if (data[filter] && filter === dField) {
        newDate[filter] = moment(data[filter]).format(format);
      }
    });
  });
  return newDate;
};

// форматирует даты. Список имен полей дат dateFields
export const dateChangeFormat = (
  data = [],
  dateFields = [],
  format = defaultDateFormat,
) => {
  if (Array.isArray(data)) {
    const newData = data.map(elem => {
      const preparedFormatDate = matchField(elem, dateFields, format);
      return { ...elem, ...preparedFormatDate };
    });
    return newData;
  }
  return { ...data, ...matchField(data, dateFields, format) };
};

// Убирает из объекта ненужные поля
export const cutFields = (fields = [], data = {}) => {
  const newData = {};
  Object.keys(data).forEach(dataFieldName => {
    const match = fields.find(fieldName => fieldName === dataFieldName);
    if (!match) newData[dataFieldName] = data[dataFieldName];
  });
  return newData;
};

// слияние 2х массивов объектов с id и сохранение только уникальных элементов
export const mergeList = (oldData, newData) => {
  const newArray = [...oldData];
  newData.forEach(newEl => {
    if (!newArray.find(oldEl => oldEl.id === newEl.id)) newArray.push(newEl);
  });
  return newArray;
};
