import { APIService } from 'connectors/api/rest';
import qs from 'qs';

export const Sessions = {
  async postSession(params) {
    try {
      return await APIService.POST('sessions', params);
    } catch (e) {
      throw e;
    }
  },
  async postSessionPassword(params) {
    try {
      const qsParams = qs.stringify(params);
      return await APIService.POST(`sessions/password?${qsParams}`);
    } catch (e) {
      throw e;
    }
  },
  async patchSessionPassword(params) {
    try {
      return await APIService.PATCH('sessions/password', params);
    } catch (e) {
      return e;
    }
  },
};
