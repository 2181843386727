import React from 'react';

import RequestsForm from 'components/forms/RequestsForm';

const RequestsPage = props => {
  const { cardParams } = props.location;
  return (
    <div style={{ background: '#fff', marginTop: 64 }}>
      <RequestsForm cardParams={cardParams} />
    </div>
  );
};

export default RequestsPage;
