import React, { useState, useEffect } from 'react';
import { Collapse, Checkbox, Col, Popover } from 'antd';
import { FaQuestion } from 'react-icons/fa';
import ReactResizeDetector from 'react-resize-detector';
import Input from 'components/controls/base/Input';

import { statesIcons, branchesIcons } from 'iconsConst';
import './styles.scss';

const icons = {
  stateIcon: statesIcons,
  branchIcon: branchesIcons,
};

const { Panel } = Collapse;

const AvailableColumns = (
  { edit = _ => _, columns = {}, filterStr, onChangeFilter },
  ref,
) => {
  const [colSpan, setColSpan] = useState(8);
  const [maxHeight, setMaxHeight] = useState(120);
  const [prepareColumns, setPrepareColumns] = useState([]);

  useEffect(() => {
    const newPrepareColumns = Object.keys(columns).map(c => ({
      ...columns[c],
      value: c,
    }));
    setPrepareColumns(newPrepareColumns);
  }, [colSpan]); // eslint-disable-line

  const defaultValue = Object.keys(columns).filter(c => columns[c].isActive);

  const onChange = checkedValues => {
    const updateColumns = { ...columns };
    Object.keys(updateColumns).forEach(c => {
      updateColumns[c].isActive = checkedValues.includes(c);
    });
    edit(updateColumns);
  };

  const updateStyle = (cols, height) => {
    setColSpan(cols);
    setMaxHeight(height);
  };

  const onResize = width => {
    if (width >= 1700) updateStyle(3, 90);
    if (width >= 1300 && width < 1700) updateStyle(4, 120);
    if (width >= 1025 && width < 1300) updateStyle(5, 130);
    if (width >= 855 && width < 1025) updateStyle(6, 150);
    if (width >= 660 && width < 855) updateStyle(8, 190);
    if (width >= 430 && width < 660) updateStyle(12, 300);
  };

  const content = column => (
    <div className="icons-popover">
      {Object.entries(icons[column]).map(si => (
        <div key={si[0]} className="icons-popover-row">
          <div className="icons-popover-row-icon">{si[1]}</div>
          <div>{si[0]}</div>
        </div>
      ))}
    </div>
  );

  const customCheckBoxLabel = column => {
    if (column.value.includes('Icon')) {
      return (
        <span>
          {column.title}{' '}
          <Popover content={content(column.value)} trigger="hover">
            <FaQuestion />
          </Popover>
        </span>
      );
    }
    return column.title;
  };

  return (
    <Collapse style={{ position: 'relative' }}>
      <Input
        ref={ref}
        value={filterStr}
        onChange={e => onChangeFilter(e.target.value)}
        placeholder="Фильтр по тексту"
        className="filter_search"
      />
      <Panel header="Поля">
        <ReactResizeDetector handleWidth onResize={onResize} />
        <Checkbox.Group
          style={{ width: '100%' }}
          value={defaultValue}
          onChange={onChange}
        >
          <div className="columnContainer" style={{ maxHeight }}>
            {prepareColumns.map(c => (
              <Col span={colSpan} key={c.value}>
                <Checkbox value={c.value}>{customCheckBoxLabel(c)}</Checkbox>
              </Col>
            ))}
          </div>
        </Checkbox.Group>
      </Panel>
    </Collapse>
  );
};

export default React.forwardRef(AvailableColumns);
