export const cardData = {
  onlyCritical: {
    name: 'Критических заявок',
    params: { type: 'onlyCritical', value: true },
    type: 'critical',
  },
  onlyOutdated: {
    name: 'Просроченных заявок',
    params: { type: 'onlyOutdated', value: true },
  },
  onlyForWeek: {
    name: 'Запланировано на неделю',
    params: { type: 'onlyForWeek', value: true },
  },
  onlyForToday: {
    name: 'Запланировано на сегодня',
    params: { type: 'onlyForToday', value: true },
  },
  onlyOpened: {
    name: 'Открытых заявок',
    params: { type: 'onlyOpened', value: true },
  },
  all: { name: 'Всего', params: { type: 'all', value: true } },
};
