import React from 'react';
import CabinetForm from 'components/forms/CabinetForm';

const CabinetPage = () => (
  <div style={{ height: '100%' }}>
    <CabinetForm />
  </div>
);

export default CabinetPage;
