import React from 'react';

import _isEmpty from 'lodash/isEmpty';
import { Upload, Icon } from 'antd';

import '../../style.scss';

const { Dragger } = Upload;

const ServiceCardDragger = ({ serviceFiles, setAllAddedFiles }) => {
  return (
    <Dragger
      showUploadList={false}
      fileList={[]}
      multiple
      onChange={({ fileList }) => {
        const newFilelist = fileList.map(fileObj => ({
          ...fileObj,
          mimetype: fileObj.type,
        }));
        setAllAddedFiles(newFilelist);
        return false;
      }}
      beforeUpload={() => false}
      className={`${
        !_isEmpty(serviceFiles) && 'service-card--short'
      } service-card`}
    >
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      {_isEmpty(serviceFiles) && (
        <p className="ant-upload-text service-card-title">
          Нажмите или перенесите
          <span> сервисную карту </span>в эту область
        </p>
      )}
    </Dragger>
  );
};

export default ServiceCardDragger;
