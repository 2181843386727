import React, { forwardRef } from 'react';
import { Menu, Icon } from 'antd';

import {
  BranchesSelect,
  PrioritiesSelect,
  RequestStatesSelect,
} from 'components/controls/selects';
import Checkbox from 'components/controls/base/Checkbox';
import 'antd/dist/antd.css';

const SubmenuOptTwo = forwardRef(
  ({ getFieldDecorator, SubMenu, checkedValue = false, ...props }, ref) => {
    return (
      <SubMenu
        title={
          <span>
            <Icon type="select" />
            <span>Статус, Приоритет, Отрасль</span>
          </span>
        }
        ref={ref}
        {...props}
      >
        <Menu.Item className="custom_menuItem" key="6">
          {getFieldDecorator('withArchived', {
            initialValue: props.withArchived,
          })(
            <Checkbox
              title="Показать архивные заявки"
              style={{ color: '#fff' }}
            />,
          )}
        </Menu.Item>
        <Menu.Item className="custom_menuItem" key="7">
          {getFieldDecorator('states', { initialValue: props.states })(
            <RequestStatesSelect
              style={{ maxWidth: '100%', width: '100%' }}
              mode="multiple"
              label="Статус"
              allowClear
              placeholder="Выберите статус"
            />,
          )}
        </Menu.Item>
        <Menu.Item className="custom_menuItem" key="8">
          {getFieldDecorator('priorities', {
            initialValue: props.priorities,
          })(
            <PrioritiesSelect
              style={{ maxWidth: '100%', width: '100%' }}
              mode="multiple"
              label="Приоритет"
              allowClear
              placeholder="Выберите приоритет"
            />,
          )}
        </Menu.Item>
        <Menu.Item className="custom_menuItem" key="9">
          {getFieldDecorator('branches', { initialValue: props.branches })(
            <BranchesSelect
              style={{ maxWidth: '100%', width: '100%' }}
              mode="multiple"
              label="Отрасль"
              allowClear
              placeholder="Выберите отрасль"
            />,
          )}
        </Menu.Item>
      </SubMenu>
    );
  },
);

export default SubmenuOptTwo;
