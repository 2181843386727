import React from 'react';
import localforage from 'localforage';
import { Dropdown, Menu, Icon } from 'antd';

import { branchesIcons, statesIcons } from 'iconsConst';
const icons = {
  stateIcon: statesIcons,
  branchIcon: branchesIcons,
};

export default function genConstructors({ columnsData, setColumnsData }) {
  const headerWrapper = (value, render) => {
    const hideColumn = () => {
      const newColumns = { ...columnsData };
      newColumns[value].isActive = false;
      setColumnsData(newColumns);
    };
    const title =
      (columnsData && columnsData[value] && columnsData[value].title) || '';

    return (
      <Dropdown
        overlay={
          <Menu onClick={hideColumn}>
            <Menu.Item>Скрыть</Menu.Item>
          </Menu>
        }
        trigger={['contextMenu']}
      >
        <span style={{ userSelect: 'none' }} title={title}>
          {render ? render() : title}
        </span>
      </Dropdown>
    );
  };

  function NewColumn(type, width = null, render = '') {
    // TODO: костыль на экстренную очистку localForage от старых данных, если они вызвали ошибку
    try {
      // console.log(
      //   'ERROR ROW WITH OLD LOCALFORAGE DATA',
      //   columnsData[type].isActive,
      // );
    } catch (e) {
      localforage.clear();
      console.log('HAS ERROR', e);
    }

    this.title = headerWrapper(type, render);
    this.dataIndex = type;
    this.className = `${columnsData[type].isActive ? '' : 'hideColumn'}`;
    this.key = type;
    this.width = width && `${width}px`;
    this.render = value => <span>{value}</span>;

    if (type.includes('Icon'))
      this.render = value => (
        <span style={{ fontSize: 16 }} title={value}>
          {icons[type][value]}
        </span>
      );
  }

  function NewDateColumn(type, width = null) {
    this.title = headerWrapper(type);
    this.dataIndex = type;
    this.className = `${columnsData[type].isActive ? '' : 'hideColumn'}`;
    this.width = width || '80px';
    this.render = value => <span>{value || ''}</span>;
  }

  function IconColumn({ icon, dataIndex, value, width, fixed }) {
    this.title = () => <Icon type={icon} />;
    this.className = `${columnsData[dataIndex].isActive ? '' : 'hideColumn'}`;
    this.dataIndex = dataIndex;
    this.key = dataIndex;
    this.width = `${width}px`;
    this.render = value => value;
  }

  return {
    IconColumn,
    NewColumn,
    NewDateColumn,
  };
}
