import React, { forwardRef } from 'react';
import _isEmpty from 'lodash/isEmpty';

import Select from 'components/controls/base/Select';

export const PriceSelect = forwardRef(({ units = [], ...props }, ref) => {
  const preparedPrice = units.map(s => ({
    value: s.id,
    name: s.name,
  }));
  return (
    <div ref={ref}>
      <Select {...props} options={preparedPrice} />
    </div>
  );
});
