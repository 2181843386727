import { createSlice } from 'redux-starter-kit';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

import { mergeList } from 'utils/helpers';
import { Stores } from 'connectors/query/Stores';
import { Buildings } from 'connectors/query/Buildings';
import { Cities } from 'connectors/query/Cities';
import { Brands } from 'connectors/query/Brands';
import { Companies } from 'connectors/query/Companies';

// TODO: при обновлении списка - смотреть на тот список, что есть и фильтровать лишние ids из запроса
// при записи не затирать то, что есть, а мержить списки
// перейти на коллекции
const SelectsFilters = createSlice({
  name: 'selectsFilters',

  initialState: {
    companiesLoading: false,
    companiesOptions: [],

    citiesLoading: false,
    citiesOptions: [],
    citiesUsedOptions: [],

    buildingsLoading: false,
    buildingsOptions: [],

    brandsLoading: false,
    brandsOptions: [],

    storesLoading: false,
    storesOptions: [],
    buildingsByStore: [],
  },

  reducers: {
    setCompaniesOptions: (state, { payload }) => {
      state.companiesOptions = mergeList(state.companiesOptions, payload) || [];
    },
    setCompaniesLoading: (state, { payload }) => {
      state.companiesLoading = payload;
    },

    setCitiesOptions: (state, { payload }) => {
      state.citiesOptions = mergeList(state.citiesOptions, payload) || [];
    },
    setCitiesLoading: (state, { payload }) => {
      state.citiesLoading = payload;
    },

    setBuildingsOptions: (state, { payload }) => {
      state.buildingsOptions = mergeList(state.buildingsOptions, payload) || [];
    },
    setBuildingsLoading: (state, { payload }) => {
      state.buildingsLoading = payload;
    },

    setBrandsOptions: (state, { payload }) => {
      state.brandsOptions = mergeList(state.brandsOptions, payload) || [];
    },
    setBrandsLoading: (state, { payload }) => {
      state.brandsLoading = payload;
    },

    setStoresOptions: (state, { payload }) => {
      state.storesOptions = mergeList(state.storesOptions, payload) || [];
    },
    setBuildingByStore: (state, { payload }) => {
      state.buildingsByStore = mergeList(state.buildingsByStore, payload) || [];
    },
    setStoresLoading: (state, { payload }) => {
      state.storesLoading = payload;
    },
  },
});

export default SelectsFilters.reducer;

export const {
  setCompaniesOptions,
  setCompaniesLoading,

  setCitiesOptions,
  setCitiesLoading,

  setBuildingsOptions,
  setBuildingsLoading,

  setBrandsOptions,
  setBrandsLoading,

  setStoresOptions,
  setBuildingByStore,
  setStoresLoading,
} = SelectsFilters.actions;

export const getCompaniesOptions = (params, isUsed) => async dispatch => {
  dispatch(setCompaniesLoading(true));

  const companiesResponse = await Companies.getList(params);
  const options = companiesResponse.map(c => ({
    ...c,
    value: c.id,
    name: c.name,
  }));

  await dispatch(setCompaniesOptions(options));
  dispatch(setCompaniesLoading(false));
};

export const getCitiesOptions = () => async (dispatch, getState) => {
  dispatch(setCitiesLoading(true));

  const storesResponse = await Stores.getList({ used: true });
  const buildingsResponse = await Buildings.getList({
    ids: _uniq(storesResponse.map(b => b.buildingId)),
  });
  const citiesResponse = await Cities.getList({
    ids: _uniq(buildingsResponse.map(b => b.cityId)),
  });
  const options = citiesResponse.map(c => ({
    ...c,
    value: c.id,
    name: c.name,
  }));

  await dispatch(setCitiesOptions(options));
  dispatch(setCitiesLoading(false));
};

export const getBuildingsOptions = ({withCity}) => async (dispatch) => {
  dispatch(setBuildingsLoading(true));

  const storesResponse = await Stores.getList({ used: true });
  const buildingsResponse = await Buildings.getList({
    ids: _uniq(storesResponse.map(b => b.buildingId)),
  });
  const citiesResponse = await Cities.getList({
    ids: _uniq(buildingsResponse.map(b => b.cityId)),
  });
  const options = buildingsResponse.map(b => {
    const cityName = citiesResponse.find(c => c.id === b.cityId);
    return {
      ...b,
      name: withCity && cityName && cityName.abbreviation ? `${b.name} - ${cityName.abbreviation}` : b.name,
      value: b.id,
    };
  });

  await dispatch(setBuildingsOptions(options));
  dispatch(setBuildingsLoading(false));
};

export const getBrandsOptions = params => async (dispatch, getState) => {
  // const loadedBrands = getState().selectsFilters.brandsOptions.map(co => co.id);
  // const idsToLoad = _get(params, 'ids', []);
  // const notLoaded = idsToLoad.filter(id => !loadedBrands.includes(id));
  // if (idsToLoad.length && !notLoaded.length) return null;

  dispatch(setBrandsLoading(true));

  const storesResponse = await Stores.getList({ used: true });
  const brandsResponse = await Brands.getList({
    ids: _uniq(storesResponse.map(b => b.brandId)),
  });
  const options = brandsResponse.map(b => ({
    ...b,
    value: b.id,
    name: b.name,
  }));

  await dispatch(setBrandsOptions(options));
  dispatch(setBrandsLoading(false));
};

export const getStoresOptions = params => async (dispatch, getState) => {
  const loadedStores = getState().selectsFilters.storesOptions.map(co => co.id);
  const idsToLoad = _get(params, 'ids', []);
  const notLoaded = idsToLoad.filter(id => !loadedStores.includes(id));
  if (idsToLoad.length && !notLoaded.length) return null;

  dispatch(setStoresLoading(true));

  const storesResponse = await Stores.getList(params);
  const buildingsResponse = await Buildings.getList({
    ids: _uniq(storesResponse.map(b => b.buildingId)),
  });
  const brandsResponse = await Brands.getList({
    ids: _uniq(storesResponse.map(b => b.brandId)),
  });
  const citiesResponse = await Cities.getList({
    ids: _uniq(buildingsResponse.map(b => b.cityId)),
  });

  let options = storesResponse.map(s => {
    const building = buildingsResponse.find(b => b.id === s.buildingId) || {};
    const brand = brandsResponse.find(b => b.id === s.brandId) || {};
    const city =
      (building.cityId && citiesResponse.find(c => c.id === building.cityId)) ||
      {};

    const buildingName = _get(building, 'name', '');
    const cityName = _get(city, 'name', '');
    const brandName = _get(brand, 'name', '');

    return {
      ...s,
      value: s.id,
      name: `${cityName} - ТЦ ${buildingName} ${brandName} ${s.code}`,
    };
  });

  await dispatch(setStoresOptions(options));
  await dispatch(setBuildingByStore(buildingsResponse));

  dispatch(setStoresLoading(false));
};
