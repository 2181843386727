import React, { useEffect } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import localforage from 'localforage';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu, Dropdown, Icon } from 'antd';
import { stopSession } from 'slices/Sessions';
import { getPolicies } from 'slices/Policies';

import HeaderItem from './HeaderItem';

const { Header: AntHeader } = Layout;

function Header() {
  const user = useSelector(state => state.sessions.user);
  const policies = useSelector(state => state.policies);
  const { role } = useSelector(state => state.sessions.user);
  const dispatch = useDispatch();
  const location = useLocation();

  const logout = () => {
    dispatch(stopSession());
  };

  useEffect(() => {
    _isEmpty(policies) && dispatch(getPolicies());
  }, [policies]); // eslint-disable-line

  const showAddBtn = !!_get(policies, `[${role}].draft`);

  return (
    <AntHeader style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
      <div className="logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[location.pathname]}
        style={{ lineHeight: '64px' }}
      >
        {showAddBtn && (
          <HeaderItem
            key="/add"
            url="/add"
            icon="file-add"
            label="Создать заявку"
          />
        )}
        <HeaderItem
          key="/requests"
          url="/requests"
          icon="unordered-list"
          label="Список заявок"
        />
        <HeaderItem key="/" url="/" icon="info-circle" label="Информация" />
        <Menu.Item key="/cabinet">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to={'/cabinet'}>
                    <Icon type="user" />
                    &nbsp;Личный кабинет
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={'/notifications'}>
                    <Icon type="exclamation-circle" />
                    &nbsp;Уведомления
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <div
                    to={'/login'}
                    onClick={async () => {
                      await localforage.removeItem('user');
                      await localforage.removeItem('token');
                      logout();
                    }}
                  >
                    <Icon type="logout" />
                    &nbsp;Выход
                  </div>
                </Menu.Item>
              </Menu>
            }
          >
            <Link to={'/cabinet'}>
              <Icon type="user" />
              {user && user.name} {user && user.surname}
            </Link>
          </Dropdown>
        </Menu.Item>
      </Menu>
    </AntHeader>
  );
}

export default Header;
