import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFilters } from 'slices/Filters';

import Select from 'components/controls/base/Select';

export const MyFilters = forwardRef(({ params = {}, ...props }, ref) => {
  const [gettedData, setGettedData] = useState(false);
  const dispatch = useDispatch();

  const { list: allMyFilters, loading } = useSelector(state => state.filters);
  const preparedFilters =
    allMyFilters &&
    allMyFilters.map(c => ({
      ...c,
      value: c.id,
      name: c.name,
    }));

  useEffect(() => {
    dispatch(getFilters());
    setGettedData(true);
  }, [JSON.stringify(props.value)]);

  return (
    <div ref={ref}>
      <div style={{ color: '#fff' }}>Мои фильтры</div>
      <Select
        {...props}
        options={preparedFilters}
        onDropdownVisibleChange={() => {
          if (!gettedData) {
            dispatch(getFilters());
            setGettedData(true);
          }
        }}
        loading={loading}
      />
    </div>
  );
});
