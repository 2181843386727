import React, { forwardRef } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

const Checkbox = forwardRef(
  ({ title, value, onChange = _ => _, ...props }, ref) => {
    return (
      <AntCheckbox onChange={onChange} ref={ref} checked={value} {...props}>
        {title}
      </AntCheckbox>
    );
  },
);

export default Checkbox;
