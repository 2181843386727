import React, { forwardRef } from 'react';
import { Menu, Icon } from 'antd';
import moment from 'moment';

import DatePicker from 'components/controls/base/DatePicker';
import 'antd/dist/antd.css';
import './style.scss';

const SubmenuOptFour = forwardRef(
  (
    {
      onFilterChange,
      getFieldDecorator,
      onChangeField,
      getFieldValue,
      disabledDateField,
      SubMenu,
      ...props
    },
    ref,
  ) => {
    return (
      <SubMenu
        title={
          <span>
            <Icon type="select" />
            <span>Даты</span>
          </span>
        }
        ref={ref}
        {...props}
      >
        <Menu.Item className="custom_menuItem" key="11">
          <p>Создан</p>
          <div className="dateContainer">
            {getFieldDecorator('createdAtFrom', {
              initialValue: props.createdAtFrom
                ? moment(props.createdAtFrom)
                : undefined,
            })(
              <DatePicker
                placeholder="Начало"
                style={{ width: 110 }}
                disabledDate={disabledDateField('createdAtFrom')}
              />,
            )}
            <span> - </span>
            {getFieldDecorator('createdAtTo', {
              initialValue: props.createdAtTo
                ? moment(props.createdAtTo)
                : undefined,
            })(
              <DatePicker
                disabledDate={disabledDateField('createdAtTo')}
                style={{ width: 110 }}
                placeholder="Конец"
              />,
            )}
          </div>
        </Menu.Item>
        <Menu.Item className="custom_menuItem" key="12">
          <p>Срок</p>
          <div className="dateContainer">
            {getFieldDecorator('completeAtFrom', {
              initialValue: props.completeAtFrom
                ? moment(props.completeAtFrom)
                : undefined,
            })(
              <DatePicker
                placeholder="Начало"
                style={{ width: 110 }}
                disabledDate={disabledDateField('completeAtFrom')}
              />,
            )}
            <span> - </span>
            {getFieldDecorator('completeAtTo', {
              initialValue: props.completeAtTo
                ? moment(props.completeAtTo)
                : undefined,
            })(
              <DatePicker
                placeholder="Конец"
                style={{ width: 110 }}
                disabledDate={disabledDateField('completeAtTo')}
              />,
            )}
          </div>
        </Menu.Item>
      </SubMenu>
    );
  },
);

export default SubmenuOptFour;
