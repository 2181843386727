import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getStoresOptions } from 'slices/SelectsFilters';
import Select from 'components/controls/base/Select';

import './styles.scss';

export const StoresSelect = forwardRef(
  (
    {
      label = '',
      buildingChoosen = [],
      citiesChoosen = [],
      withInactive = false,
      showAddress = false,
      showPhone = false,
      showEmail = false,
      compact = false,
      used = false,
      ...props
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [gettedData, setGettedData] = useState(false);
    const { storesLoading, storesOptions, buildingsByStore } = useSelector(
      state => state.selectsFilters,
    );

    const params = { withInactive, used };
    const hasAddInfo = showAddress || showPhone || showEmail;
    useEffect(() => {
      if (hasAddInfo || compact) {
        setGettedData(true);
        dispatch(getStoresOptions(params));
      }
    }, []);
    useEffect(() => {
      if (props.value && props.value.length)
        dispatch(getStoresOptions({ ids: props.value, ...params }));
    }, [JSON.stringify(props.value)]);

    let preparedStores = storesOptions;

    // Фильтрация
    if (citiesChoosen && citiesChoosen.length > 0) {
      preparedStores = preparedStores.filter(el =>
        citiesChoosen.includes(el.cityId),
      );
    }
    if (buildingChoosen && buildingChoosen.length > 0) {
      preparedStores = preparedStores.filter(el =>
        buildingChoosen.includes(el.buildingId),
      );
    }

    const selectedStore = storesOptions.find(s => s.id === props.value);
    const selectedPreparedStore = preparedStores.find(
      s => s.value === props.value,
    );
    const storeBuilding = selectedStore
      ? buildingsByStore.find(b => b.id === selectedStore.buildingId)
      : null;

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        {compact ? (
          selectedPreparedStore && (
            <div className="highlighter">{selectedPreparedStore.name}</div>
          )
        ) : (
          <Select
            className={`store-select ${hasAddInfo ? 'has-add-info' : ''}`}
            {...props}
            options={preparedStores}
            onDropdownVisibleChange={() => {
              if (!gettedData) {
                setGettedData(true);
                dispatch(getStoresOptions(params));
              }
            }}
            loading={storesLoading}
          />
        )}
        {hasAddInfo && (
          <div
            className={`select-add-info ${
              compact ? 'compact highlighter' : ''
            }`}
          >
            {showAddress && storeBuilding && (
              <div className="select-add-info_wrap custom_wrap ">
                <div className="select-add-info_header">Адрес:</div>
                <div>{storeBuilding.address}</div>
              </div>
            )}

            {showPhone && selectedStore && (
              <div className="select-add-info_wrap custom_wrap">
                <div className="select-add-info_header">Телефон:</div>
                <div>{selectedStore.phone}</div>
              </div>
            )}

            {showEmail && selectedStore && (
              <div className="select-add-info_wrap custom_wrap">
                <div className="select-add-info_header">E-mail:</div>
                <div>{selectedStore.email}</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
