import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { Form, Typography, Icon, Row, Col } from 'antd';
import { outputDateFormat } from 'utils/constants';
import { BranchesSelect, StoresSelect } from 'components/controls/selects';

import openNotificationWithIcon from 'components/controls/base/Notification';
import TextArea from 'components/controls/base/TextArea';
import Input from 'components/controls/base/Input';
import './style.scss';

const { Title, Text, Paragraph } = Typography;

const RequestInfoPart = ({
  form,
  code,
  createdAt,
  externalCode,
  details,
  storeId,
  branch,
  availableFields,
  requestInfoSmallSize,
  contractorCompanyId,
}) => {
  const { getFieldDecorator } = form;
  const [smallSize, setSmallSize] = useState(requestInfoSmallSize);
  const resize = () => {
    setSmallSize(!smallSize);
  };
  const { storesOptions: stores } = useSelector(state => state.selectsFilters);

  const clipboard = (value, descr) => {
    navigator.clipboard
      .writeText(value)
      .then(_ => openNotificationWithIcon('success', descr))
      .catch(_ => openNotificationWithIcon('erro', 'Ошибка при копировании'));
  };

  useEffect(() => {
    setSmallSize(requestInfoSmallSize);
  }, [requestInfoSmallSize]);

  return (
    <Row className={`info-part light-blue ${smallSize ? 'compact' : ''}`}>
      <Row type="flex" justify="space-between">
        <Title level={4}>Информация о заявке</Title>
        <Icon
          onClick={resize}
          type={`${smallSize ? 'arrows-alt' : 'shrink'}`}
        />
      </Row>
      <div className="request_container">
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item
              className="text-left form-item-flex reqInfo"
              wrapperCol={{ span: 20, sm: 19, xs: 19 }}
              labelCol={{ span: 20, sm: 5, xs: 5 }}
              label={
                <span
                  title="скопировать код заявки"
                  onClick={() => clipboard(code, 'Код заявки скопирован')}
                >
                  <Icon type="copy" className="copyIcon" />
                  <Text>Заявка</Text>
                </span>
              }
            >
              <span>
                № <b className={`${smallSize ? 'highlighter' : ''}`}>{code}</b>{' '}
                от{' '}
                <span className={`${smallSize ? 'highlighter' : ''}`}>
                  {moment(createdAt).format(outputDateFormat)}
                </span>
              </span>
            </Form.Item>
          </Col>
          <Col>
            {smallSize && !branch ? null : (
              <Form.Item
                className="text-left form-item-flex reqInfo"
                wrapperCol={{ span: 20, sm: 18, xs: 19 }}
                labelCol={{ sm: 6, xs: 5 }}
                label={<Text>Отрасль</Text>}
              >
                {getFieldDecorator('branch', {
                  initialValue: branch,
                })(
                  <BranchesSelect
                    compact={smallSize}
                    disabled={!availableFields.includes('branch')}
                  />,
                )}
              </Form.Item>
            )}
          </Col>
        </Row>

        {smallSize && !externalCode ? null : (
          <Form.Item
            labelCol={{ span: 6 }}
            className={`text-left ${smallSize ? 'compact-block' : ''}`}
            label={
              <>
                <span
                  title="Скопировать внешний код заявки"
                  onClick={() =>
                    clipboard(externalCode, 'Внешний код заявки скопирован')
                  }
                >
                  <Icon type="copy" className="copyIcon" />
                </span>
                <Text>Внешний номер заявки</Text>
              </>
            }
          >
            {getFieldDecorator('externalCode', { initialValue: externalCode })(
              <Input
                compact={smallSize}
                disabled={!availableFields.includes('externalCode')}
                maxLength={20}
              />,
            )}
          </Form.Item>
        )}

        {smallSize && !storeId ? null : (
          <Form.Item
            className={`text-left ${smallSize ? 'compact-block' : ''}`}
            wrapperCol={{ span: 20, sm: 17, xs: 24 }}
            label={
              <>
                <span
                  title="скопировать код объекта"
                  onClick={() => {
                    clipboard(
                      stores.find(s => s.id === storeId).code,
                      'Код объекта скопирован',
                    );
                  }}
                >
                  <Icon type="copy" className="copyIcon" />
                </span>
                <Text>Объект</Text>
              </>
            }
          >
            {getFieldDecorator('storeId', {
              initialValue: storeId,
            })(
              // если stores - disabled, то скачиваем список withInactive
              // если активен - used
              <StoresSelect
                style={{ width: '100%' }}
                disabled={!availableFields.includes('storeId')}
                compact={smallSize}
                rows={8}
                withInactive={!availableFields.includes('storeId')}
                used={availableFields.includes('storeId')}
                showAddress
                showPhone
                showEmail
              />,
            )}
          </Form.Item>
        )}

        {smallSize && !details ? null : (
          <>
            {!smallSize && (
              <Paragraph style={{ textAlign: 'center' }} level={4}>
                Содержание заявки/объем дополнительных работ к осмотру или
                заказу
              </Paragraph>
            )}
            <Form.Item
              className={`text-left ${smallSize ? 'compact-block' : ''}`}
              wrapperCol={{ sm: smallSize ? 20 : 24 }}
              label={smallSize && <Text>Содержание заявки</Text>}
            >
              {getFieldDecorator('details', {
                initialValue: details,
              })(
                <TextArea
                  rows={7}
                  compact={smallSize}
                  disabled={!availableFields.includes('details')}
                />,
              )}
            </Form.Item>
          </>
        )}

        {smallSize && !contractorCompanyId ? null : (
          <Form.Item
            className={`text-left ${smallSize ? 'compact-block' : ''}`}
            wrapperCol={{ sm: smallSize ? 18 : 18 }}
            labelCol={{ sm: 4 }}
            label={<span>Исполнитель</span>}
          >
            <div>
              <div className={`highlighter ${!smallSize ? 'contractorPadding' : ''}`}>ООО «Сити-Галс Самара»</div>
              <div
                className={`select-add-info blue ${
                  smallSize && 'compact highlighter'
                }`}
              >
                <div className="select-add-info_wrap">
                  <div className="select-add-info_header">Телефон:</div>
                  <div>+7-927-000-6090</div>
                </div>
                <div className="select-add-info_wrap">
                  <div className="select-add-info_header">E-mail:</div>
                  <div>requests@city-gals.com</div>
                </div>
              </div>
            </div>
            {/* {getFieldDecorator('contractorCompanyId', {
              initialValue: contractorCompanyId,
              rules: [
                {
                  required: true,
                  message: 'Укажите исполнителя!',
                },
              ],
            })(
              <CompaniesSelect
                params={{ onlyContractors: true }}
                compact={smallSize}
                showPhone
                showEmail
                disabled
                rows={8}
                // TODO: запрещаем изменение поля ИСПОЛНИТЕЛЬ для всех в 19й версии
                // disabled={!availableFields.includes('contractorCompanyId')}
              />,
            )} */}
          </Form.Item>
        )}
      </div>
    </Row>
  );
};

export default RequestInfoPart;
