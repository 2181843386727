import { APIService } from 'connectors/api/rest';

export const Requests = {
  async getCounters(params) {
    try {
      return await APIService.GET('requests/counters', params);
    } catch (e) {
      throw e;
    }
  },

  async getStateList(params) {
    try {
      return await APIService.GET('states', params);
    } catch (e) {
      throw e;
    }
  },

  async postRequest(params) {
    try {
      return await APIService.POST('requests', params);
    } catch (e) {
      throw e;
    }
  },

  async putRequest({ contractorCompanyId, ...params }, id) {
    try {
      const test = await APIService.PUT(`requests/${id}`, params);
      return test;
    } catch (e) {
      throw e;
    }
  },

  async getList(params) {
    try {
      return await APIService.GET('requests', params, false, true);
    } catch (e) {
      throw e;
    }
  },

  async getXLSX(params) {
    try {
      return await APIService.GET('requests/excel?', params, true);
    } catch (e) {
      throw e;
    }
  },

  async getCurrentRequest(id) {
    try {
      return await APIService.GET(`requests/${id}`);
    } catch (e) {
      throw e;
    }
  },

  async getCommentsList(id) {
    try {
      return await APIService.GET(`/comments`, { requestId: id });
    } catch (e) {
      throw e;
    }
  },

  async postCommentsList(params) {
    try {
      return await APIService.post(`requests/comments`, params);
    } catch (e) {
      throw e;
    }
  },

  async getEventsList(id) {
    try {
      return await APIService.GET(`requests/${id}/events`);
    } catch (e) {
      throw e;
    }
  },
};
