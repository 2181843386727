import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Col, Row, Form, Icon, Upload, Divider, Typography } from 'antd';

import {
  PrioritiesSelect,
  RequestStatesSelect,
} from 'components/controls/selects';
import ChatBox from '../ChatBox';
import FileList from '../FileList';
import RequestInfoPart from '../RequestInfoPart';
import Button from 'components/controls/base/Button';
import ContractorInfoPart from '../ContractorInfoPart';
import ServiceCardPicture from '../ServiceCardPicture';
import DatePicker from 'components/controls/base/DatePicker';
import openNotificationWithIcon from 'components/controls/base/Notification';

import { editRequest } from 'slices/Requests';
import { getUsers } from 'slices/Users';
import {
  getFile,
  setFiles,
  getFiles,
  uploadFiles,
  updateBlobFiles,
} from 'slices/Files';
import { getEstimates, getUnitsEstimates } from 'slices/Estimates';
import { defaultDateFormat } from 'utils/constants';

// import { LocalizedModal } from 'components/controls/base/LocalizedModal';
import SmetaModal from './Modals/SmetaModals';
import PictureModal from './Modals/PictureModal';
import CloseReqModal from './Modals/CloseReqModal';

import { reason, formItemLayout, attantion } from './constants';
import {
  onSubmit,
  checkBlob,
  getFullName,
  prepareFiles,
  checkXlsFile,
  onChangeSlide,
  preparingRequestData,
} from './helpers';
import { contractorFieldOpenDefault } from './constants';

import './styles.scss';
import _debounce from 'lodash/debounce';

const checkXlsFileDebounced = _debounce(checkXlsFile, 500);

const { Dragger } = Upload;
const { Title, Text } = Typography;

const RequestForm = Form.create({
  name: 'request',
})(props => {
  const { form, policies, role, id, request } = props;
  const { getFieldDecorator, resetFields } = form;
  const {
    state,
    priority,
    completeAt,
    createdAt,
    rejectedBy,
    closedBy,
    rejectedAt,
    closedAt,
    closedDetails,
    rejectedDetails,
  } = request;
  const history = useHistory();
  const dispatch = useDispatch();
  const files = useSelector(state => state.files.list);
  const users = useSelector(state => state.users.list);
  const estimates = useSelector(state => state.estimates.list);

  const [error, setError] = useState('');
  const [filesToUpload, setFilesToUpload] = useState({
    restFiles: [],
    smeta: [],
    card: [],
    receipt: [],
  });
  const [url, setUrl] = useState('');
  const [count, setCount] = useState(0);
  const [fileName, setFileName] = useState('');
  const [modalData, setModalData] = useState({});
  const [indexSlide, setIndexSlide] = useState(0);
  const [imageFiles, setImageFiles] = useState(null);
  const [rejectedValue, setRejectedValue] = useState('');
  const [closedValue, setClosedValue] = useState(reason);
  const [visibleModal, setVisibleModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [requestInfoSmallSize, setRequestInfoSmallSize] = useState(true);
  const [visibleSmetaTableModal, setVisibleSmetaTableModal] = useState(false);
  const [contractorInfoSmallSize, setContractorInfoSmallSize] = useState(true);

  const carousel = useRef(null);
  const linkToDownload = useRef(null);
  const isSmetaBlock = role === 'contractor' || role === 'partner';
  const isSmetaBtn = role === 'contractor' || role === 'technician';

  const downloadFile = async ({
    id = 0,
    slider = false,
    otherFiles = true,
    name = 'name is undefined',
  }) => {
    const { blob, name: imgName } = await checkBlob({
      files,
      id: id,
      slider,
      getFile,
      dispatch,
      otherFiles,
      imageFiles,
      updateBlobFiles,
    });
    await setUrl(URL.createObjectURL(blob));
    await setFileName(imgName || name);
    linkToDownload.current.click();
    URL.revokeObjectURL(linkToDownload.current.href);
  };

  const showModal = async (file, index) => {
    setPreviewModal(true);
    setIndexSlide(index);
    await checkBlob({
      files,
      getFile,
      dispatch,
      imageFiles,
      id: file.id,
      slider: false,
      updateBlobFiles,
      otherFiles: false,
    });
  };

  // устанавливаем активный слайдер
  useEffect(() => {
    if (carousel && carousel.current) {
      carousel.current.goTo(indexSlide);
    }
  }, [carousel.current, indexSlide]); // eslint-disable-line

  // формируем массив изображений
  useEffect(() => {
    const onlyImg =
      files && files.filter(file => file.mimetype.includes('image'));
    setImageFiles(onlyImg);
  }, [files]);

  useEffect(() => {
    dispatch(getFiles(id));

    return () => dispatch(setFiles([]));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (state === 'rejected' && rejectedBy)
      dispatch(getUsers({ ids: [rejectedBy] }));
  }, [state]); // eslint-disable-line

  useEffect(() => {
    // установка свёрнутости contractorInfoShrink в соответствии с требованиями
    if (contractorFieldOpenDefault[role][request.state]) {
      setContractorInfoSmallSize(false);
    }

    // установка развёрнутой информации о заявке в зависимости от роли и статуса заявки
    const fullRequestInfoStates = ['new', 'draft'];
    if (role === 'partner' && fullRequestInfoStates.includes(request.state)) {
      setRequestInfoSmallSize(false);
    }

    if (role === 'agent' && request.state === 'draft') {
      setRequestInfoSmallSize(false);
    }
  }, [role, request]);

  useEffect(() => {
    if (visibleSmetaTableModal && _isEmpty(estimates)) {
      dispatch(getEstimates({ id, isContractor: role === 'contractor' }));
      dispatch(getUnitsEstimates());
    }
  }, [visibleSmetaTableModal]);

  const availableStates = _get(policies, `[${role}][${state}].states`, []);
  const availableFields = _get(policies, `[${role}][${state}].fields`, []);

  const editRequestData = data => {
    setError('');
    const preparedData = {
      ...data,
      ...(data.completeAt && {
        completeAt: moment(data.completeAt).format(defaultDateFormat),
      }),
    };

    dispatch(editRequest(preparedData, id))
      .then(() =>
        openNotificationWithIcon('success', 'Данные успешно сохранены'),
      )
      .catch(e => {
        setError(_get(e, 'response.data.message'));
        resetFields();
      });
  };

  // костыль для отправки файлов единожды. Переписать на debounce
  useEffect(() => {
    if (count !== 0) {
      prepareFiles({
        id,
        files,
        dispatch,
        uploadFiles,
        setFilesToUpload,
        addedFiles: filesToUpload,
        openNotificationWithIcon,
      });
      setCount(0);
    }
  }, [count]); // eslint-disable-line

  const rejectedByUser =
    rejectedBy && users && users.find(u => u.id === rejectedBy);

  const closedByUser = closedBy && users && users.find(u => u.id === closedBy);

  return (
    <>
      <Form
        {...formItemLayout}
        onSubmit={e =>
          onSubmit(e, {
            form,
            request,
            setModalData,
            editRequestData,
            setVisibleModal,
            preparingRequestData,
            openNotificationWithIcon,
          })
        }
        style={{ maxWidth: '800px', margin: 'auto' }}
      >
        <Button
          title="Назад"
          type="dashed "
          style={{ margin: 10 }}
          onClick={() => history.goBack()}
        />
        <div className="info-part yellow">
          <Title level={4}>Действия с заявкой</Title>
          <div className="action_request">
            <div className="action_request_wrap">
              <span className="action_request_title required">Статус</span>
              {getFieldDecorator('state', {
                initialValue: state,

                rules: [
                  {
                    required: true,
                    message: 'Укажите статус!',
                  },
                ],
              })(
                <RequestStatesSelect
                  activeField={state}
                  style={{ width: '130px' }}
                  availableStates={availableStates}
                  disabled={!availableFields.includes('state')}
                />,
              )}
            </div>

            <div className="action_request_wrap">
              <span className="action_request_title required">Приоритет</span>
              {getFieldDecorator('priority', {
                initialValue: priority,
                rules: [
                  {
                    required: true,
                    message: 'Укажите приоритет!',
                  },
                ],
              })(
                <PrioritiesSelect
                  disabled={!availableFields.includes('priority')}
                  style={{ width: '130px' }}
                />,
              )}
            </div>

            <div className="action_request_wrap">
              <span className="action_request_title ">
                {state === 'rejected' ? 'Дата отклонения' : 'Срок выполнения'}
              </span>
              {getFieldDecorator('completeAt', {
                initialValue: completeAt ? moment(completeAt) : null,
              })(
                <DatePicker
                  disabled={!availableFields.includes('completeAt')}
                  style={{ width: '130px' }}
                  disabledDate={current =>
                    current && current < moment(createdAt).startOf('day')
                  }
                />,
              )}
            </div>
          </div>
          <div className="action_buttons">
            <Button title="Сохранить" type="primary" htmlType="submit" />
            <Button
              title="Назад"
              type="dashed"
              onClick={history.goBack}
              style={{ marginLeft: 10 }}
            />
            {isSmetaBtn && (
              <Button
                title="Смета"
                type="dashed"
                style={{ marginLeft: 10 }}
                onClick={() => setVisibleSmetaTableModal(true)}
              />
            )}
          </div>
        </div>
        <RequestInfoPart
          {...props}
          {...request}
          availableFields={availableFields}
          requestInfoSmallSize={requestInfoSmallSize}
        />
        {state !== 'rejected' && (
          <ContractorInfoPart
            {...props}
            {...request}
            files={files}
            availableFields={availableFields}
            contractorInfoSmallSize={contractorInfoSmallSize}
          />
        )}
        {state === 'rejected' && (
          <div className="info-part red compact">
            <Title level={4}>Причина отклонения заявки</Title>
            <Text className="requestTitle">
              <b>
                {moment(rejectedAt).format('DD.MM.YYYY')}{' '}
                {getFullName(rejectedByUser, ['name', 'surname'])}:{' '}
              </b>
              {rejectedDetails}
            </Text>
          </div>
        )}
        {state === 'closed' && (
          <div className="info-part green compact">
            <Row>
              <Col span={20} sm={20} xs={18}>
                <Title level={4}>Заявка закрыта</Title>
                <Text className="requestTitle">
                  <b>
                    {moment(closedAt).format('DD.MM.YYYY')}{' '}
                    {getFullName(closedByUser, ['name', 'surname'])}:
                  </b>{' '}
                  {closedDetails}
                </Text>
              </Col>
              <Col span={4} xs={6} sm={4}>
                <ServiceCardPicture
                  id={id}
                  form={form}
                  files={files}
                  uploadFiles={uploadFiles}
                  prepareFiles={prepareFiles}
                />
              </Col>
            </Row>
          </div>
        )}

        <Divider />
        <Row>
          {/* all files */}
          <Col span={24}>
            <Col span={16} className="file_wrap">
              {files &&
                files.map((file, i) => {
                  const { card, smeta } = file;
                  if (!card && !smeta) {
                    return (
                      <FileList
                        key={file.id}
                        file={file}
                        downloadFile={downloadFile}
                        showModal={showModal}
                        index={i}
                      />
                    );
                  }
                })}
            </Col>
            <Col span={8} className="area_file">
              <Dragger
                fileList={filesToUpload.restFiles}
                name="file"
                multiple
                onChange={({ fileList }) => {
                  const withoutXls = fileList.filter(
                    file => !file.name.includes('.xls'),
                  );
                  checkXlsFileDebounced({ fileList, openNotificationWithIcon });

                  setCount(count + 1);
                  setFilesToUpload({
                    ...filesToUpload,
                    restFiles: withoutXls,
                  });
                  return false;
                }}
                beforeUpload={() => false}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Нажмите или перенесите{' '}
                  <span style={{ color: '#1890ff' }}> файл </span> в эту область
                </p>
              </Dragger>
            </Col>
          </Col>
          {/*  SMETA */}

          {isSmetaBlock && (
            <Col span={24}>
              <Col span={16} className="file_wrap yellow">
                {files &&
                  files.map((file, i) => {
                    const { smeta } = file;
                    if (smeta) {
                      return (
                        <FileList
                          key={file.id}
                          file={file}
                          downloadFile={downloadFile}
                          showModal={showModal}
                          index={i}
                        />
                      );
                    }
                  })}
              </Col>
              <Col span={8} className="area_file">
                <Dragger
                  fileList={filesToUpload.smeta}
                  name="file"
                  multiple
                  onChange={({ fileList }) => {
                    setCount(count + 1);
                    setFilesToUpload({
                      ...filesToUpload,
                      smeta: fileList,

                      typeFile: { type: 'smeta', value: true },
                    });
                    return false;
                  }}
                  beforeUpload={() => false}
                  style={{ background: '#ffe58f' }}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    Нажмите или перенесите
                    <span style={{ color: '#1890ff' }}> смету </span>в эту
                    область
                  </p>
                </Dragger>
              </Col>
            </Col>
          )}
          {/* TODO: оставить только в модалке */}
          {/*  CARD */}
          {/* <Col span={24}>
          <Dragger
            fileList={filesToUpload.card}
             name='file'
            multiple
            onChange={({ fileList }) => {
              setCount(count + 1);
              setFilesToUpload({
                ...filesToUpload,
                card: fileList,
                // TODO: Временное решение. поля type, value  Нужно понять как отправлять квери парамс вместе с formData у axous
                typeFile: { type: 'card', value: true },
              });
              return false;
            }}
            beforeUpload={() => false}
            style={{ background: '#b7eb8f' }}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Нажмите или перенесите
              <span style={{ color: '#1890ff' }}> сервисную карту </span>в эту
              область
            </p>
          </Dragger>
        </Col> */}

          {/* TODO: на случай если загрузку будет на кнопку */}
          {/* <Col span={24} style={{ textAlign: 'center' }}>
          <Button type="primary" size={'large'} onClick={prepareFiles}>
            Загрузить
          </Button>
        </Col> */}
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <ChatBox />
          </Col>
        </Row>
      </Form>

      <CloseReqModal
        form={form}
        count={count}
        setCount={setCount}
        attantion={attantion}
        modalData={modalData}
        visible={visibleModal}
        closedValue={closedValue}
        rejectedValue={rejectedValue}
        filesToUpload={filesToUpload}
        setClosedValue={setClosedValue}
        editRequestData={editRequestData}
        setVisibleModal={setVisibleModal}
        setRejectedValue={setRejectedValue}
        setFilesToUpload={setFilesToUpload}
        openNotificationWithIcon={openNotificationWithIcon}
      />

      {/* Модалка для превью фоток */}
      <PictureModal
        url={url}
        files={files}
        getFile={getFile}
        dispatch={dispatch}
        carousel={carousel}
        fileName={fileName}
        visible={previewModal}
        indexSlide={indexSlide}
        imageFiles={imageFiles}
        downloadFile={downloadFile}
        setIndexSlide={setIndexSlide}
        rejectedValue={rejectedValue}
        handleModal={setPreviewModal}
        onChangeSlide={onChangeSlide}
        linkToDownload={linkToDownload}
        updateBlobFiles={updateBlobFiles}
      />

      <SmetaModal
        role={role}
        requestId={id}
        setCount={setCount}
        showModal={showModal}
        estimates={estimates}
        downloadFile={downloadFile}
        filesToUpload={filesToUpload}
        visible={visibleSmetaTableModal}
        setFilesToUpload={setFilesToUpload}
        handleModal={setVisibleSmetaTableModal}
      />
    </>
  );
});

export default RequestForm;
