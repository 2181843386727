import { dateChangeFormat } from 'utils/helpers';
import { defaultDateFormat, dateFields } from 'utils/constants';
import openNotificationWithIcon from 'components/controls/base/Notification';

export const cutFields = values => {
  const newValues = {};
  let preparedData = dateChangeFormat(values, dateFields, defaultDateFormat);
  Object.keys(preparedData).forEach(el => {
    if (el !== 'comment' && el !== 'files') {
      newValues[el] = preparedData[el];
    }
  });
  return newValues;
};

export const handleAddFiles = ({ files, filesToUpload, setFilesToUpload }) => {
  let newFiles = [];

  files.forEach(curFile => {
    const matchFile = filesToUpload.find(
      addedFile => addedFile.name === curFile.name,
    );

    matchFile
      ? openNotificationWithIcon(
          'error',
          `Файл c именем ${matchFile.name} уже добавлен`,
        )
      : newFiles.push(curFile);
  });

  setFilesToUpload(state => [...state, ...newFiles]);
};
