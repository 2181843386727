import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { createSlice } from 'redux-starter-kit';
import { Brands } from 'connectors/query/Brands';

const BrandsSlice = createSlice({
  name: 'brands',

  initialState: {
    list: [],
  },

  reducers: {
    setBrands: (state, { payload }) => {
      state.list = _uniqWith([...state.list, ...payload], _isEqual) || [];
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export default BrandsSlice.reducer;

export const { setBrands, setLoading } = BrandsSlice.actions;

export const getBrands = params => async dispatch => {
  dispatch(setLoading(true));
  const response = await Brands.getList(params);
  await dispatch(setBrands(response));
  dispatch(setLoading(false));
};
