// import { message } from 'antd';
import { createSlice } from 'redux-starter-kit';
import { Companies } from 'connectors/query/Companies';

const CompaniesSlice = createSlice({
  name: 'companies',

  initialState: {
    list: [],
  },

  reducers: {
    setCompanies: (state, { payload }) => {
      state.list = payload || [];
    },
  },
});

export default CompaniesSlice.reducer;

export const { setCompanies } = CompaniesSlice.actions;

export const getCompanies = params => async dispatch => {
  const response = await Companies.getList(params);
  await dispatch(setCompanies(response));
};
