import React, { useRef, useState } from 'react';

import _isEmpty from 'lodash/isEmpty';
import { Carousel } from 'antd';

import ServiceCardDragger from '../ServiceCardDragger';
import { LocalizedModal } from 'components/controls/base/LocalizedModal';
import Button from 'components/controls/base/Button';
import text from '../../text.jpeg';

import { download, getPreview } from '../../helpers';
import '../../style.scss';

const ServiceCardModal = ({
  serviceFiles = [],
  setAllAddedFiles = () => {},
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [fileData, setFileData] = useState({});
  const [currentIndexSlide, setCurrentIndexSlide] = useState(0);
  const linkToDownload = useRef();

  return (
    <div>
      <div
        onClick={() => {
          setVisibleModal(true);
        }}
        className="service_card_preview"
        style={{
          backgroundImage: `url(${getPreview(serviceFiles)})`,
          backgroundPosition: '0 0',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <LocalizedModal
        title="Сервисные карты"
        visible={visibleModal}
        maskClosable={true}
        footer={[
          <ServiceCardDragger
            serviceFiles={serviceFiles}
            setAllAddedFiles={setAllAddedFiles}
          />,
          <Button
            type="dashed"
            onClick={() => setVisibleModal(false)}
            title="Ok"
            key="Ok"
          />,
          <Button
            type="dashed"
            onClick={() =>
              download({
                serviceFiles,
                currentIndexSlide,
                setFileData,
                linkToDownload,
              })
            }
            title="Скачать"
            key="Скачать"
          />,
        ]}
        //   footer={[
        //       <ServiceCardDragger

        // />,
        //     <Button
        //       type="dashed"
        //       onClick={() => setVisibleModal(false)}
        //       title="Ok"
        //       key="Ok"
        //     />,
        //     <Button
        //       type="dashed"
        //       onClick={() =>
        //         download({
        //           serviceFiles,
        //           currentIndexSlide,
        //           setFileData,
        //           linkToDownload,
        //         })
        //       }
        //       title="Скачать"
        //       key="Скачать"
        //     />,
        //   ]}
        onCancel={() => setVisibleModal(false)}
      >
        <Carousel beforeChange={(from, to) => setCurrentIndexSlide(to)}>
          {serviceFiles &&
            serviceFiles.map(({ src, mimetype, createdAt, name }) => (
              <div className="slider_text" key={createdAt} key={name}>
                <div
                  className="carousel_elem service_card"
                  style={{
                    background: `url(${
                      mimetype.includes('image') ? src : text
                    }) no-repeat 50% 50%`,
                  }}
                />
                <div>{name}</div>
              </div>
            ))}
        </Carousel>
        <a
          ref={linkToDownload}
          href={fileData.url}
          download={fileData.name}
        ></a>
      </LocalizedModal>
    </div>
  );
};

export default ServiceCardModal;
