import React from 'react';
import { useSelector } from 'react-redux';
import genConstructors from './constructors';
import { findInEntity } from 'utils/entity';

export default function GenColumns({ columnsData, setColumnsData }) {
  const priorities = useSelector(state => state.priorities.list);

  const { IconColumn, NewColumn, NewDateColumn } = genConstructors({
    columnsData,
    setColumnsData,
  });

  const stateColumn = new NewColumn('state', 75);
  const stateIconColumn = new NewColumn('stateIcon', 30, () => <span />);
  const branchIconColumn = new NewColumn('branchIcon', 30, () => <span />);
  const codeColumn = new NewColumn('code', 65);
  const externalCodeColumn = new NewColumn('externalCode', 90);
  const branchColumn = new NewColumn('branch', 120);
  const storeColumn = new NewColumn('store', 240);
  const detailsColumn = new NewColumn('details');
  const completeJobsColumn = new NewColumn('completeJobs');
  const suppliedByClientColumn = new NewColumn('suppliedByClient');
  const suppliedByServiceColumn = new NewColumn('suppliedByService');
  const leftInStoreColumn = new NewColumn('leftInStore');
  const noticesColumn = new NewColumn('notices');
  const uncompleteJobsColumn = new NewColumn('uncompleteJobs');
  const rejectedDetailsColumn = new NewColumn('rejectedDetails');
  const storeCodeColumn = new NewColumn('storeCode', 120);
  const brandColumn = new NewColumn('brand', 80);
  const buildingColumn = new NewColumn('building', 100);
  const cityColumn = new NewColumn('city', 100);
  const contractorColumn = new NewColumn('contractor', 150);

  const createdAtColumn = new NewDateColumn('createdAt');
  const completeAtColumn = new NewDateColumn('completeAt', 113);
  const closedAtColumn = new NewDateColumn('closedAt', 95);
  const rejectedAtColumn = new NewDateColumn('rejectedAt', 110);

  const filesColumn = new IconColumn({
    icon: 'file',
    dataIndex: 'files',
    width: 30,
  });
  const commentsColumn = new IconColumn({
    icon: 'team',
    dataIndex: 'comments',
    value: 0,
    width: 30,
  });

  const columns = [
    {
      title: () => (
        <div className="priority" title={columnsData.priority.title} />
      ),
      dataIndex: 'priority',
      className: `priority ${
        columnsData.priority.isActive ? '' : 'hideColumn'
      }`,
      key: 'priority',
      width: '20px',
      maxWidth: '20px',
      render: value => (
        <div
          className={`priority ${value}`}
          title={findInEntity(priorities, value, 'code', 'name')}
        />
      ),
    },
    stateIconColumn,
    stateColumn,
    branchIconColumn,
    branchColumn,
    codeColumn,
    externalCodeColumn,
    storeCodeColumn,
    brandColumn,
    storeColumn,
    buildingColumn,
    cityColumn,
    detailsColumn,
    contractorColumn,
    completeJobsColumn,
    suppliedByClientColumn,
    suppliedByServiceColumn,
    leftInStoreColumn,
    noticesColumn,
    uncompleteJobsColumn,
    rejectedDetailsColumn,
    createdAtColumn,
    completeAtColumn,
    closedAtColumn,
    rejectedAtColumn,
    commentsColumn,
    filesColumn,
  ];

  return columns.filter(c => c.className !== 'hideColumn');
}
