import React from 'react';
import _get from 'lodash/get';
import { Form, Checkbox } from 'antd';
import { formTailLayout, translations } from './constants';

const FormRow = ({ name, state, getFieldDecorator, postAct, deleteAct }) => (
  <Form.Item {...formTailLayout}>
    {getFieldDecorator(name, {
      initialValue: state,
      valuePropName: 'checked',
    })(
      <Checkbox
        onChange={(e, b, a) => {
          if (e.target.checked) {
            postAct(name);
          } else {
            deleteAct(name);
          }
        }}
      >
        {_get(translations, `[${name}]`, 'неизвестное уведомление')}
      </Checkbox>,
    )}
  </Form.Item>
);

export default FormRow;
