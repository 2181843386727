import React, { forwardRef, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadExcelFile } from 'slices/Requests';

import { Menu, Button } from 'antd';

import 'antd/dist/antd.css';
import { cutFields } from 'utils/helpers';
import './style.scss';

const OtherOptions = forwardRef(
  ({ onResetFields, filtersInUrl, columnsData }, ref) => {
    const excelLink = useRef(null);
    const dispatch = useDispatch();
    const [excelUrl, setExcelUrl] = useState(null);
    const [filename, setFilename] = useState('');
    const downloadFilters = () => {
      const availableColumns = Object.keys(columnsData).filter(
        column => columnsData[column].isActive,
      );
      dispatch(
        downloadExcelFile({
          ...cutFields(['name', 'id'], filtersInUrl),
          fields: availableColumns,
        }),
      )
        .then(({ fileData, nameFromHeader, mimeFromHeader }) => {
          const name = nameFromHeader
            .split(';')
            .find(el => el.includes('filename='))
            .split('=')[1]
            .replace(/['"]+/g, '');
          setFilename(name);
          const blob = new Blob([fileData], {
            type: mimeFromHeader,
          });
          setExcelUrl(URL.createObjectURL(blob));
          excelLink.current.click();
          URL.revokeObjectURL(excelLink.current.href);
        })
        .catch(e => console.error('oops', e));
    };

    return (
      <Menu theme="dark" mode="inline" inlineIndent={2} className="custom_menu">
        <Menu.Item
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            type="primary"
            size="small"
            icon="download"
            onClick={downloadFilters}
            title="Скачать excel файл"
            style={{ width: 40 }}
          />

          <Button
            type="primary"
            className="customPrimary"
            size="small"
            htmlType="submit"
          >
            Применить
          </Button>
          <Button type="danger" size="small" onClick={onResetFields}>
            Сбросить
          </Button>
        </Menu.Item>

        <a
          href={excelUrl}
          download={filename}
          ref={excelLink}
          style={{ display: 'none' }}
        />
      </Menu>
    );
  },
);

export default OtherOptions;
