import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBrandsOptions } from 'slices/SelectsFilters';

import Select from 'components/controls/base/Select';

export const BrandsSelect = forwardRef(
  ({ params = {}, label = '', ...props }, ref) => {
    const [gettedData, setGettedData] = useState(false);
    const dispatch = useDispatch();

    const { brandsLoading, brandsOptions } = useSelector(
      state => state.selectsFilters,
    );

    useEffect(() => {
      if (props.value && props.value.length)
        dispatch(getBrandsOptions({ ids: props.value, used: true }));
    }, [JSON.stringify(props.value)]);

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        <Select
          {...props}
          options={brandsOptions}
          onDropdownVisibleChange={() => {
            if (!gettedData) {
              dispatch(getBrandsOptions({ ...params, used: true }));
              setGettedData(true);
            }
          }}
          loading={brandsLoading}
        />
      </div>
    );
  },
);
