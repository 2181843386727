import React from 'react';
import { Form, Input, Button } from 'antd';
import { resetPassword } from 'slices/Sessions';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';

import openNotificationWithIcon from 'components/controls/base/Notification';
import { formItemLayout, tailFormItemLayout } from './constants';

const ResetPass = props => {
  const dispatch = useDispatch();

  const {
    getFieldDecorator,
    resetFields,
    validateFieldsAndScroll,
  } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch(resetPassword(values))
          .then(_ => {
            resetFields();
            openNotificationWithIcon(
              'success',
              'Письмо с инструкцией выслано на вашу почту',
            );
          })
          .catch(err =>
            openNotificationWithIcon('error', _get(err, 'data.message', '')),
          );
      }
      return;
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="E-mail">
        {getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: 'Пожалуйста введите валидный E-mail!',
            },
            {
              required: true,
              message: 'Пожалуйста введите ваш E-mail!',
            },
          ],
        })(<Input />)}
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" block>
          Восстановить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({ name: 'resetPass' })(ResetPass);
