import React, { forwardRef } from 'react';
import { Menu, Icon } from 'antd';

import {
  BrandsSelect,
  BuildingsSelect,
  CitiesSelect,
  StoresSelect,
} from 'components/controls/selects';
import 'antd/dist/antd.css';

const SubmenuOptThree = forwardRef(
  (
    {
      onFilterChange,
      getFieldDecorator,
      onChangeField,
      getFieldValue,
      SubMenu,

      ...props
    },
    ref,
  ) => {
    return (
      <SubMenu
        title={
          <span>
            <Icon type="select" />
            <span>Город, ТЦ, Бренд, Объект</span>
          </span>
        }
        ref={ref}
        {...props}
      >
        <Menu.Item className="custom_menuItem" key="710">
          {getFieldDecorator('cityIds', { initialValue: props.cityIds })(
            <CitiesSelect
              style={{ maxWidth: '100%', width: '100%' }}
              label="Город"
              mode="multiple"
              onChange={onChangeField('cityIds')}
              allowClear
              placeholder="Выберите город"
            />,
          )}
        </Menu.Item>

        <Menu.Item className="custom_menuItem" key="11">
          {getFieldDecorator('buildingIds', {
            initialValue: props.buildingIds,
          })(
            <BuildingsSelect
              style={{ maxWidth: '100%', width: '100%' }}
              label="ТЦ"
              mode="multiple"
              onChange={onChangeField('buildingIds')}
              citiesChoosen={getFieldValue('cityIds')}
              allowClear
              withCity
              placeholder="Выберите ТЦ"
            />,
          )}
        </Menu.Item>

        <Menu.Item className="custom_menuItem" key="12">
          {getFieldDecorator('brandIds', { initialValue: props.brandIds })(
            <BrandsSelect
              style={{ maxWidth: '100%', width: '100%' }}
              label="Бренд"
              mode="multiple"
              onChange={onChangeField('brandIds')}
              allowClear
              placeholder="Выберите бренд"
            />,
          )}
        </Menu.Item>

        <Menu.Item className="custom_menuItem" key="13">
          {getFieldDecorator('storeIds', { initialValue: props.storeIds })(
            <StoresSelect
              style={{ maxWidth: '100%', width: '100%' }}
              label="Объект"
              mode="multiple"
              buildingChoosen={getFieldValue('buildingIds')}
              citiesChoosen={getFieldValue('cityIds')}
              placeholder="Выберите объект"
              withInactive
              allowClear
              used
            />,
          )}
        </Menu.Item>
      </SubMenu>
    );
  },
);

export default SubmenuOptThree;
