import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Icon } from 'antd';
import _get from 'lodash/get';

import { createSession } from 'slices/Sessions';
import './login.scss';
import Button from 'components/controls/base/Button';
import { LocalizedModal } from 'components/controls/base/LocalizedModal';

import openNotificationWithIcon from 'components/controls/base/Notification';
import ResetPass from './components/ResetPass';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function Login({ form = {} }) {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, { email, password }) => {
      if (!err) {
        dispatch(
          createSession({ email: email.trim(), password: password.trim() }),
        ).catch(err => {
          openNotificationWithIcon('error', _get(err, 'data.message', ''));
        });
      }
    });
  };

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = form;

  // Only show error after a field is touched.
  const usernameError = isFieldTouched('email') && getFieldError('email');
  const passwordError = isFieldTouched('password') && getFieldError('password');

  return (
    <>
      <Form className="login-form" onSubmit={onSubmit}>
        <Form.Item
          validateStatus={usernameError ? 'error' : ''}
          help={usernameError || ''}
        >
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Введите E-mail!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-mail"
              className="login-form-input"
            />,
          )}
        </Form.Item>
        <Form.Item
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError || ''}
          className="login-form-input"
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Введите пароль!' }],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Пароль"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={hasErrors(getFieldsError())}
            title="Войти"
          />
          <Button
            type="link"
            className="login-form-forgot"
            onClick={() => setVisibleModal(true)}
            title="Восстановить пароль"
          />
        </Form.Item>
      </Form>
      <LocalizedModal
        title="Восстановление пароля"
        visible={visibleModal}
        maskClosable={true}
        cancelText="Отмена"
        footer={[
          <Button
            title="Отмена"
            key="back"
            onClick={() => setVisibleModal(false)}
          />,
        ]}
        onCancel={() => setVisibleModal(false)}
      >
        <ResetPass />
      </LocalizedModal>
    </>
  );
}

export default Form.create({ name: 'adapter' })(Login);
