import { createSlice } from 'redux-starter-kit';
import { Users } from 'connectors/query/Users';

const UsersSlice = createSlice({
  name: 'users',

  initialState: {
    list: [],
  },

  reducers: {
    setUsers: (state, { payload }) => {
      state.list = payload || [];
    },
  },
});

export default UsersSlice.reducer;

export const { setUsers } = UsersSlice.actions;

export const getUsers = params => async dispatch => {
  const response = await Users.getList(params);
  await dispatch(setUsers(response));
};
