import React, { useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRequestStates } from 'slices/Requests';

import Select from 'components/controls/base/Select';

export const RequestStatesSelect = forwardRef(
  ({ label = '', params = {}, availableStates = [], ...props }, ref) => {
    const dispatch = useDispatch();
    const requestStates = useSelector(state => state.requests.states);

    const activeValue = requestStates.find(s => s.code === props.activeField);

    let preparedState = requestStates.map(s => ({
      value: s.code,
      name: s.name,
    }));

    if (availableStates.length) {
      preparedState = preparedState.filter(ps =>
        availableStates.includes(ps.value),
      );
      preparedState = [
        ...preparedState,
        {
          value: activeValue && activeValue.code,
          name: activeValue && activeValue.name,
        },
      ];
    }

    useEffect(() => {
      dispatch(getRequestStates(params));
    }, []);

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        <Select {...props} options={preparedState} />
      </div>
    );
  },
);
