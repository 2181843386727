// import { message } from 'antd';
import { createSlice } from 'redux-starter-kit';
import { Priorities } from 'connectors/query/Priorities';

const PrioritiesSlice = createSlice({
  name: 'priorities',

  initialState: {
    list: [],
  },

  reducers: {
    setPriorities: (state, { payload }) => {
      state.list = payload || [];
    },
  },
});

export default PrioritiesSlice.reducer;

export const { setPriorities } = PrioritiesSlice.actions;

export const getPriorities = () => async dispatch => {
  const response = await Priorities.getList();
  await dispatch(setPriorities(response));
};
