import React from 'react';
import { Row, Col } from 'antd';

const CardInfo = ({ name = '', count = '' }) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      align="top"
      style={{ width: '100%' }}
    >
      <Col span={20}>{name}</Col>
      <Col span={2}>{count}</Col>
    </Row>
  );
};

export default CardInfo;
