import { createSlice } from 'redux-starter-kit';
import { Policies } from 'connectors/query/Policies';

const PoliciesSlice = createSlice({
  name: 'policies',

  initialState: {},

  reducers: {
    setPolicies: (state, { payload }) => {
      if (payload) {
        Object.keys(payload).forEach(p => (state[p] = payload[p]));
      }
    },
  },
});

export default PoliciesSlice.reducer;

export const { setPolicies } = PoliciesSlice.actions;

export const getPolicies = params => async dispatch => {
  const response = await Policies.getData(params);
  await dispatch(setPolicies(response));
};
