// import { message } from 'antd';
import { createSlice } from 'redux-starter-kit';
import localforage from 'localforage';
import { Sessions } from 'connectors/query/Sessions';
import parseJwt from 'utils/parseJwt';

const SessionsSlice = createSlice({
  name: 'sessions',

  initialState: {
    user: null,
    token: null,
  },

  reducers: {
    saveUser: (state, { payload: { token, user } }) => {
      state.token = token;
      state.user = user;
    },

    clearUser: state => {
      state.user = null;
      state.token = null;
    },
  },
});

export default SessionsSlice.reducer;

export const { saveUser, clearUser } = SessionsSlice.actions;

export const createSession = params => async dispatch => {
  try {
    const response = await Sessions.postSession(params);
    if (response && response.token) {
      const data = parseJwt(response.token);
      await localforage.setItem('token', JSON.stringify(response.token));
      await localforage.setItem('user', JSON.stringify(data));
      dispatch(saveUser({ token: response.token, user: data }));
    }
  } catch (e) {
    throw e;
  }
};

export const setUser = params => async dispatch => {
  dispatch(saveUser(params));
};

export const stopSession = () => dispatch => {
  localforage.removeItem('token');
  localforage.removeItem('user');
  dispatch(clearUser());
};

export const resetPassword = params => async dispatch => {
  try {
    await Sessions.postSessionPassword(params);
  } catch (e) {
    throw e;
  }
};
export const changePassword = params => async dispatch => {
  const response = await Sessions.patchSessionPassword(params);
  return response;
};
