import { createSlice } from 'redux-starter-kit';
import { Files } from '../connectors/query/Files';
import { Users } from '../connectors/query/Users';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

const FilesSlice = createSlice({
  name: 'files',

  initialState: {
    list: [],
  },

  reducers: {
    setFiles: (state, { payload }) => {
      state.list = sortFilesByDate(payload);
    },

    addFile: (state, { payload }) => {
      state.list = sortFilesByDate([...state.list, ...payload]);
    },
    updateFile: (state, { payload }) => {
      state.list = state.list.map(el => {
        if (el.id === payload.id) return { ...payload };
        return el;
      });
    },
  },
});

export default FilesSlice.reducer;

export const { setFiles, addFile, updateFile } = FilesSlice.actions;

export const getFilesCounter = params => async () => {
  const response = await Files.getCounter(params);
  return response;
};

// расширяет файлы полем fullName
const expansionFiles = ({ users, files }) => {
  try {
    const newFIles = [];
    users.forEach(user => {
      const userName = _get(user, 'name', '');
      const patronymic = _get(user, 'patronymic', '');
      files.forEach(file => {
        if (file.createdBy === user.id) {
          newFIles.push({
            ...file,
            fullName: `${userName} ${patronymic}`,
          });
        }
      });
    });
    return newFIles;
  } catch (error) {
    console.error('error in expansionFiles fn', error);
    return [];
  }
};

const sortFilesByDate = files => {
  try {
    return files.sort((a, b) => {
      const aDate = new Date(a.createdAt).getTime();
      const bDate = new Date(b.createdAt).getTime();
      return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
    });
  } catch (error) {
    console.error('error in sortFilesByDate fn', error);
    return [];
  }
};

export const getFiles = requestId => async dispatch => {
  const files = await Files.getFiles(requestId);
  const createdByIds = _uniq(files.map(u => u.createdBy));
  const users = await Users.getList({ ids: createdByIds });
  if (users) {
    dispatch(setFiles(expansionFiles({ users, files })));
  }
};

export const uploadFiles = (requestId, data, file = true) => async dispatch => {
  try {
    const response = await Files.postFiles(requestId, data);
    if (file) {
      const createdByIds = _uniq(response.map(u => u.createdBy));
      const users = await Users.getList({ ids: createdByIds });

      await dispatch(addFile(expansionFiles({ users, files: response })));
    }
    return response;
  } catch ({ status }) {
    throw status;
  }
};

export const updateBlobFiles = file => async dispatch => {
  dispatch(updateFile(file));
};

export const getFile = id => async dispatch => {
  const response = await Files.getFile(id);
  return response;
};
