import _isEmpty from 'lodash/isEmpty';
import openNotificationWithIcon from 'components/controls/base/Notification';
import { preparedSRC } from '../RequestForm/helpers';
import smetaPreviewFile from './service-card-preview.jpg';

export const download = async ({
  setFileData,
  serviceFiles,
  linkToDownload,
  currentIndexSlide,
}) => {
  const activeSlide = serviceFiles[currentIndexSlide];
  await setFileData({
    url: URL.createObjectURL(activeSlide.blob),
    name: activeSlide.name,
  });
  linkToDownload.current.click();
  URL.revokeObjectURL(linkToDownload.current.href);
};

// Проверка на уже добавленные файлы
export const checkFiles = (allFiles, filesToSentServer) => {
  const preparedFiles = filesToSentServer.map(actualFile => {
    const match = allFiles.find(f => f.name === actualFile.name);
    if (match) {
      openNotificationWithIcon('error', `Файл ${actualFile.name} уже добавлен`);
      return;
    }
    return actualFile;
  });
  // чистим массив от undefined
  return preparedFiles.filter(f => !!f);
};

export const uploadFileToServer = ({
  fileList,
  dispatch,
  uploadFiles,
  setServiceFiles,
  allFiles,
  id,
}) => {
  const formData = new FormData();

  const finalFiles = checkFiles(allFiles, fileList);
  if (_isEmpty(finalFiles)) return;

  finalFiles.forEach(newFile => {
    formData.append('files', newFile.originFileObj, newFile.name);
  });

  dispatch(uploadFiles({ id, type: 'card', value: 'true' }, formData))
    .then(newAddedFile => {
      setServiceFiles(finalFiles);
      newAddedFile.forEach(file => {
        openNotificationWithIcon(
          'success',
          `Файл ${file.name} успешно добавлен`,
        );
      });
    })
    .catch(status => {
      if (status === 413) {
        return openNotificationWithIcon(
          'error',
          `Размер файла должен быть меньше 1мб`,
        );
      }
      return openNotificationWithIcon(
        'error',
        `При загрузке файла что-то пошло не так`,
      );
    });
};

const getBlob = async ({ dispatch, getFile, id, restData }) => {
  let urlBase64;
  const { fileData, mimeFromHeader } = await dispatch(getFile(id));
  const blob = await new Blob([fileData], { type: mimeFromHeader });
  await preparedSRC({ blob }).then(res => (urlBase64 = res));
  return { ...restData, blob, src: urlBase64, uid: id };
};

// фильтрует все файлы по сервисным картам
export const getIsCardFiles = ({
  files,
  getFile,
  dispatch,
  serviceFiles,
  setServiceFiles,
}) => {
  const result = files.map(async ({ id, card, ...restData }) => {
    if (card) {
      if (!serviceFiles) {
        return getBlob({ dispatch, getFile, id, restData });
      }
      if (serviceFiles) {
        const match = serviceFiles.find(serviceCard => id === serviceCard.uid);
        if (!match) {
          return getBlob({ dispatch, getFile, id, restData });
        }
      }
    }
  });
  Promise.all(result)
    .then(result => {
      if (serviceFiles) {
        return setServiceFiles([...serviceFiles, ...result.filter(f => !!f)]);
      }
      setServiceFiles([...result.filter(f => !!f)]);
    })
    .catch(e => console.error(e, 'Ошибка в формировании файлов isCard'));
};

// сортирует по полю createdAt последний добавленный файл и выводит его превью

const checkByimg = file => {
  if (!file) return false;
  const imgType = ['JPG', 'JPEG', 'JFIF', 'PNG', 'GIF', 'SVG'];
  return imgType.find(type => file.toLowerCase().includes(type.toLowerCase()));
};

export const getPreview = (files = [{ src: 'src is undefined' }]) => {
  try {
    const srcBase64 = files.sort(
      (fileA, fileB) =>
        new Date(fileB.createdAt).getTime() -
        new Date(fileA.createdAt).getTime(),
    )[0].src;
    // Если посл добавленный файл не изображение, отдаем мок
    if (!checkByimg(srcBase64)) return smetaPreviewFile;
    return srcBase64;
  } catch (error) {
    console.error('getPreview fn throw err');
  }
};
