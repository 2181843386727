import React, { useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBranches } from 'slices/Branches';
import _isEmpty from 'lodash/isEmpty';

import Select from 'components/controls/base/Select';

export const BranchesSelect = forwardRef(
  ({ params = {}, label = '', compact = false, ...props }, ref) => {
    const dispatch = useDispatch();
    const branches = useSelector(state => state.branches.list);

    const preparedBranches = branches.map(s => ({
      value: s.code,
      name: s.name,
    }));

    useEffect(() => {
      dispatch(getBranches(params));
    }, []);

    const selectedBranch = preparedBranches.find(b => b.value === props.value);

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        {compact ? (
          <span className="highlighter">
            {selectedBranch && selectedBranch.name}
          </span>
        ) : (
          <Select {...props} options={preparedBranches} />
        )}
      </div>
    );
  },
);
