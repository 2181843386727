import { APIService } from 'connectors/api/rest';

export const Estimates = {
  async getUnitsList(id) {
    try {
      return await APIService.GET('/units', { id });
    } catch (e) {
      throw e;
    }
  },
  async getList(requestId) {
    try {
      return await APIService.GET('/estimations', { requestId });
    } catch (e) {
      throw e;
    }
  },
  async createEstimate(data) {
    try {
      return await APIService.POST('/estimations', data);
    } catch (e) {
      throw e;
    }
  },
  async replaceEstimate({ id, ...data }) {
    try {
      return await APIService.PUT(`/estimations/${id}`, data);
    } catch (e) {
      throw e;
    }
  },
  async deleteEstimate(id) {
    try {
      return await APIService.DELETE(`estimations/${id}`);
    } catch (e) {
      throw e;
    }
  },
  async postExcellEstimates(id) {
    try {
      return await APIService.POST(`/estimations/excel?requestId=${id}`);
    } catch (e) {
      throw e;
    }
  },
};
