import moment from 'moment';
import { outputDateFormat } from 'utils/constants';
import { findInEntity } from 'utils/entity';

const timesFields = [
  'createdAt',
  'updatedAt',
  'closedAt',
  'completeAt',
  'rejectedAt',
];

export default function dataConverter(
  data,
  { stores, requestStates, branches, companies, buildings, brands, cities },
) {
  const formatedData =
    data &&
    data.map(row => {
      const oldRow = { ...row };

      Object.keys(row).forEach(field => {
        if (row[field]) {
          // перевод полей с датами в читаемый формат
          if (timesFields.includes(field)) {
            oldRow[field] = moment(row[field]).format(outputDateFormat);
          }

          // отображение исполнителей
          if (field === 'contractorCompanyId' && companies) {
            oldRow.contractor = findInEntity(
              companies,
              row[field],
              'id',
              'name',
            );
          }

          // перевод статусов
          if (field === 'state' && requestStates) {
            oldRow[field] = findInEntity(
              requestStates,
              row[field],
              'code',
              'name',
            );
            // столбец с иконками статусов
            oldRow.stateIcon = findInEntity(
              requestStates,
              row[field],
              'code',
              'name',
            );
          }

          // перевод отраслей
          if (field === 'branch' && branches) {
            oldRow[field] = findInEntity(branches, row[field], 'code', 'name');
            oldRow.branchIcon = findInEntity(
              branches,
              row[field],
              'code',
              'name',
            );
          }

          // получение полей, получаемых из storeId
          if (field === 'storeId' && stores) {
            const store = stores.find(s => s.id === row[field]) || {};
            const building =
              buildings.find(b => b.id === store.buildingId) || {};

            oldRow.storeCode = findInEntity(stores, row[field], 'id', 'code');
            oldRow.brand = findInEntity(brands, store.brandId, 'id', 'name');
            oldRow.city = findInEntity(cities, building.cityId, 'id', 'name');
            oldRow.building = findInEntity(
              buildings,
              store.buildingId,
              'id',
              'name',
            );

            const brandShortName = findInEntity(
              brands,
              store.brandId,
              'id',
              'shortName',
            );
            const cityAbbreviation = findInEntity(
              cities,
              building.cityId,
              'id',
              'abbreviation',
            );
            oldRow.store = `${brandShortName}: ${oldRow.building} - ${cityAbbreviation}`;
          }
        }
      });

      return oldRow;
    });

  return formatedData;
}
