import React from 'react';
import { Button as AntButton } from 'antd';

const Button = ({ type = 'primary', title = 'Кнопка', ...props }) => {
  return (
    <AntButton type={type} {...props}>
      {title}
    </AntButton>
  );
};

export default Button;
