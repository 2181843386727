const defaultColumnsData = {
  priority: { isActive: true, title: 'Приоритет' },
  stateIcon: { isActive: true, title: 'Статус (иконка)' },
  state: { isActive: true, title: 'Статус' },
  branchIcon: { isActive: true, title: 'Отрасль (иконка)' },
  branch: { isActive: false, title: 'Отрасль' },
  code: { isActive: true, title: 'Заявка' },
  externalCode: { isActive: true, title: 'Внешний № Заявки' },
  store: { isActive: true, title: 'Объект' },
  city: { isActive: true, title: 'Город' },
  building: { isActive: false, title: 'ТЦ' },
  brand: { isActive: false, title: 'Бренд' },
  storeCode: { isActive: false, title: 'Номер объекта' },
  details: { isActive: true, title: 'Описание заявки' },
  contractor: { isActive: false, title: 'Исполнитель' },
  completeJobs: { isActive: true, title: 'Описание выполнения' },
  suppliedByClient: { isActive: false, title: 'Поставлено заказчиком' },
  suppliedByService: { isActive: false, title: 'Поставлено исполнителем' },
  leftInStore: { isActive: false, title: 'Оставлено на объекте' },
  notices: { isActive: false, title: 'Замечания и рекомендации' },
  uncompleteJobs: { isActive: false, title: 'Описания не выполнения' },
  rejectedDetails: { isActive: false, title: 'Описание отклонения' },
  createdAt: { isActive: true, title: 'Создана' },
  completeAt: { isActive: true, title: 'Срок выполнения' },
  closedAt: { isActive: true, title: 'Дата закрытия' },
  rejectedAt: { isActive: true, title: 'Дата отклонения' },
  comments: { isActive: true, title: 'Сообщений' },
  files: { isActive: true, title: 'Вложений' },
};

export default defaultColumnsData;
