import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import localforage from 'localforage';
import moment from 'moment';
import { Comment, Form, Collapse, List, Input, Checkbox, Button } from 'antd';

import _getFp from 'lodash/fp/get';
import _uniq from 'lodash/uniq';
import _sortByFp from 'lodash/fp/sortBy';
import { withHoursDateFormat } from 'utils/constants';

import { postComment } from 'slices/Comments';
import { getRequestComments, getRequestEvents } from 'slices/Requests';
import { getUsers } from 'slices/Users';
import SystemMessage from './components/SystemMessage';

import './styles.scss';

const { TextArea } = Input;
const { Panel } = Collapse;

// список комментов
const CommentList = ({ comments }) => {
  return (
    <List
      dataSource={comments}
      header={`${comments.length - 1} ${'сообщений'}`}
      itemLayout="horizontal"
      renderItem={props => {
        // Инфо о создателе заявки
        if (props.createInfo)
          return (
            <div className="comment_createInfo">
              <span className="comment_createInfo_name">
                {_getFp('user.name')(props)} {_getFp('user.surname')(props)}
              </span>
              <span className="comment_createInfo_date">
                {moment(props.createdAt).format('DD.MM.YYYY HH:mm')}:
              </span>
              <span className="comment_createInfo_title">Заявка создана</span>
            </div>
          );
        // Системные сообщения
        if (props.event) return <SystemMessage {...props} />;
        // Комментарии
        return (
          <Comment
            className={`comment_user ${_getFp('user.role')(props)}_color`}
            content={<p>{props.text}</p>}
            datetime={moment(props.createdAt).format('DD.MM.YYYY HH:mm')}
            author={`${_getFp('user.name')(props)} 
              ${_getFp('user.surname')(props)}`}
          />
        );
      }}
    />
  );
};

// строка комментирования
const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <TextArea
      placeholder="Введите комментарий"
      onChange={onChange}
      disabled={submitting}
      value={value}
      autoSize={{ minRows: 3, maxRows: 5 }}
    />
    <Button type="primary" onClick={onSubmit}>
      Отправить
    </Button>
  </div>
);

const ChatBox = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const request = useSelector(state => state.requests.currentRequest);
  const comments = useSelector(state => state.requests.comments);
  const events = useSelector(state => state.requests.events);
  const users = useSelector(state => state.users.list);

  const [usersIds, setUsersIds] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [showSysEvents, setShowSysEvents] = useState(false);

  useEffect(() => {
    dispatch(getRequestComments(id));
    dispatch(getRequestEvents(id));

    const getCheckboxValue = async () => {
      const showSysEvents = await localforage.getItem('showSysEvents');
      setShowSysEvents(showSysEvents);
    };
    getCheckboxValue();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (comments && events && request) {
      const usersComments = comments.map(c => c.createdBy);
      const usersEvents = events.map(e => e.createdBy);
      const uniq = _uniq([
        ...usersComments,
        ...usersEvents,
        request.createdBy,
      ]).filter(u => !!u);
      if (uniq.length) setUsersIds(uniq);
    }
  }, [comments, events, request]);

  useEffect(() => {
    if (usersIds.length) dispatch(getUsers({ ids: usersIds }));
  }, [usersIds]); // eslint-disable-line

  // объединяем комменты и ивенты, сортируем по дате, подкладываем инфу о юзере
  const prepareComments =
    (comments &&
      users &&
      events &&
      [
        ...comments.map(c => ({ ...c, event: false })),
        ...events.map(e => ({ ...e, event: true })),
        { createInfo: true, ...request },
      ]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map(c => ({
          ...c,
          user: users.find(u => u.id === c.createdBy),
        }))) ||
    [];

  const filteredPreparedComments = showSysEvents
    ? prepareComments
    : prepareComments.filter(pc => !pc.event);

  const onSubmit = () => {
    if (!value) return;
    setSubmitting(true);

    dispatch(postComment({ requestId: id, text: value })).then(() => {
      setSubmitting(false);
    });
    setValue('');
  };

  const handleShowSysEvents = async () => {
    setShowSysEvents(!showSysEvents);
    await localforage.setItem('showSysEvents', !showSysEvents);
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel key="1" header="Чат">
        <Checkbox checked={showSysEvents} onChange={handleShowSysEvents}>
          Показать системные сообщения
        </Checkbox>
        <div>
          <Comment
            content={
              <Editor
                onChange={e => setValue(e.target.value)}
                onSubmit={onSubmit}
                submitting={submitting}
                value={value}
              />
            }
          />
          {prepareComments && prepareComments.length > 0 && (
            <CommentList comments={filteredPreparedComments} />
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default ChatBox;
