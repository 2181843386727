import { APIService } from 'connectors/api/rest';

export const Buildings = {
  async getList(params) {
    try {
      return await APIService.GET('buildings', params);
    } catch (e) {
      return null;
    }
  },
};
