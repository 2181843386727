import { APIService } from 'connectors/api/rest';

export const Notifications = {
  async GET() {
    try {
      return await APIService.GET('notifications');
    } catch (e) {
      throw e;
    }
  },
  async DELETE(code) {
    try {
      return await APIService.DELETE('notifications', {
        params: { code },
      });
    } catch (e) {
      throw e;
    }
  },
  async POST(code) {
    try {
      return await APIService.POST(`notifications?code=${code}`);
    } catch (e) {
      throw e;
    }
  },
};
