export const RequestsFieldsTranslator = code => {
  const fields = {
    storeId: 'магазин',
    startedAt: 'дата начала работ',
    finishedAt: 'дата окончания работ',
    closedAt: 'дата закрытия',
    closedDetails: 'причина закрытия',
    rejectedAt: 'дата отклонения',
    rejectedDetails: 'причина отклонения',
    completeAt: 'планируемая дата завершения заявки',
    state: 'статус',
    priority: 'приоритет',
    branch: 'отрасль',
    details: 'описание',
    completeJobs: 'перечень выполненых работ',
    suppliedByClient: 'перечень продукции, поставленной заказчиком',
    suppliedByService: 'перечень продукции, поставленной исполнителем',
    leftInStore: 'перечень продукции, оставленной в магазине',
    notices: 'замечания и рекомендации',
    uncompleteJobs: 'перечень неоконченных работ',
    externalCode: 'Внешний номер',
  };

  return fields.hasOwnProperty(code) ? fields[code] : null;
};
