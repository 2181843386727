import { createSlice } from 'redux-starter-kit';
import { Notifications } from 'connectors/query/Notifications';
import openNotificationWithIcon from 'components/controls/base/Notification';

const NotificationsSlice = createSlice({
  name: 'Notifications',

  initialState: {
    newRequest: false,
    newComment: false,
  },

  reducers: {
    setNotifications: (state, { payload }) => {
      if (payload) {
        Object.keys(payload).forEach(p => (state[p] = payload[p]));
      }
    },
  },
});

export default NotificationsSlice.reducer;

export const { setNotifications } = NotificationsSlice.actions;

export const getNotifications = params => async dispatch => {
  const response = await Notifications.GET(params);
  await dispatch(setNotifications(response));
};

export const postNotifications = params => async dispatch => {
  await Notifications.POST(params);
  openNotificationWithIcon('success', 'Уведомления обновлены');
};

export const deleteNotifications = params => async dispatch => {
  await Notifications.DELETE(params);
  openNotificationWithIcon('success', 'Уведомления обновлены');
};
