import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';

function HeaderItem({ url, icon, label, ...props }) {
  return (
    <Menu.Item key={url} {...props}>
      <Link to={url}>
        {icon && <Icon type={icon} />}
        {label}
      </Link>
    </Menu.Item>
  );
}

export default HeaderItem;
