import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import localforage from 'localforage';
import { Menu, Form } from 'antd';
import moment from 'moment';
import { putFilter, postFilters } from 'slices/Filters';
import { getRequests, setTotalRequests } from 'slices/Requests';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _without from 'lodash/without';

import { useQueryParams } from 'use-query-params';

import openNotificationWithIcon from 'components/controls/base/Notification';
import SubmenuOptOne from '../sidemenuOptions/SubmenuOptOne';
import SubmenuOptTwo from '../sidemenuOptions/SubmenuOptTwo';
import SubmenuOptThree from '../sidemenuOptions/SubmenuOptThree';
import SubmenuOptFour from '../sidemenuOptions/SubmenuOptFour';
import OtherOptions from '../sidemenuOptions/OtherOptions';
import {
  customModalType,
  LocalizedModal,
} from 'components/controls/base/LocalizedModal';
import Input from 'components/controls/base/Input';

import {
  cardType,
  editFilter,
  defaultPage,
  parseUrlToObj,
  defaultPerPage,
  queryParamsInit,
  prepareRequestData,
} from './helpers';
import { cutFields } from 'utils/helpers';

import 'antd/dist/antd.css';
import './style.scss';

const CustomizedFilterForm = Form.create({
  name: 'filters',
  onFieldsChange(props, changedFields, allFields) {
    props.onChange(changedFields, allFields, props.form.resetFields);
  },
})(
  ({
    form,
    setFilterData,
    setPerPage,
    setPage,
    page,
    perPage,
    filterData,
    columnsData,
    ...props
  }) => {
    const {
      setFields,
      resetFields,
      getFieldValue,
      getFieldsValue,
      setFieldsValue,
      getFieldDecorator,
    } = form;
    const dispatch = useDispatch();
    const history = useHistory();

    const allMyFilters = useSelector(state => state.filters.list);
    const [visibleModal, setVisibleModal] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [openMenuKeys, setOpenMenuKeys] = useState(['sub3']);

    const { SubMenu } = Menu;
    const [filtersInUrl, setFiltersinURL] = useQueryParams(queryParamsInit);
   
    useEffect(() => {
      (async function loadMenuKeys() {
        const filterMunuFromLS = await localforage.getItem('openFilterMenu');
        if (filterMunuFromLS) setOpenMenuKeys(filterMunuFromLS);
      })();
      return () => {
        dispatch(setTotalRequests(0));
      };
    }, []);

    useEffect(() => {
      const {
        page: pageFromUrl,
        perPage: perPageFromUrl,
        ...updatedFields
      } = parseUrlToObj();

      onFilterChange(updatedFields.id, updatedFields);

      const curPage = pageFromUrl || defaultPage;
      const curPerPage = perPageFromUrl || defaultPerPage;

      setFiltersinURL({
        page: curPage,
        perPage: curPerPage,
      });
      dispatch(
        getRequests({ ...updatedFields, page: curPage, perPage: curPerPage }),
      );
      setPage(pageFromUrl);
      setPerPage(perPageFromUrl);
    }, []);

    // при изменении пагинации изменять урлу
    useEffect(() => {
      const { page: pageFromUrl, perPage: perPageFromUrl } = parseUrlToObj();
      setFiltersinURL({
        page: page || pageFromUrl,
        perPage: perPage || perPageFromUrl,
      });
    }, [page, perPage]);

    // устанавливаем в модалке имя выбранного фильтра
    useEffect(() => {
      const nameFilter = _get(
        allMyFilters.find(f => f.id === getFieldsValue().id),
        'name',
        '',
      );
      setFilterName(nameFilter);
    }, [allMyFilters]);

    // Метод для очистки URL от параметров
    const clearDataUrl = () => {
      history.push(`/requests?page=${defaultPage}&perPage=${defaultPerPage}`);
    };

    const setFilters = (fields, dataFromUrl) => {
      const {
        page: pageFromUrl,
        perPage: perPageFromUrl,
        ...updatedFields
      } = parseUrlToObj();

      // Перед применением нового фильтра, очищаем старый
      clearDataUrl();
      // проверяем есть ли в урле данные из фильтров карточки
      let cardInfo = {};
      cardType.forEach(type => {
        if (updatedFields[type]) {
          cardInfo = { ...cardInfo, [type]: updatedFields[type] };
        }
      });
      const data = {
        ...fields,
        ...cardInfo,
      };

      if (!dataFromUrl) {
        data.page = defaultPage;
        data.perPage = defaultPerPage;
        setFiltersinURL(data);
        dispatch(getRequests(data));
        setPage(defaultPage);
        setPerPage(defaultPerPage);
        return;
      }

      setFiltersinURL(data);
    };

    // Применить фильтры
    const applyFilters = ({ data, dataFromUrl, cutNameFields = ['name'] }) => {
      const fields = prepareRequestData(data);
      setFilters(cutFields(cutNameFields, fields), dataFromUrl);
    };

    const onSubmit = e => {
      e.preventDefault();
      applyFilters({
        data: getFieldsValue(),
        dataFromUrl: false,
        cutNameFields: ['name', 'id'],
      });
    };

    // метод для сохранения фильтров
    const onSaveFilters = () => {
      const newFilds = getFieldsValue();
      let changeTargetFilter = false;
      // Проверка поля 'Имя фильтра'
      if (!newFilds.name) {
        setFields({
          name: {
            errors: [new Error('Введите имя фильтра')],
          },
        });
        openNotificationWithIcon('error', 'Введите имя фильтра');
        return;
      }
      // Проверка на изменение текущего фильтра
      allMyFilters.forEach(filter => {
        if (filter.name === newFilds.name.trim()) {
          changeTargetFilter = true;
          customModalType({
            type: 'confirm',
            content:
              'Фильтр с таким именем уже существует. Вы уверены, что хотите его обновить?',
            onOk() {
              const prepareEditData = editFilter(filter, newFilds);
              if (_isEmpty(prepareEditData)) return;
              dispatch(putFilter(filter.id, prepareEditData)).then(_ => {
                openNotificationWithIcon('success', 'Фильтр успешно изменен');
                resetFields();
                clearDataUrl();
                setVisibleModal(false);
              });
            },
          });
          return;
        }
      });

      if (changeTargetFilter) return;
      const preparedFields = prepareRequestData(newFilds, true);
      dispatch(postFilters(preparedFields))
        .then(_ => {
          openNotificationWithIcon('success', 'Фильтр успешно сохранен');
          // onResetFields();
          // setFilterName('');
          // clearDataUrl();
          setVisibleModal(false);
        })
        .catch(e => {
          openNotificationWithIcon('error', 'Упс, что-то пошло не так :(');
        });
    };

    // Обработчик onChange полей
    const onChangeField = fieldName => value => {
      // Убрать ошибку если ввели значение в поле name
      if (fieldName === 'name' && value.trim() !== '') {
        setFields({
          [fieldName]: {
            errors: null,
          },
        });
      }
      setFieldsValue({ [fieldName]: value });
    };

    // Выбор сохраненного фильтра
    const onFilterChange = (activeIdFilter, dataFromUrl = false) => {
      const preparedActiveFilter = {};
      let activeFilter;
      if (!dataFromUrl) {
        activeFilter = allMyFilters.find(f => f.id === Number(activeIdFilter));
        // AntD если данных нет устанавливает Undefined
        // бек возвращает null, приводим к одному типу
        // Warning AntD -Убираем поля, которые не исп-ся в форме
        Object.keys(activeFilter).forEach(el => {
          if (activeFilter[el] === null) {
            preparedActiveFilter[el] = undefined;
            return;
          }
          if (activeFilter[el] && el !== 'userId' && el !== 'createdAt') {
            preparedActiveFilter[el] = activeFilter[el];
          }
        });
      }

      if (dataFromUrl) {
        activeFilter = dataFromUrl;
        Object.keys(activeFilter).forEach(el => {
          if (activeFilter[el] === null) {
            preparedActiveFilter[el] = undefined;
            return;
          }
          if (activeFilter[el] && el !== 'userId' && el !== 'createdAt') {
            el === 'id'
              ? (preparedActiveFilter[el] = Number(activeFilter[el]))
              : (preparedActiveFilter[el] = activeFilter[el]);
          }
        });
      }
      // AntD если данных нет устанавливает Undefined
      // бек возвращает null, приводим к одному типу
      const preparedDate = date => (date ? moment(date) : undefined);
      // Установка значний сохр фильтра
      setFieldsValue({
        ...preparedActiveFilter,
        createdAtFrom: preparedDate(activeFilter.createdAtFrom),
        createdAtTo: preparedDate(activeFilter.createdAtTo),
        completeAtFrom: preparedDate(activeFilter.completeAtFrom),
        completeAtTo: preparedDate(activeFilter.completeAtTo),
      });
      //  при выборе сохранного фильтра сразу фильтровать
      applyFilters({ data: preparedActiveFilter, dataFromUrl });
    };

    const onResetFields = () => {
      resetFields();
      setPage(defaultPage);
      setPerPage(defaultPerPage);
      setFilterData({});
      clearDataUrl();

      dispatch(getRequests({ page: defaultPage, perPage: defaultPerPage }));
    };

    // Методы для Datepicker'ов.
    const disabledDateField = dataFieldName => value => {
      if (!value || !getFieldValue(dataFieldName)) {
        return false;
      }
      return value.valueOf() <= getFieldValue(dataFieldName).valueOf();
    };

    return (
      <Form className="filter-form" onSubmit={onSubmit}>
        <Menu
          openKeys={openMenuKeys}
          inlineIndent={2}
          theme="dark"
          mode="inline"
          onOpenChange={a => {
            const newOpenKeys = _without(a, ...openMenuKeys);
            localforage.setItem('openFilterMenu', newOpenKeys);
            setOpenMenuKeys(newOpenKeys);
          }}
        >
          <SubmenuOptOne
            getFieldDecorator={getFieldDecorator}
            onFilterChange={onFilterChange}
            onSaveFilters={onSaveFilters}
            setVisibleModal={setVisibleModal}
            SubMenu={SubMenu}
            key="sub1"
            {...props}
          />
          <SubmenuOptTwo
            getFieldDecorator={getFieldDecorator}
            SubMenu={SubMenu}
            key="sub2"
            {...props}
          />
          <SubmenuOptThree
            getFieldDecorator={getFieldDecorator}
            onChangeField={onChangeField}
            getFieldValue={getFieldValue}
            SubMenu={SubMenu}
            key="sub3"
            {...props}
          />
          <SubmenuOptFour
            disabledDateField={disabledDateField}
            getFieldDecorator={getFieldDecorator}
            onChangeField={onChangeField}
            getFieldValue={getFieldValue}
            SubMenu={SubMenu}
            key="sub4"
            {...props}
          />
          <OtherOptions
            getFieldDecorator={getFieldDecorator}
            onChangeField={onChangeField}
            onResetFields={onResetFields}
            filtersInUrl={filtersInUrl}
            columnsData={columnsData}
            SubMenu={SubMenu}
            key="sub5"
            {...props}
          />
        </Menu>
        <LocalizedModal
          title="Имя фильтра"
          visible={visibleModal}
          maskClosable={true}
          cancelText="Отмена"
          onOk={onSaveFilters}
          onCancel={() => setVisibleModal(false)}
        >
          <Form.Item>
            {getFieldDecorator('name', { initialValue: filterName })(
              <Input
                title="Имя фильтра"
                placeholder="Введите имя фильтра"
                onChange={e => onChangeField('name')(e.target.value)}
              />,
            )}
          </Form.Item>
        </LocalizedModal>
      </Form>
    );
  },
);

export default CustomizedFilterForm;
