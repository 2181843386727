import { createSlice } from 'redux-starter-kit';
import { Filters } from '../connectors/query/Filters';
import _uniqBy from 'lodash/uniqBy';
import _concat from 'lodash/concat';
import { defaultDateFormat, dateFields } from 'utils/constants';
import { dateChangeFormat } from 'utils/helpers';

const FiltersSlice = createSlice({
  name: 'filters',

  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setFilters: (state, { payload }) => {
      if (payload)
        state.list = dateChangeFormat(payload, dateFields, defaultDateFormat);
    },
    addFilters: (state, { payload }) => {
      state.list = [...state.list, payload];
    },
    editFilters: (state, { payload }) => {
      // пробегаемся по state.list, находим совпадение по id payload
      // кладем payload вместо старого значения в list
      state.list = _uniqBy(_concat(payload, state.list), ({ id }) => id);
    },
  },
});

export default FiltersSlice.reducer;

export const {
  setFilters,
  addFilters,
  editFilters,
  setLoading,
} = FiltersSlice.actions;

export const getFilters = params => async dispatch => {
  dispatch(setLoading(true));
  const response = await Filters.getList(params);
  await dispatch(setFilters(response));
  dispatch(setLoading(false));
};

export const postFilters = params => async dispatch => {
  const response = await Filters.postFilter(params);
  await dispatch(addFilters(response));
};

export const delFilters = params => async dispatch => {
  await Filters.delFilter(params);
};
export const putFilter = (id, params) => async dispatch => {
  const response = await Filters.putFilter(id, params);
  await dispatch(editFilters(response));
};
