import moment from 'moment';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// отправляем только те данные, которые были изменены
export const preparingRequestData = (currentData = {}, oldData = {}) => {
  const data = {};
  Object.keys(currentData).forEach(fieldName => {
    Object.keys(oldData).forEach(requestFieldName => {
      if (fieldName === requestFieldName) {
        if (fieldName === 'completeAt') {
          if (
            moment(currentData.completeAt).format('YYYY-MM-DD') ===
            moment(oldData.completeAt).format('YYYY-MM-DD')
          )
            return;
        }
        if (!currentData[fieldName] && !oldData[requestFieldName]) return;

        if (currentData[fieldName] !== oldData[requestFieldName]) {
          data[fieldName] = currentData[fieldName];
        }
      }
    });
  });
  return data;
};

export const prepareFiles = ({
  id = 0,
  addedFiles = [],
  files = [],
  openNotificationWithIcon = _ => _,
  dispatch = _ => _,
  setFilesToUpload = _ => _,
  uploadFiles = _ => _,
}) => {
  const formData = new FormData();
  const allGroupFiles = Object.keys(addedFiles);
  allGroupFiles.forEach(groupFile => {
    const groupFiles = addedFiles[groupFile];

    if (Array.isArray(groupFiles) && groupFiles.length > 0) {
      const cleanFiles = groupFiles.filter(curFile => {
        const match = files.find(f => f.name === curFile.name);
        return match
          ? openNotificationWithIcon(
              'error',
              `Файл ${curFile.name} уже добавлен`,
            )
          : curFile;
      });

      if (cleanFiles && cleanFiles.length > 0) {
        cleanFiles.forEach(newFile => {
          formData.append('files', newFile.originFileObj, newFile.name);
        });
        dispatch(uploadFiles({ id, ...addedFiles.typeFile }, formData))
          .then(newAddedFile => {
            newAddedFile.forEach(file => {
              openNotificationWithIcon(
                'success',
                `Файл ${file.name} успешно добавлен`,
              );
            });
          })
          .catch(status => {
            if (status === 413) {
              return openNotificationWithIcon(
                'error',
                `Размер файла должен быть меньше 1мб`,
              );
            }
            return openNotificationWithIcon(
              'error',
              `При загрузке файла что-то пошло не так`,
            );
          });
      }
      setFilesToUpload([]);
    }
  });
};

export const onSubmit = (
  e,
  {
    form = {},
    request = {},
    setModalData = _ => _,
    setVisibleModal = _ => _,
    editRequestData = _ => _,
    openNotificationWithIcon = _ => _,
    // preparingRequestData = _ => _,
  },
) => {
  e.preventDefault();
  form.validateFieldsAndScroll((err, values) => {
    let preparedData = { ...request, ...values };
    if (!err && !_isEmpty(preparedData)) {
      if (preparedData.state === 'closed' && !preparedData.rejectedDetails) {
        setModalData(preparedData);
        setVisibleModal(true);
        return;
      }
      if (preparedData.state === 'rejected' && !preparedData.rejectedDetails) {
        setModalData(preparedData);

        setVisibleModal(true);
        return;
      }
      editRequestData(preparedData);
    }

    if (err) {
      openNotificationWithIcon('error', 'Заполните обязательные поля');
    }
  });
};

export const preparedSRC = ({ blob }) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let urlBase64 = null;

    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      urlBase64 = reader.result;
      resolve(urlBase64);
    };
  });
};

/*
 ** метод обрабатывает клик открытия карусели и скачивания
 ** если данные до этого не стягивались - делает запрос
 ** иначе берет из памяти
 */
export const checkBlob = async ({
  id = 0,
  slider = false,
  otherFiles = false,
  imageFiles = [],
  files = [],
  dispatch = _ => _,
  updateBlobFiles = _ => _,
  getFile = _ => _,
}) => {
  let blob;
  let urlBase64;
  let name;
  if (!otherFiles) {
    await Promise.all(
      imageFiles.map(async (img, i) => {
        if (!slider) {
          if (img.id === id && !img.blob) {
            const { fileData, mimeFromHeader } = await dispatch(getFile(id));
            blob = await new Blob([fileData], { type: mimeFromHeader });
            await preparedSRC({ blob }).then(res => (urlBase64 = res));
            // TODO: redux ругается на сложную структуру {blob:{}}
            await dispatch(
              updateBlobFiles({ ...img, blob, src: urlBase64, isLoaded: true }),
            );
          }
          if (img.id === id && img.blob) {
            blob = img.blob;
          }
        }

        //  for slider
        if (slider) {
          if (i === id && !img.blob) {
            const { fileData, mimeFromHeader } = await dispatch(
              getFile(img.id),
            );
            blob = await new Blob([fileData], { type: mimeFromHeader });
            await preparedSRC({ blob }).then(res => (urlBase64 = res));
            // TODO: redux ругается на сложную структуру {blob:{}}
            await dispatch(
              updateBlobFiles({ ...img, blob, src: urlBase64, isLoaded: true }),
            );
          }
          if (i === id && img.blob) {
            blob = img.blob;
            name = img.name;
          }
        }
      }),
    );
  }
  if (otherFiles) {
    await Promise.all(
      files.map(async img => {
        if (img.id === id && !img.blob) {
          const { fileData, mimeFromHeader } = await dispatch(getFile(id));
          blob = await new Blob([fileData], { type: mimeFromHeader });
          if (mimeFromHeader.includes('image')) {
            await preparedSRC({ blob }).then(res => (urlBase64 = res));
            await dispatch(
              updateBlobFiles({ ...img, blob, src: urlBase64, isLoaded: true }),
            );
            return;
          }
          // TODO: redux ругается на сложную структуру {blob:{}}
          await dispatch(updateBlobFiles({ ...img, blob }));
        }
        if (img.id === id && img.blob) {
          blob = img.blob;
        }
      }),
    );
  }

  return { blob, name };
};

export const onChangeSlide = async ({
  id = 0,
  slider = false,
  otherFiles = false,
  imageFiles = [],
  files = [],
  dispatch = _ => _,
  updateBlobFiles = _ => _,
  getFile = _ => _,
  setIndexSlide = _ => _,
}) => {
  await checkBlob({
    id,
    slider,
    otherFiles,
    imageFiles,
    files,
    dispatch,
    updateBlobFiles,
    getFile,
  });
  setIndexSlide(id);
};

export const getFullName = (dataTarget = {}, fields = []) => {
  try {
    return fields.map(field => `${_get(dataTarget, field, '')}`).join(' ');
  } catch (e) {
    console.error('getFullName fn throw err');
  }
};

export const checkXlsFile = ({ fileList, openNotificationWithIcon }) => {
  const errorXls = fileList.find(file => file.name.includes('.xls'));
  if (errorXls) {
    openNotificationWithIcon(
      'error',
      `Невозможно добавить файл с расширением xls/xlsx`,
    );
  }
};
