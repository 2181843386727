import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Typography } from 'antd';

import {
  getNotifications,
  deleteNotifications,
  postNotifications,
} from 'slices/Notifications';
import FormRow from './FormRow';
const { Title } = Typography;

const NotificationsForm = Form.create()(({ form }) => {
  const { getFieldDecorator } = form;

  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const [notifyRows, setNotifyRows] = useState([]);

  useEffect(() => {
    dispatch(getNotifications());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (notifications) {
      const prepared = Object.entries(notifications).map(n => ({
        name: n[0],
        state: n[1],
      }));
      setNotifyRows(prepared);
    }
  }, [notifications]);

  return (
    <div className="content_container">
      <Title level={2} style={{ textAlign: 'center' }}>
        Уведомления
      </Title>

      <Form name="dynamic_rule">
        {notifyRows &&
          notifyRows.map(nr => (
            <FormRow
              getFieldDecorator={getFieldDecorator}
              key={nr.name}
              name={nr.name}
              state={nr.state}
              postAct={name => {
                dispatch(postNotifications(name));
              }}
              deleteAct={name => {
                dispatch(deleteNotifications(name));
              }}
            />
          ))}
      </Form>
    </div>
  );
});

export default NotificationsForm;
