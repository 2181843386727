import React from 'react';
import 'antd/dist/antd.css';
import { Modal } from 'antd';

export const LocalizedModal = ({ title = 'Modal', ...props }) => {
  return (
    <div>
      <Modal title={title} {...props}>
        {props.children}
      </Modal>
    </div>
  );
};

export const customModalType = ({
  type = 'success',
  title = 'Внимание!',
  okText = 'Да',
  cancelText = 'Нет',
  ...props
}) => {
  Modal[type]({
    okText,
    cancelText,
    ...props,
  });
};
