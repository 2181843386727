// export const defaultDateFormat = 'DD.MM.YYYY';
export const defaultDateFormat = 'YYYY-MM-DD';
export const outputDateFormat = 'DD.MM.YYYY';
export const withHoursDateFormat = 'DD.MM.YYYY HH:mm:ss';

export const dateFields = [
  'createdAtFrom',
  'createdAtTo',
  'completeAtFrom',
  'completeAtTo',
  'createdAt',
  'completeAt',
];
