import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import _isEmpty from 'lodash/isEmpty';

import {
  getRules,
  getTitle,
  getFormEl,
  checkCustomId,
  checkRequiredFields,
} from '../helpers';

import { EditableContext } from '../TableRow';
import { postEstimates, countFullPrice, putEstimates } from 'slices/Estimates';
import { requiredFields, typesFormEl } from '../constant';

import 'antd/dist/antd.css';

const EditableCell = ({
  requestId,
  work,
  isContractor,
  handleSaveStore,
  tableTitle,
  ...props
}) => {
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const units = useSelector(state => state.estimates.units);

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing) {
      inputEl.current && inputEl.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => setEditing(!editing);

  const save = ({ eventValue, form, field }) => {
    const { record, estimates } = props;
    form.validateFields((error, values) => {
      if (!_isEmpty(error)) {
        return;
      }

      // для эл=ов, которые ихменяются не на onBlur
      const currentValue =
        field === 'unitId' || field === 'visible'
          ? eventValue
          : form.getFieldValue(field);

      // если не было изменений, не сохранять
      let nothingСhanged = false;

      estimates.forEach(values => {
        if (values.tableTitle === tableTitle) {
          values.data.forEach(tableFields => {
            if (tableFields[field] === currentValue) {
              nothingСhanged = true;
              return;
            }
            nothingСhanged = false;
          });
        }
      });

      if (nothingСhanged) return;

      const fullData = { ...record, [field]: eventValue };

      handleSaveStore(fullData);

      // Пересчет стоимости в сторе
      if (fullData.price && fullData.quantity) {
        dispatch(countFullPrice({ id: fullData.id, isContractor }));
      }

      if (!fullData.price || !fullData.quantity) {
        dispatch(countFullPrice({ id: fullData.id, clear: true }));
      }
      // POST/PUT в зависимости от ID и заполненных всех обязательных полей
      if (checkRequiredFields(fullData, requiredFields)) {
        if (checkCustomId(fullData.id)) {
          const { id, fullPrice, ...reqData } = fullData;
          dispatch(
            postEstimates({
              ...reqData,
              customId: id,
              work,
              requestId,
              isContractor,
            }),
          );

          return;
        }

        const { fullPrice, ...reqData } = fullData;

        dispatch(
          putEstimates({
            ...reqData,
            work,
            requestId,
          }),
        );
      }
    });
  };

  const renderCell = form => {
    const { children, dataIndex, record } = props;

    return editing || dataIndex === 'visible' ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [getRules({ dataIndex, requiredFields, typesFormEl })],
          initialValue: record[dataIndex],
        })(
          getFormEl({
            form,
            units,
            toggleEdit,
            typesFormEl,
            record: record,
            inputEl: inputEl,
            field: dataIndex,
            onClick: () => setEditing(true),
            handleOnSave: save,
          }),
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
        tabIndex="0"
        onFocus={e => e.currentTarget.click()}
      >
        {getTitle({ dataIndex, units, record, children, typesFormEl })}
      </div>
    );
  };

  const {
    index,
    title,
    record,
    editable,
    children,
    dataIndex,
    handleSave,
    ...restProps
  } = props;

  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
