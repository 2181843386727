// import { message } from 'antd';
import _get from 'lodash/get';
import { createSlice } from 'redux-starter-kit';
import { Requests } from 'connectors/query/Requests';
import openNotificationWithIcon from 'components/controls/base/Notification';

const RequestsSlice = createSlice({
  name: 'requests',

  initialState: {
    counters: [],
    states: [],
    list: null,
    currentRequest: {},
    comments: null,
    events: null,
    loader: true,
    totalRec: 0,
  },

  reducers: {
    setRequestCounters: (state, { payload }) => {
      state.counters = payload || [];
    },
    setRequestStates: (state, { payload }) => {
      state.states = payload || [];
    },

    setRequests: (state, { payload }) => {
      state.list = payload || [];
    },

    setTotalRequests: (state, { payload }) => {
      state.totalRec = payload || 0;
    },

    setCurrentRequest: (state, { payload }) => {
      state.currentRequest = payload || {};
    },
    editCurrentRequest: (state, { payload }) => {
      state.currentRequest = payload;
    },

    setRequestComments: (state, { payload }) => {
      state.comments = payload || [];
    },
    addRequestComment: (state, { payload }) => {
      state.comments = [payload, ...state.comments];
    },

    setRequestEvents: (state, { payload }) => {
      state.events = payload || [];
    },

    visibleLoader: (state, { payload }) => {
      state.loader = payload;
    },
  },
});

export default RequestsSlice.reducer;

export const {
  setRequestCounters,
  setRequestStates,
  setRequests,
  setTotalRequests,
  editCurrentRequest,
  setCurrentRequest,
  setRequestComments,
  addRequestComment,
  setRequestEvents,
  setFiles,
  addFile,
  visibleLoader,
  updateFile,
} = RequestsSlice.actions;

export const getRequestCounters = params => async dispatch => {
  const response = await Requests.getCounters(params);
  dispatch(setRequestCounters(response));
};

export const getRequestStates = params => async dispatch => {
  const response = await Requests.getStateList(params);
  dispatch(setRequestStates(response));
};

export const getRequests = params => async dispatch => {
  try {
    dispatch(visibleLoader(true));
    const response = await Requests.getList(params);
    if (!response) return;
    const { data, headers } = response;
    let totalRec = headers['x-total-records'];
    if (totalRec) totalRec = parseInt(totalRec, 10);
    dispatch(setRequests(data));
    dispatch(setTotalRequests(totalRec));
    dispatch(visibleLoader(false));
  } catch (e) {
    throw e;
  }
};

export const saveRequest = params => async dispatch => {
  try {
    const response = await Requests.postRequest(params);
    return response;
  } catch (e) {
    throw e;
  }
};
export const editRequest = (params, id) => async dispatch => {
  try {
    const response = await Requests.putRequest(params, id);
    await dispatch(editCurrentRequest(response));
  } catch (e) {
    const errorText = _get(e, 'response.data.message', 'Ошибка сохранения!');
    openNotificationWithIcon(
      'error',
      errorText === 'Internal server error' ? 'Ошибка сохранения!' : errorText,
    );
    throw e;
  }
};

export const getCurrentRequest = id => async dispatch => {
  const response = await Requests.getCurrentRequest(id);
  // contractorCompanyId - хардкодим в 19й версии
  dispatch(setCurrentRequest({ ...response, contractorCompanyId: [11] }));
};

export const getRequestComments = id => async dispatch => {
  const response = await Requests.getCommentsList(id);
  await dispatch(setRequestComments(response));
};

export const getRequestEvents = id => async dispatch => {
  const response = await Requests.getEventsList(id);
  await dispatch(setRequestEvents(response));
};

export const turnLoader = status => async dispatch => {
  dispatch(visibleLoader(status));
};

export const downloadExcelFile = params => async dispatch => {
  const response = await Requests.getXLSX(params);
  return response;
};
