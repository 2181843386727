import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { defaultDateFormat } from 'utils/constants';
import { StringParam, NumberParam, ArrayParam } from 'use-query-params';

export const defaultPage = 1;
export const defaultPerPage = 100;

export const queryParamsInit = {
  withArchived: StringParam,
  id: NumberParam,
  name: StringParam,
  states: ArrayParam,
  cityIds: ArrayParam,
  storeIds: ArrayParam,
  branches: ArrayParam,
  brandIds: ArrayParam,
  codeLike: StringParam,
  priorities: ArrayParam,
  buildingIds: ArrayParam,
  createdAtTo: ArrayParam,
  completeAtTo: ArrayParam,
  createdAtFrom: ArrayParam,
  completeAtFrom: ArrayParam,
  externalCodeLike: StringParam,
};

export const parseUrlToObj = () => {
  const data = parseQuery();
  const fields = prepareRequestData(data);
  return fields;
};

// Метод для перевода данных в числа/строки из урла
// Добавляет в массив данные в единичном экземпляре
// Необходимо при считывании данных из урла
export const stringToNumb = data => {
  const arrayFieldsName = [
    'priorities',
    'branches',
    'states',
    'cityIds',
    'buildingIds',
    'brandIds',
    'storeIds',
  ];
  const newPreparedData = {};
  Object.keys(data).forEach(el => {
    // если в урле нет параметров
    if (el.trim() === '') return;

    let nextStep = true;
    arrayFieldsName.forEach(name => {
      // Если строка из поля массивов, но в урле он как один эл-нт
      // нужно привести к число/строка и добавить в массив
      if (el === name && !Array.isArray(data[el])) {
        nextStep = false;
        const toNumb = Number(data[el]) || data[el];
        newPreparedData[name] = [toNumb];
        return;
      }
      // если массив нужно привести к число/строка
      if (el === name && Array.isArray(data[el])) {
        nextStep = false;
        const toNumb = data[el].map(numb => {
          return Number(numb) || numb;
        });
        newPreparedData[el] = toNumb;
        return;
      }
    });
    if (nextStep) {
      if (el === 'name') {
        newPreparedData[el] = decodeURI(data[el]);
        return;
      }
      newPreparedData[el] = data[el];
    }
  });
  return newPreparedData;
};

// Метод для получения данных фильтра из урл
export const parseQuery = () => {
  const qs = window.location.search.replace('?', '');
  const items = qs.split('&');
  const data = items.reduce((data, item) => {
    const splittedItem = item.split('=');
    const key = splittedItem[0];
    const value =
      splittedItem.length > 2
        ? window.decodeURIComponent(splittedItem.slice(1).join('='))
        : splittedItem[1];
    if (data[key] !== undefined) {
      if (!Array.isArray(data[key])) {
        data[key] = [data[key]];
      }
      data[key].push(value);
    } else {
      data[key] = value;
    }

    return data;
  }, {});

  return stringToNumb(data);
};

// Подготавливаем данные к отправке
export const prepareRequestData = (values = {}, saveFilter = false) => {
  let prepareNewFields = {};
  Object.keys(values).forEach(el => {
    // Отсеиваем пустые массивы
    if (Array.isArray(values[el]) && values[el].length > 0) {
      prepareNewFields[el] = values[el];
      return;
    }

    // Форматируем дату
    if (values[el] && moment.isMoment(values[el])) {
      prepareNewFields[el] = moment(values[el]).format(defaultDateFormat);
      return;
    }

    if (!saveFilter && values[el]) {
      prepareNewFields[el] = decodeURIComponent(values[el]);
      return;
    }

    // При клике сохранить фильтр, нужно отправлять все поля кроме id
    if (saveFilter && values[el] && el !== 'id') {
      prepareNewFields[el] = values[el];
      return;
    }
  });

  return prepareNewFields;
};

export const editFilter = (oldFields, newFields) => {
  const prepareData = {};

  // поля без undefined
  const filledFilds = {};
  // Очищаем поля где есть undefined
  for (let newField in newFields) {
    if (newFields[newField] !== undefined)
      filledFilds[newField] = newFields[newField];
  }

  Object.keys(filledFilds).forEach(filledFild => {
    Object.keys(oldFields).forEach(oldField => {
      if (filledFild === oldField) {
        // если это пустая строка
        // значит там было какое-то значение и мы его очистили
        if (!filledFilds[filledFild] && filledFild !== 'id') {
          prepareData[filledFild] = null;
          return;
        }
        // Если есть значение и это moment, то работаем с датой
        if (
          filledFilds[filledFild] &&
          moment.isMoment(filledFilds[filledFild])
        ) {
          prepareData[filledFild] = moment(filledFilds[filledFild]).format(
            defaultDateFormat,
          );
          return;
        }
        if (
          Array.isArray(filledFilds[filledFild]) &&
          !_isEmpty(filledFilds[filledFild]) &&
          !_isEqual(filledFilds[filledFild], oldFields[oldField])
        ) {
          prepareData[filledFild] = filledFilds[filledFild];
          return;
        }
        prepareData[filledFild] = filledFilds[filledFild];
      }
    });
  });

  return prepareData;
};

export const cardType = [
  'onlyCritical',
  'onlyOutdated',
  'onlyForWeek',
  'onlyForToday',
  'onlyOpened',
  'all',
];
