import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _keyByFp from 'lodash/fp/keyBy';
import _getFp from 'lodash/fp/get';
import moment from 'moment';

import { Comment } from 'antd';

import { getRequestStates } from 'slices/Requests';
import { getPriorities } from 'slices/Priorities';
import { getBranches } from 'slices/Branches';

import { RequestsFieldsTranslator } from 'utils/requestsFieldsTranslator';

const SystemMessage = props => {
  const dispatch = useDispatch();

  const requestStates = useSelector(state => state.requests.states);
  const priorities = useSelector(state => state.priorities.list);
  const branches = useSelector(state => state.branches.list);

  useEffect(() => {
    if (!requestStates.length) dispatch(getRequestStates());
    if (!priorities.length) dispatch(getPriorities());
    if (!branches.length) dispatch(getBranches());
  }, []); // eslint-disable-line

  const keyByCode = _keyByFp('code');
  const priorityPrepared = keyByCode(priorities);
  const statePrepared = keyByCode(requestStates);
  const branchPrepared = keyByCode(branches);

  const valueEditor = {
    state: s => _getFp(`[${s}].name`)(statePrepared),
    priority: p => _getFp(`[${p}].name`)(priorityPrepared),
    branch: b => _getFp(`[${b}].name`)(branchPrepared),
    completeAt: d => moment(d).format('DD.MM.YYYY'),
    rejectedAt: d => moment(d).format('DD.MM.YYYY'),
    closedAt: d => moment(d).format('DD.MM.YYYY'),
  };

  const translate = (field, value) => {
    if (value === 'null' || value === null) return 'пусто';
    if (valueEditor[field]) return valueEditor[field](value);
    return value;
  };

  return (
    <Comment
      className="comment_event_wrap"
      datetime={moment(props.createdAt).format('DD.MM.YYYY HH:mm')}
      author={`${_getFp('user.name')(props)} ${_getFp('user.surname')(props)}`}
      content={
        <div>
          {props.changes.map(c => (
            <div key={c.id} className="comment event">
              <span className="event_name">
                {RequestsFieldsTranslator(c.field)}:
              </span>
              <div className="event_value" title={translate(c.field, c.from)}>
                {translate(c.field, c.from)}
              </div>
              <span>-></span>
              <div className="event_value" title={translate(c.field, c.to)}>
                {translate(c.field, c.to)}
              </div>
            </div>
          ))}
        </div>
      }
    />
  );
};

export default SystemMessage;
