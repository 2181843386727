import React, { useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPriorities } from 'slices/Priorities';

import Select from 'components/controls/base/Select';

export const PrioritiesSelect = forwardRef(
  ({ label = '', params = {}, ...props }, ref) => {
    const dispatch = useDispatch();
    const priorities = useSelector(state => state.priorities.list);

    const preparedPriorities = priorities.map(s => ({
      value: s.code,
      name: s.name,
    }));

    useEffect(() => {
      dispatch(getPriorities(params));
    }, []);

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        <Select {...props} options={preparedPriorities} />
      </div>
    );
  },
);
