import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Table, Button, Row } from 'antd';

import { delEstimates, setEstimates, addEstimateRow } from 'slices/Estimates';

import EditableRow from './TableRow';
import EditableCell from './TableCell';

import {
  editTableCell,
  getTableHeaders,
  setCurrencyFormat,
  createNewRowFields,
} from './helpers';

import { titleWork } from './constant';
import 'antd/dist/antd.css';
import './style.scss';

const SmetaTable = ({
  list,
  requestId,
  isContractor,
  setSmetaTotal,
  tableFieldsData: { tableHeaders, tableNewRowStructure } = {},
}) => {
  const dispatch = useDispatch();
  const estimates = useSelector(state => state.estimates.list);

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(list.data);
  }, [list]);

  useEffect(() => {
    setColumns(
      getTableHeaders({
        dispatch,
        estimates,
        tableHeaders,
        setEstimates,
        handleDel: delEstimates,
        tableTitle: list.tableTitle,
      }),
    );
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSaveStore = row => {
    const preparedData = editTableCell({
      estimates,
      curDataRow: { row, tableTitle: list.tableTitle },
    });

    dispatch(setEstimates(preparedData));
  };

  const preparedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        estimates,
        requestId,
        isContractor,
        handleSaveStore,
        title: col.title,
        editable: col.editable,
        dataIndex: col.dataIndex,
        work: list.tableTitle === titleWork,
        tableTitle: list.tableTitle,
      }),
    };
  });

  const dispatchAddEstimateRow = () => {
    dispatch(
      addEstimateRow({
        tableTitle: list.tableTitle,
        curDataRow: createNewRowFields(tableNewRowStructure),
      }),
    );
  };

  const memoizedTotal = useMemo(() => {
    try {
      const target = list.tableTitle === titleWork ? 'work' : 'mat';

      const result = list.data.reduce((acc, cur) => {
        return Number(acc) + Number(String(cur.fullPrice).replace(/\s/g, ''));
      }, 0);

      setSmetaTotal(prevState => ({ ...prevState, [target]: result }));
      return setCurrencyFormat(result);
    } catch (error) {
      console.log('memoizedTotal fn', error);
    }
  }, [list]);

  return (
    <div>
      <h2>{list.tableTitle}</h2>
      <Button
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={dispatchAddEstimateRow}
      >
        Добавить строку
      </Button>
      <Table
        bordered
        rowKey="id"
        pagination={false}
        dataSource={tableData}
        components={components}
        columns={preparedColumns}
        rowClassName={() => 'editable-row'}
      />
      <Row type="flex" justify="end">
        <h3>Итого: {memoizedTotal}</h3>
      </Row>
    </div>
  );
};

export default SmetaTable;
