import { createSlice } from 'redux-starter-kit';
import { Estimates } from 'connectors/query/Estimates';
import { Files } from 'connectors/query/Files';
import {
  titleWork,
  titleMaterials,
} from 'components/forms/DetailsForm/components/RequestForm/SmetaTable/constant';
import openNotificationWithIcon from 'components/controls/base/Notification';
import { getFullPrice } from 'components/forms/DetailsForm/components/RequestForm/SmetaTable/helpers';

const EstimatesSlice = createSlice({
  name: 'estimates',

  initialState: {
    list: [],
    units: [],
  },

  reducers: {
    setEstimates: (state, { payload }) => {
      state.list = payload || [];
    },
    setUnits: (state, { payload }) => {
      state.units = payload || [];
    },

    addEstimateRow: (state, { payload }) => {
      const { tableTitle, curDataRow } = payload;

      state.list = state.list.map(tableFields => {
        if (tableFields.tableTitle === tableTitle) {
          tableFields.data.push(curDataRow);

          return { data: tableFields.data, tableTitle };
        }

        return tableFields;
      });
    },

    delEstimateRow: (state, { payload }) => {
      const { tableTitle, id } = payload;

      state.list = state.list.map(tableFields => {
        if (tableFields.tableTitle === tableTitle) {
          const newTableData = tableFields.data.filter(
            tableRow => tableRow.id !== id,
          );
          return { data: newTableData, tableTitle };
        }
        return tableFields;
      });
    },
  },
});

export default EstimatesSlice.reducer;

export const {
  setEstimates,
  delEstimateRow,
  addEstimateRow,
  setUnits,
} = EstimatesSlice.actions;

export const getUnitsEstimates = () => async dispatch => {
  const response = await Estimates.getUnitsList();
  dispatch(setUnits(response));
};

export const getEstimates = ({ id, isContractor }) => async dispatch => {
  const response = await Estimates.getList(id);

  if (response && Array.isArray(response)) {
    const newData = response.map(row => {
      // const quantity = isContractor ? row.finalPrice : row.quantity;
      const price = isContractor ? row.finalPrice : row.price;
      return {
        ...row,
        fullPrice: getFullPrice(price, row.quantity),
      };
    });

    const works = newData.filter(row => row.work);
    const material = newData.filter(row => !row.work);

    dispatch(
      setEstimates([
        { data: works, tableTitle: titleWork },
        { data: material, tableTitle: titleMaterials },
      ]),
    );
  }
};

export const countFullPrice = ({ id, clear = false, isContractor = false }) => (
  dispatch,
  getState,
) => {
  const estimates = getState().estimates.list;

  const newEstimatesGroup = estimates.map(estGroup => {
    const newData = estGroup.data.map(est => {
      if (est.id === id) {
        // const quantity = isContractor ? est.finalPrice : est.quantity;
        const price = isContractor ? est.finalPrice : est.price;
        return {
          ...est,
          // fullPrice: clear ? '' : getFullPrice(est.price, quantity),
          fullPrice: clear ? '' : getFullPrice(price, est.quantity),
        };
      }
      return est;
    });
    return { data: newData, tableTitle: estGroup.tableTitle };
  });
  dispatch(setEstimates(newEstimatesGroup));
};

export const postEstimates = ({ customId, isContractor, ...data }) => async (
  dispatch,
  getState,
) => {
  try {
    const response = await Estimates.createEstimate(data);
    const estimates = getState().estimates.list;

    const newEstimatesGroup = estimates.map(estGroup => {
      const newData = estGroup.data.map(est => {
        if (est.id === customId) {
          // const price = isContractor ? response.finalPrice : response.finalPrice;
          const price = isContractor ? response.finalPrice : response.price;

          return {
            ...response,
            // fullPrice: getFullPrice(response.price, quantity),
            fullPrice: getFullPrice(price, response.quantity),
          };
        }
        return est;
      });
      return { data: newData, tableTitle: estGroup.tableTitle };
    });

    dispatch(setEstimates(newEstimatesGroup));
    openNotificationWithIcon('success', 'Данные успешно изменены');
  } catch (error) {
    openNotificationWithIcon('error', 'При изменении данных произошла ошибка');
  }
};

export const putEstimates = data => async dispatch => {
  try {
    await Estimates.replaceEstimate(data);
    openNotificationWithIcon('success', 'Данные успешно сохранены');
  } catch (error) {
    openNotificationWithIcon('error', 'При сохранении данных произошла ошибка');
  }
};

export const delEstimates = data => async dispatch => {
  try {
    await Estimates.deleteEstimate(data.id);
    dispatch(delEstimateRow(data));
    openNotificationWithIcon('success', 'Данные успешно удалены');
  } catch (error) {
    openNotificationWithIcon('error', 'При удалении данных произошла ошибка');
  }
};

export const sendExcellEstimates = id => async dispatch => {
  try {
    await Estimates.postExcellEstimates(id);
    openNotificationWithIcon('success', 'Excell успешно сформирован');
  } catch (error) {
    openNotificationWithIcon(
      'error',
      'При формировании Excell произошла ошибка',
    );
  }
};
