import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCitiesOptions } from 'slices/SelectsFilters';
import Select from 'components/controls/base/Select';

export const CitiesSelect = forwardRef(
  ({ label = '', params = {}, ...props }, ref) => {
    const [gettedData, setGettedData] = useState(false);
    const dispatch = useDispatch();

    const { citiesLoading, citiesOptions } = useSelector(
      state => state.selectsFilters,
    );

    useEffect(() => {
      if (props.value && props.value.length)
        dispatch(getCitiesOptions({ ids: props.value, used: true }));
    }, [JSON.stringify(props.value)]);

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        <Select
          {...props}
          options={citiesOptions}
          onDropdownVisibleChange={() => {
            if (!gettedData) {
              dispatch(getCitiesOptions({ ...params, used: true }));
              setGettedData(true);
            }
          }}
          loading={citiesLoading}
        />
      </div>
    );
  },
);
