import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { createSlice } from 'redux-starter-kit';
import { Stores } from 'connectors/query/Stores';

const StoresSlice = createSlice({
  name: 'stores',

  initialState: {
    list: [],
    withInactiveList: [],
    isLoading: false,
  },

  reducers: {
    setStores: (state, { payload }) => {
      state.withInactiveList =
        _uniqWith([...state.withInactiveList, ...payload], _isEqual) || [];
      state.list =
        _uniqWith([...state.list, ...payload], _isEqual).filter(p =>
          ['serving', 'noto'].includes(p.state),
        ) || [];
    },

    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export default StoresSlice.reducer;

export const { setStores, setLoading } = StoresSlice.actions;

export const getStores = params => async (dispatch, getState) => {
  const {
    stores: { isLoading },
  } = getState();

  if (isLoading) return;

  dispatch(setLoading(true));
  const response = await Stores.getList({
    withInactive: true,
  });
  if (response) dispatch(setStores(response));
  dispatch(setLoading(false));
  return response;
};
