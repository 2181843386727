import { APIService } from '../api/rest';

export const Filters = {
  async getList(params) {
    try {
      return await APIService.GET('filters', params);
    } catch (e) {
      return e.response.data;
    }
  },
  async postFilter(params) {
    try {
      return await APIService.POST('filters', params);
    } catch (e) {
      return e.response.data;
    }
  },
  async delFilter(id) {
    try {
      return await APIService.DELETE(`filters/${id}`);
    } catch (e) {
      return e.response.data;
    }
  },
  async putFilter(id, params) {
    try {
      return await APIService.PUT(`filters/${id}`, params);
    } catch (e) {
      return e.response.data;
    }
  },
};
