import React, { useEffect, useState } from 'react';
import { getFile } from 'slices/Files';
import { useDispatch } from 'react-redux';

import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';

import { uploadFileToServer, getIsCardFiles } from './helpers';
import ServiceCardModal from './components/ServiceCardModal';
import ServiceCardDragger from './components/ServiceCardDragger';
import './style.scss';
import isEmpty from 'lodash/isEmpty';

const uploadFileToServerDebounced = _debounce(uploadFileToServer, 500);

const ServiceCardPicture = ({ files = [], id, uploadFiles = () => {} }) => {
  const [allAddedFiles, setAllAddedFiles] = useState([]);
  const [serviceFiles, setServiceFiles] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(allAddedFiles)) {
      uploadFileToServerDebounced({
        id,
        dispatch,
        uploadFiles,
        allFiles: files,
        setServiceFiles,
        fileList: allAddedFiles,
      });
    }
  }, [allAddedFiles]); // eslint-disable-line

  useEffect(() => {
    if (!files) return;
    getIsCardFiles({
      files,
      getFile,
      dispatch,
      serviceFiles,
      setServiceFiles,
    });
  }, [files]);

  const renderFn = () => {
    return Array.isArray(serviceFiles) && !_isEmpty(serviceFiles) ? (
      <>
        <ServiceCardModal
          serviceFiles={serviceFiles}
          setAllAddedFiles={setAllAddedFiles}
        />
      </>
    ) : Array.isArray(serviceFiles) && _isEmpty(serviceFiles) ? (
      <ServiceCardDragger
        serviceFiles={serviceFiles}
        setAllAddedFiles={setAllAddedFiles}
      />
    ) : null;
  };

  return <div>{renderFn()}</div>;
};

export default ServiceCardPicture;
