import React from 'react';

import CardForm from 'components/forms/CardForm';
import './style.scss';

function InfoPage() {
  return (
    <div className="infoPage" style={{ marginTop: 64 }}>
      <h1 className="infoPage_title">Добро пожаловать</h1>
      <p className="infoPage_subtitle">
        в портал технической поддержки CityGals
      </p>
      <div className="infoBlock_wrap">
        <CardForm />
      </div>
    </div>
  );
}

export default InfoPage;
