import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table as AntTable } from 'antd';

import { getRequestStates, getRequests } from 'slices/Requests';
import { getBranches } from 'slices/Branches';
import { getCompanies } from 'slices/Companies';
import { getPriorities } from 'slices/Priorities';
import { getStores } from 'slices/Stores';
import { getFilesCounter } from 'slices/Files';
import { getCommentsCounts } from 'slices/Comments';
import useGetStoresData from 'hooks/useGetStoresData';

import GenColumns from './genColumns';
import tableDataConverter from './tableDataConverter';
import { parseUrlToObj } from 'components/common/FilterSidebar/components/CustomizedFilterForm/helpers';
import './table.scss';

function Table({
  setColumnsData = _ => _,
  setPage = _ => _,
  setPerPage = _ => _,
  columnsData = {},
  perPage,
  page,
  filterStr = '',
  pageSteps = [],
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const requests = useSelector(state => state.requests.list);
  const totalRec = useSelector(state => state.requests.totalRec);
  const stores = useSelector(state => state.stores.withInactiveList);
  const buildings = useSelector(state => state.buildings.list);
  const brands = useSelector(state => state.brands.list);
  const cities = useSelector(state => state.cities.list);
  const requestStates = useSelector(state => state.requests.states);
  const branches = useSelector(state => state.branches.list);
  const companies = useSelector(state => state.companies.list);
  const visibleLoader = useSelector(state => state.requests.loader);

  const [filesCount, setFilesCount] = useState([]);
  const [commentsCount, setCommentsCount] = useState([]);

  useEffect(() => {
    dispatch(getRequestStates());
    dispatch(getBranches());
    dispatch(getCompanies());
    dispatch(getPriorities());
    dispatch(getStores());
  }, []); // eslint-disable-line

  // запросы на получение buildings/brands/cities для заполнение данных по stores
  useGetStoresData();

  useEffect(() => {
    const idList = requests && requests.map(r => Number(r.id));
    if (idList && idList.length > 0) {
      dispatch(getFilesCounter(idList)).then(list => setFilesCount(list || []));
      dispatch(getCommentsCounts(idList)).then(list =>
        setCommentsCount(list || []),
      );
    }
  }, [requests]); // eslint-disable-line

  const columns = GenColumns({ columnsData, setColumnsData });
  const data =
    requests &&
    requests.map(el => {
      const fileCount = filesCount.find(file => file.requestId === el.id);
      const commentCount = commentsCount.find(file => file.requestId === el.id);
      return {
        ...el,
        key: el.id,
        files: fileCount && fileCount.count,
        comments: commentCount && commentCount.count,
      };
    });

  // добавление нужных для таблицы полей и перевод в читаемый вид
  const convertedData = tableDataConverter(data, {
    stores,
    requestStates,
    branches,
    companies,
    buildings,
    brands,
    cities,
  });

  // фильтрация строкой поиска над таблицей
  const filteredData =
    data &&
    convertedData.filter(row => {
      let hasVal = false;
      Object.values(row).forEach(val => {
        if (
          val &&
          val.toString().toLowerCase().includes(filterStr.toLowerCase())
        )
          hasVal = true;
      });
      return hasVal;
    });

  const handlerChangePagination = (page, perPage) => {
    setPage(page);
    const {
      page: pageFromUrl,
      perPage: perPageFromUrl,
      ...updatedFields
    } = parseUrlToObj();

    dispatch(getRequests({ ...updatedFields, page, perPage }));
  };

  return (
    <AntTable
      columns={columns.map(item => ({ ...item, ellipsis: true }))}
      locale={{ emptyText: 'Пусто' }}
      className="requestTable"
      dataSource={filteredData}
      pagination={{
        total: totalRec,
        position: 'both',
        defaultPageSize: Number(perPage) || 100,
        pageSize: Number(perPage) || 100,
        pageSizeOptions: pageSteps,
        showSizeChanger: true,
        locale: { items_per_page: '' },
        current: Number(page),
        onChange: (page, size) => {
          handlerChangePagination(page, size);
        },
        onShowSizeChange: (page, size) => {
          setPerPage(size);
          handlerChangePagination(page, size);
        },
      }}
      size="small"
      loading={visibleLoader}
      rowClassName="requestRow"
      onRow={record => {
        return {
          onClick: () => {
            history.push(`/requests/${record.id}`);
          },
        };
      }}
      bordered
    />
  );
}

export default Table;
