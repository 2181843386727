import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCompaniesOptions } from 'slices/SelectsFilters';

import Select from 'components/controls/base/Select';

export const CompaniesSelect = forwardRef(
  (
    {
      label = '',
      params = {},
      showPhone = false,
      showEmail = false,
      compact = false,
      firstActive = false,
      ...props
    },
    ref,
  ) => {
    const [gettedData, setGettedData] = useState(false);
    const dispatch = useDispatch();

    const { companiesLoading, companiesOptions } = useSelector(
      state => state.selectsFilters,
    );

    useEffect(() => {
      if (props.value && props.value.length)
        dispatch(getCompaniesOptions({ ids: props.value }));
    }, [JSON.stringify(props.value)]);

    // useEffect(() => {
    //   if (firstActive && companies && companies[0])
    //     props.onChange(companies[0].id);
    // }, [companies]); // eslint-disable-line

    const hasAddInfo = showPhone || showEmail;

    return (
      <div ref={ref}>
        {label && <div>{label}</div>}
        {compact && props.value ? (
          props.value.name
        ) : (
          <Select
            {...props}
            options={companiesOptions}
            onDropdownVisibleChange={() => {
              if (!gettedData) {
                dispatch(getCompaniesOptions(params));
                setGettedData(true);
              }
            }}
            loading={companiesLoading}
          />
        )}
        {hasAddInfo && (
          <div className={`select-add-info blue ${compact && 'compact'}`}>
            {showPhone && (
              <div className="select-add-info_wrap">
                <div className="select-add-info_header">Телефон:</div>
                <div>+7-927-000-6090</div>
              </div>
            )}

            {showEmail && (
              <div className="select-add-info_wrap">
                <div className="select-add-info_header">E-mail:</div>
                <div>requests@city-gals.com</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
