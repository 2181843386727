import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Col, Icon, Upload } from 'antd';

import TextArea from 'components/controls/base/TextArea';

import { LocalizedModal } from 'components/controls/base/LocalizedModal';

import '../../styles.scss';
const { Dragger } = Upload;

const CloseReqModal = ({
  attantion,
  modalData,
  visible,
  rejectedValue,
  closedValue,
  openNotificationWithIcon,
  editRequestData,
  setVisibleModal,
  setRejectedValue,
  setClosedValue,
  form,
  filesToUpload,
  count,
  setCount,
  setFilesToUpload,
}) => {
  return (
    <LocalizedModal
      title={`${attantion} ${
        modalData.state === 'rejected' ? 'отклонения' : 'закрытия'
      }`}
      visible={visible}
      // visible={visibleModal}
      maskClosable={true}
      cancelText="Отмена"
      onOk={() => {
        const reasonData =
          modalData.state === 'rejected' ? rejectedValue : closedValue;
        if (!reasonData) {
          openNotificationWithIcon('error', 'Укажите причину');
          return;
        }
        editRequestData({
          ...modalData,
          ...(modalData.state === 'rejected'
            ? { rejectedDetails: reasonData }
            : { closedDetails: reasonData }),
        });
        setVisibleModal(false);
      }}
      onCancel={() => setVisibleModal(false)}
    >
      {modalData.state === 'rejected' ? (
        <TextArea
          value={rejectedValue}
          onChange={e => setRejectedValue(e.target.value)}
          rows="10"
          required
        />
      ) : (
        <TextArea
          value={closedValue}
          onChange={e => setClosedValue(e.target.value)}
          rows="10"
          required
        />
      )}
      {form.getFieldValue('state') === 'closed' && (
        <Col span={24}>
          <Dragger
            fileList={filesToUpload.card}
            name="file"
            multiple
            onChange={({ fileList }) => {
              setCount(count + 1);
              setFilesToUpload({
                ...filesToUpload,
                card: fileList,
                // TODO: Временное решение. поля type, value  Нужно понять как отправлять квери парамс вместе с formData у axous
                typeFile: { type: 'card', value: true },
              });
              return false;
            }}
            beforeUpload={() => false}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Нажмите или перенесите
              <span style={{ color: '#1890ff' }}> сервисную карту </span>в эту
              область
            </p>
          </Dragger>
        </Col>
      )}
    </LocalizedModal>
  );
};

export default CloseReqModal;
