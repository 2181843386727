export const tableColTehnicial = [
  {
    title: 'Наименование *',
    dataIndex: 'name',
    editable: true,
    width: '30%',
  },
  {
    title: 'Ед. измерения *',
    dataIndex: 'unitId',
    editable: true,
  },
  {
    title: 'Кол-во *',
    dataIndex: 'quantity',
    editable: true,
  },
  {
    title: 'Цена руб. *',
    dataIndex: 'price',
    editable: true,
  },
  {
    title: 'Стоимость руб.',
    dataIndex: 'fullPrice',
  },
];

export const tableColContractor = [
  {
    title: 'Наименование *',
    dataIndex: 'name',
    editable: true,
    width: '30%',
  },
  {
    title: 'Ед. измерения *',
    dataIndex: 'unitId',
    editable: true,
  },
  {
    title: 'Кол-во *',
    dataIndex: 'quantity',
    editable: true,
  },
  {
    title: 'Цена руб. *',
    dataIndex: 'price',
    editable: true,
  },
  {
    title: 'Цена в смету руб.',
    dataIndex: 'finalPrice',
    editable: true,
  },
  {
    title: 'Стоимость в смету руб.',
    dataIndex: 'fullPrice',
  },
  {
    title: 'Отобразить расход технику',
    dataIndex: 'visible',
    editable: true,
  },
];

export const tableNewRowTehnicial = {
  name: '',
  unitId: 1,
  quantity: 1,
  price: '',
};

export const tableNewRowContractor = {
  name: '',
  unitId: 1,
  quantity: 1,
  price: '',
  finalPrice: '',
  fullPrice: '',
  visible: false,
};

export const requiredFields = ['name', 'unitId', 'quantity', 'price'];

export const titleWork = 'Работы';
export const titleMaterials = 'Материалы';

export const typesFormEl = {
  name: {
    elem: 'input',
  },
  unitId: {
    elem: 'select',
  },
  quantity: {
    elem: 'inputNumber',
    type: 'number',
  },
  price: {
    elem: 'inputNumber',
    type: 'inputCurrency',
  },
  finalPrice: {
    elem: 'inputNumber',
    type: 'inputCurrency',
  },
  fullPrice: {
    elem: 'inputNumber',
    type: 'inputCurrency',
  },
  visible: {
    elem: 'checkbox',
  },
};

export const currencyRegExp = /\B(?=(\d{3})+(?!\d))/g;
