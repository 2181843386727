export const reason =
  'Магазин претензий по объему, качеству и срокам выполнения работ не имеет. Сервисная карта подписана обеими сторонами и приложена';
export const attantion = 'Внимание, введите причину';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

// матрица автоматического развертывания раздела "Информация о исполнителе" на /details
export const contractorFieldOpenDefault = {
  agent: {
    agreement: false,
    approved: false,
    closed: false,
    done: false,
    draft: false,
    new: false,
    progress: false,
    rejected: false,
    signing: false,
  },
  contractor: {
    agreement: false,
    approved: true,
    closed: false,
    done: true,
    draft: false,
    new: false,
    progress: true,
    rejected: false,
    signing: false,
  },
  partner: {
    agreement: false,
    approved: false,
    closed: false,
    done: false,
    draft: false,
    new: false,
    progress: false,
    rejected: false,
    signing: false,
  },
  technician: {
    agreement: false,
    approved: true,
    closed: false,
    done: false,
    draft: false,
    new: false,
    progress: true,
    rejected: false,
    signing: false,
  },
};
