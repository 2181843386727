import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, PageHeader } from 'antd';

import { getCurrentRequest } from 'slices/Requests';
import { getPolicies } from 'slices/Policies';

import RequestForm from './components/RequestForm';

const DetailsForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const policies = useSelector(state => state.policies);
  const { role } = useSelector(state => state.sessions.user);
  const request = useSelector(state => state.requests.currentRequest);

  useEffect(() => {
    dispatch(getCurrentRequest(id));
    dispatch(getPolicies());
  }, []);

  return (
    <>
      <PageHeader
        onBack={() => history.push('/requests')}
        title="Редактрование заявки"
      />
      <RequestForm request={request} policies={policies} role={role} id={id} />
    </>
  );
};
export default Form.create({ name: 'editPage' })(DetailsForm);
