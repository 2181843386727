import { APIService } from 'connectors/api/rest';

export const Cities = {
  async getList(params) {
    try {
      return await APIService.GET('cities', params);
    } catch (e) {
      return null;
    }
  },
};
