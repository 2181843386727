import { createSlice } from 'redux-starter-kit';
import { Comments } from 'connectors/query/Comments';

import { addRequestComment } from './Requests';

const CommentsSlice = createSlice({
  name: 'Comments',

  initialState: {
    list: [],
  },

  reducers: {
    setCounts: (state, { payload }) => {
      state.list = payload;
    },
  },
});

export default CommentsSlice.reducer;

export const { setComments, setCounts } = CommentsSlice.actions;

export const postComment = (params, addComment = true) => async dispatch => {
  const response = await Comments.postComment(params);
  if (addComment) dispatch(addRequestComment(response));
  return response;
};

export const getCommentsCounts = params => async dispatch => {
  const response = await Comments.getCounts(params);
  dispatch(setCounts(response));
  return response;
};
