import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Button } from 'antd';

import { MyFilters } from 'components/controls/selects';
import Input from 'components/controls/base/Input';

const SubmenuOptOne = forwardRef(
  (
    {
      onFilterChange,
      getFieldDecorator,
      SubMenu,
      onSaveFilters,
      setVisibleModal,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <Menu
          theme="dark"
          mode="inline"
          inlineIndent={2}
          className="custom_menu"
        >
          <Menu.Item key="1" title="Мои фильтры" style={{ minHeight: 75 }}>
            {getFieldDecorator('id', {
              initialValue: [],
            })(
              <MyFilters
                style={{ maxWidth: '100%', width: '100%' }}
                onChange={id => onFilterChange(id)}
                placeholder="Выберите фильтр"
              />,
            )}
          </Menu.Item>
          <Menu.Item
            style={{ textAlign: 'center' }}
            title="Мои фильтры"
            key="2"
          >
            <Link to="/filters">
              <Button
                style={{ width: '100%' }}
                type="primary"
                className="customPrimary"
              >
                Мои фильтры
              </Button>
            </Link>
          </Menu.Item>
          <Menu.Item
            style={{ textAlign: 'center' }}
            title="Сохранить фильтр"
            key="3"
          >
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={() => {
                setVisibleModal(true);
              }}
              // onClick={onSaveFilters}
            >
              Сохранить фильтры
            </Button>
          </Menu.Item>
        </Menu>
        <SubMenu
          title={
            <span>
              <Icon type="database" />
              <span>Номер заявки</span>
            </span>
          }
          ref={ref}
          {...props}
        >
          <Menu.Item className="custom_menuItem" key="4">
            {getFieldDecorator('externalCodeLike', {
              initialValue: props.externalCodeLike,
            })(
              <Input
                iconType="container"
                title="Внешний номер заявки"
                placeholder="Введите номер"
                maxLength={20}
              />,
            )}
          </Menu.Item>
          <Menu.Item className="custom_menuItem" key="5">
            {getFieldDecorator('codeLike', {
              initialValue: props.codeLike,
            })(
              <Input
                iconType="container"
                title="Номер заявки:"
                placeholder="Введите номер"
                maxLength={10}
              />,
            )}
          </Menu.Item>
        </SubMenu>
      </>
    );
  },
);

export default SubmenuOptOne;
