import React from 'react';

import {
  // FaVolumeOff,
  // FaCamera,
  FaBuilding,
  FaTimes,
  FaDraftingCompass,
  FaPencilAlt,
} from 'react-icons/fa';
import {
  GiElectricalResistance,
  GiComputerFan,
  GiDrill,
  GiSandsOfTime,
  GiTv,
} from 'react-icons/gi';
import { GoGear } from 'react-icons/go';
import {
  MdLibraryAdd,
  MdWork,
  MdComputer,
  MdDone,
  MdDoneAll,
} from 'react-icons/md';

export const branchesIcons = {
  'Аудио/Видео': <GiTv />,
  Вентиляция: <GiComputerFan />,
  Модернизация: <GoGear />,
  Стройка: <FaBuilding />,
  'Техническая эксплуатация': <GiDrill />,
  Электричество: <GiElectricalResistance />,
};

export const statesIcons = {
  'В ожидании': <GiSandsOfTime />,
  Новая: <MdLibraryAdd />,
  'В работе': <MdWork />,
  'Согласование сметы': <FaDraftingCompass />,
  'Согласована, в работе': <MdComputer />,
  Выполнена: <MdDone />,
  'На подписании': <FaPencilAlt />,
  Закрыта: <MdDoneAll />,
  Отклонена: <FaTimes />,
};
