import React from 'react';
import LoginForm from 'components/forms/LoginForm';

import './login.scss';

const LoginPage = () => (
  <div className="login-wrapper">
    <span className="login-logo">Портал технической поддержки City-Gals</span>
    <div className="loginForm-wrapper">
      <LoginForm />
    </div>
  </div>
);

export default LoginPage;
