import React from 'react';
import { Select as AntSelect } from 'antd';
const { Option } = AntSelect;

const Select = ({ onChange = _ => _, options = [], ...props }) => (
  <AntSelect
    showSearch
    style={{ width: 200 }}
    onChange={onChange}
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    {...props}
  >
    {options.map(o => (
      <Option key={o.value} value={o.value}>
        {o.name}
      </Option>
    ))}
  </AntSelect>
);

export default Select;
