import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRequestCounters } from 'slices/Requests';
import { getFilters } from 'slices/Filters';

import CardInfo from './components/CardInfo';
import MyFiltersList from './components/MyFiltersList';
import { cardData } from './constants';
import './style.scss';
import { Col, Row, Icon, Popover } from 'antd';

const CardForm = () => {
  const dispatch = useDispatch();
  const counters = useSelector(state => state.requests.counters);
  const allMyFilters = useSelector(state => state.filters.list);

  const [cardFields, setCardFields] = useState([]);
  const translateText = (counters, cardData) => {
    const cardFields = Object.keys(cardData).map(field => {
      const counter = counters.find(c => c.type === field);
      return {
        ...counter,
        ...cardData[field],
      };
    });

    return cardFields;
  };

  useEffect(() => {
    dispatch(getRequestCounters());
    dispatch(getFilters());
  }, []);

  useEffect(() => {
    if (counters && counters.length)
      setCardFields(translateText(counters, cardData));
  }, [counters]);

  return (
    <Row type="flex" justify="center" style={{ width: '100%' }}>
      <Col xl={22} xs={18}>
        <Row type="flex" justify="center" style={{ width: '100%' }}>
          {cardFields.map(card => {
            return (
              <Link
                key={card.name}
                to={`/requests?${card.params.type}=${card.params.value}`}
                className={`cardInfo ${
                  card.type === 'critical' ? 'cardInfo_attantion' : ''
                }`}
              >
                <CardInfo {...card} allMyFilters={allMyFilters} />
              </Link>
            );
          })}
        </Row>
      </Col>
      {/* // скрываем до 20й версии */}
      {/* <Col xl={2} xs={4}>
        <Popover
          content={<MyFiltersList allMyFilters={allMyFilters} />}
          title="Мои фильтры"
        >
          <Icon type="setting" style={{ fontSize: 40 }} />
        </Popover>
      </Col> */}
    </Row>
  );
};

export default CardForm;
