import { APIService } from 'connectors/api/rest';

export const Comments = {
  async postComment(params) {
    try {
      return await APIService.POST('comments', params);
    } catch (e) {
      return null;
    }
  },
  async getCounts(requestIds) {
    try {
      return await APIService.GET('/comments/counters', { requestIds });
    } catch (e) {
      return e.response.data;
    }
  },
};
