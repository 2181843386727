import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Descriptions } from 'antd';
import Button from 'components/controls/base/Button';
import { LocalizedModal } from 'components/controls/base/LocalizedModal';
import ChangePass from './components/ChangePass';
import { allRoles } from './constants';

const { Item } = Descriptions;

function CabinetForm() {
  const user = useSelector(state => state.sessions.user);
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <div className="content_container">
      <Descriptions title="Личный кабинет" column={1} bordered>
        <Item label="Имя">{user.name}</Item>
        <Item label="Фамилия">{user.surname}</Item>
        <Item label="Номер телефона">{user.phone}</Item>
        <Item label="E-mail">{user.email}</Item>
        <Item label="Роль">{allRoles[user.role]}</Item>
        <Item label="Инфо">{user.note}</Item>
      </Descriptions>

      <Button
        type="primary"
        onClick={() => setVisibleModal(true)}
        title="Сменить пароль"
        size="large"
        style={{ marginTop: 20 }}
      />
      <LocalizedModal
        title="Смена пароля"
        visible={visibleModal}
        maskClosable={true}
        cancelText="Отмена"
        footer={[
          <Button
            type="dashed"
            onClick={() => setVisibleModal(false)}
            title="Отмена"
            key="Отмена"
          />,
        ]}
        onCancel={() => setVisibleModal(false)}
      >
        <ChangePass />
      </LocalizedModal>
    </div>
  );
}

export default CabinetForm;
